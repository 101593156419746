import {ArrowLeftIcon} from "@heroicons/react/solid";
import React from "react";
import {Link} from "react-router-dom";

export default function BlogPost15() {

    return (
        <div className="relative py-16 bg-white overflow-hidden">
            <div className="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
                <div className="relative h-full text-lg max-w-prose mx-auto" aria-hidden="true">
                    <svg
                        className="absolute top-12 left-full transform translate-x-32"
                        width={404}
                        height={384}
                        fill="none"
                        viewBox="0 0 404 384"
                    >
                        <defs>
                            <pattern
                                id="74b3fd99-0a6f-4271-bef2-e80eeafdf357"
                                x={0}
                                y={0}
                                width={20}
                                height={20}
                                patternUnits="userSpaceOnUse"
                            >
                                <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor"/>
                            </pattern>
                        </defs>
                        <rect width={404} height={384} fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)"/>
                    </svg>
                    <svg
                        className="absolute top-1/2 right-full transform -translate-y-1/2 -translate-x-32"
                        width={404}
                        height={384}
                        fill="none"
                        viewBox="0 0 404 384"
                    >
                        <defs>
                            <pattern
                                id="f210dbf6-a58d-4871-961e-36d5016a0f49"
                                x={0}
                                y={0}
                                width={20}
                                height={20}
                                patternUnits="userSpaceOnUse"
                            >
                                <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor"/>
                            </pattern>
                        </defs>
                        <rect width={404} height={384} fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)"/>
                    </svg>
                    <svg
                        className="absolute bottom-12 left-full transform translate-x-32"
                        width={404}
                        height={384}
                        fill="none"
                        viewBox="0 0 404 384"
                    >
                        <defs>
                            <pattern
                                id="d3eb07ae-5182-43e6-857d-35c643af9034"
                                x={0}
                                y={0}
                                width={20}
                                height={20}
                                patternUnits="userSpaceOnUse"
                            >
                                <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor"/>
                            </pattern>
                        </defs>
                        <rect width={404} height={384} fill="url(#d3eb07ae-5182-43e6-857d-35c643af9034)"/>
                    </svg>
                </div>
            </div>
            <div className="mt-4 relative px-4 sm:px-6 lg:px-8">
                <div className="text-lg max-w-prose mx-auto">
                    <Link to="/blog"
                          className="inline-flex space-x-4">
                        <span
                            className="inline-flex items-center text-sm font-medium text-blue-500 space-x-1">
                      <ArrowLeftIcon className="h-5 w-5" aria-hidden="true"/>
                            <span>Back to Blog</span>
                    </span>
                    </Link>
                    <span className="block text-base text-center text-blue-600 font-semibold tracking-wide uppercase">
                        Article
                    </span>
                    <h1>
                        <span
                            className="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                        Tech Talk: What's an API?
                        </span>
                    </h1>
                    <p className="mt-8 text-xl text-gray-500 leading-8">
                        This is the kickoff post for our new Tech Talk series! We know firsthand how difficult it can be to
                        navigate software and tech solutions for your laboratory. Let us be your guide! Throughout this series
                        we will teach you some of  the basics and let you know what to keep an eye out for. This week we’re
                        talking all things APIs. What is an API? Why should you care? What questions should you be asking?
                        Read on to learn about all this and more.
                    </p>
                </div>
                <figure className="my-2">
                    <img
                        className="mx-auto w-7/12 rounded-lg"
                        src={process.env.PUBLIC_URL+"/assets/blog/blog15_api_tech_talk_clia_cap.png"}
                        alt=""
                    />
                </figure>
                <div className="mt-6 prose prose-blue prose-lg text-gray-500 mx-auto">
                    <h2>What is an API?</h2>
                    <p>
                        The official definition of an API, or Application Programming Interface, is “a set of functions
                        and procedures allowing the creation of applications that access the features or data of an
                        operating system, application or service.” Put more simply, an API is the the software that
                        allows systems to talk to each other.
                        <br/><br/>
                        The API type clinical laboratories are most likely to encounter are REST APIs. REST refers to a
                        software architecture called Representational State Transfer that lays out conditions on how an
                        API should work. The particulars of what makes an API RESTful aren’t necessary for clinical
                        laboratory users to understand but more information can found [here](https://restfulapi.net/).
                        Overall, clinical laboratories evaluating software solutions should know that RESTful APIs are a
                        common industry standard to facilitate the exchange of information securely over the internet.
                        <br/><br/>
                        GraphQL is a newer method of providing integration. GraphQL, like REST, transfers information
                        between systems over the web. This type of API is less common, particularly for software systems
                        in the clinical laboratory but it is good to know that it’s a new option that is gaining
                        popularity. The advantage over REST is that GraphQL allows you to pull information from multiple
                        data sources in one request and limit requests only to relevant data. However, GraphQL
                        introduces more complexity by introducing schemas and resolvers.
                    </p>
                    <h2>Why Does It Matter?</h2>
                    <p>
                        <h3>Necessary to integrating with your existing systems</h3>
                        Communication between any new software solution your laboratory is considering and your
                        existing software system can only happen if your systems have API support. If a software system
                        doesn’t provide APIs or provides limited APIs for your laboratory to use to integrate, it can
                        drastically increase the timeline and cost of integration by requiring your development team to
                        build and maintain APIs.
                        <h3>Necessary to facilitate automation</h3>
                        If your clinical laboratory has any ambitions to automate your laboratory tests, APIs are a
                        necessity. Your laboratory software systems will need to be able to communicate with your
                        instruments in a standardized and consistent manner. In addition, more complicated workflows and
                        testing activities may require more specialized software that standard instruments don’t support.
                        Consistent APIs makes the customization and configuration process more straightforward.
                        <h3>Reduces human error during data transfer</h3>
                        Finally, using APIs to automatically transmit information from one application to another
                        reduces the risk for human error. In the course of laboratory testing, an immense amount of
                        information must be recorded. The chance of an avoidable error occurring decreases when
                        information is automatically transmitted and recorded via API rather than expecting human
                        technicians to be perfect at transcription.
                    </p>
                    <h2>Why Should You Be Looking For?</h2>
                    <p>
                        <h3>Documentation</h3>
                        API documentation can be overwhelming for even the most seasoned developers. However, a software
                        provider’s API documentation has indicators anyone can keep an out for. Bad documentation is
                        sparse and unclear. It will leave your developers with questions about how the APIs actually
                        work and what is and isn’t possible. Conversely, good documentation will clearly explain what
                        their APIs are and are not capable of and provide your developers with a clear understanding of
                        the underlying design and why integrations work the way they do. Furthermore, API documentation
                        should have a clear and straightforward approach to authentication outlined. Authentication is
                        the process if determining whether the user is who they claim they are. Common methods of
                        authentication include HTTP basic authentication, API key authentication, and OAuth.
                        <h3>Stability</h3>
                        Something else clinical laboratories should pay attention to when evaluating software providers
                        is how up to date their API documentation is. Recent documentation indicates that the software
                        is likely more stable since the provider is allocating resources to keep the documentation up
                        to date. More out of date documentation can indicate that the software you’re evaluating does
                        not receive much support or that providing documentation to users is not a high priority. You
                        should also ask software providers if they provide backwards compatibility guarantees. If not,
                        beware that you may have to put time and resources into managing unexpected updates and version
                        changes in order to maintain your software.
                        <h3>Limitations</h3>
                        Finally, software providers should make the limitations of their system clear. Common limits to
                        ask about are rate limits and API key access limits. Rate limits are any limits around how many
                        time your system can connect to their system through their API. It’s very important to ensure
                        that their limits can accommodate the rate at which your laboratory is making API calls. For
                        instance, if your laboratory is using a system to accession samples and your accession 50
                        samples per minute but the system’s API can only handle 25 calls per minute, the system is not
                        a good fit and could lead to your access being throttled. API key access limits are limits
                        around how many API keys the provider will issue you. An API key is code used to identify the
                        user and/or system making contact. The number of keys the software provider gives in addition
                        to the permissions associated can be a deciding factor of whether a software provider will work
                        for you.
                    </p>
                    <h2>Interested in trying our QMS, Regulator?</h2>
                    <p>
                        Our Early Adopter program is still open, but spots are filling up quickly. You can reach us via
                        email at{" "}
                        <a href="mailto:info@decotolabs.com">info@decotolabs.com</a>{" "}
                        with “Early Adopter Program” as the subject line. Still not sure? Visit our{" "}
                        <a href="https://decotolabs.com">homepage</a>{" "}
                        where you can directly schedule time to speak with our founders and get your questions answered.
                    </p>
                </div>
            </div>
        </div>
    )
}
