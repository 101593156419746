import {CheckCircleIcon, CurrencyDollarIcon} from '@heroicons/react/solid'
import Footer from "./common/Footer";
import {HashLink} from "react-router-hash-link";
import React from "react";

const includedFeatures = [
    'All Regulator modules',
    'Up to 2 system administrator accounts',
    'Fully self-service',
    'Standard operating procedure importing',
]

export default function PricingPage() {
    return (
        <div className="bg-white">
                <div className="pt-12 sm:pt-16 lg:pt-20">
                    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                        <div className="text-center">
                            <h2 className="text-3xl font-extrabold text-gray-900 sm:text-4xl lg:text-5xl">Simple no-tricks pricing</h2>
                            <p className="mt-4 text-xl text-gray-600">
                                If you're not satisfied, contact us within the first 30 days and we'll send you a full refund.
                            </p>
                            <div
                                className="mt-4 inline-flex items-center text-white bg-blue-600 rounded-full p-1 pr-2 sm:text-base lg:text-sm xl:text-base hover:text-gray-200"
                            >
                                <CurrencyDollarIcon className="ml-2 w-5 h-5 text-green-300" aria-hidden="true" />
                                <span className="mx-4 font-bold">Get 2 months free with annual billing</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mt-8 bg-white pb-12 sm:mt-12 sm:pb-16 lg:pb-20">
                    <div className="relative">
                        <div className="absolute inset-0 h-1/2 bg-white" />
                        <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                            <div className="border max-w-lg mx-auto rounded-lg shadow-lg overflow-hidden lg:max-w-none lg:flex">
                                <div className="flex-1 bg-white px-6 py-8 lg:p-12">
                                    <h3 className="text-2xl font-extrabold text-gray-900 sm:text-3xl">Regulator Subscription</h3>
                                    <p className="mt-6 text-base text-gray-500">
                                        Includes access to the full version of Regulator, our comprehensive quality
                                        management tool for CLIA laboratories. No user minimum requirement.
                                    </p>
                                    <div className="mt-8">
                                        <div className="flex items-center">
                                            <h4 className="flex-shrink-0 pr-4 bg-white text-sm tracking-wider font-semibold uppercase text-blue-600">
                                                What's included
                                            </h4>
                                            <div className="flex-1 border-t-2 border-gray-200" />
                                        </div>
                                        <ul role="list" className="mt-8 space-y-5 lg:space-y-0 lg:grid lg:grid-cols-2 lg:gap-x-8 lg:gap-y-5">
                                            {includedFeatures.map((feature) => (
                                                <li key={feature} className="flex items-start lg:col-span-1">
                                                    <div className="flex-shrink-0">
                                                        <CheckCircleIcon className="h-5 w-5 text-green-400" aria-hidden="true" />
                                                    </div>
                                                    <p className="ml-3 text-sm text-gray-700">{feature}</p>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                                <div className="py-8 px-6 text-center bg-blue-50 lg:flex-shrink-0 lg:flex lg:flex-col lg:justify-center lg:p-12">
                                    <div className="mt-4 flex items-center justify-center text-5xl font-extrabold text-gray-900">
                                        <span>$150/user</span>
                                    </div>
                                    <p className="mt-4 text-sm">
                                        <span className="font-medium">per month<br/></span>
                                        <HashLink
                                            to={"/terms-of-service#top"} className="font-medium text-gray-500 underline">
                                            Learn about our terms of service
                                        </HashLink>
                                    </p>
                                    <div className="mt-6">
                                        <div className="rounded-md shadow">
                                            <a
                                                href="https://regulator.decotolabs.com"
                                                className="flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700"
                                            >
                                                Get Started
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="pt-12 sm:pt-16 lg:pt-20">
                        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                            <div className="text-center">
                                <h2 className="text-3xl font-extrabold text-gray-900 sm:text-4xl lg:text-5xl">Need something special not listed here?</h2>
                                <p className="mt-4 text-xl text-gray-600">
                                    Contact us at{" "}
                                    <a href="mailto:sales@decotolabs.com" className="font-medium text-blue-600 underline">
                                        sales@decotolabs.com</a>
                                    {" "}to learn about what enterprise
                                    features we have to offer.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="static inset-x-0 bottom-0 mt-36 xl:mt-44">
                    <Footer/>
                </div>
            </div>
    )
}

