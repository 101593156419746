import { Link } from "react-router-dom"

const navigation = [
    { name: 'Pricing', href: '/pricing' },
    { name: 'Blog', href: '/blog' },
    { name: 'Team', href: '/team' },
]


export default function Header() {
    return (
        <header className={"bg-blue-600"}>
            <nav className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8" aria-label="Top">
                <div className="w-full py-6 flex items-center justify-between border-b border-blue-500 lg:border-none">
                    <div className="flex items-center">
                        <Link to="/">
                            <span className="sr-only">Decoto Labs</span>
                            <img
                                className="h-10 w-auto"
                                src={process.env.PUBLIC_URL+'/assets/decoto-labs_logo-img_white.svg'}
                                alt="Decoto Labs"
                            />
                        </Link>
                        <div className="hidden ml-10 space-x-8 lg:block">
                            {navigation.map((link) => (
                                <Link key={link.name} to={link.href} className="text-base font-medium text-white hover:text-blue-50">
                                    {link.name}
                                </Link>
                            ))}
                            <a className="text-base font-medium text-white hover:text-blue-50"
                               target="_blank"
                               rel="noreferrer"
                               href='https://decotolabs.notion.site/Decoto-Labs-Documentation-9c49392dc9b54c44af10973ee5f59b12'>
                                Documentation
                            </a>
                        </div>
                    </div>
                    <div className="ml-10 space-x-4">
                        <a
                            href="https://regulator.decotolabs.com/regulator/login"
                            target="_blank"
                            rel="noreferrer"
                            className="inline-flex bg-white py-2 px-4 border border-transparent rounded-md text-base font-medium text-blue-600 hover:bg-blue-50"
                        >
                            <img className="ml-1 mr-2 h-6 w-6"
                                 src={process.env.PUBLIC_URL+"/assets/dl-regulator_logo-img_color.svg"}
                                 alt=""
                            />
                            Get Started with Regulator
                        </a>
                    </div>
                </div>
                <div className="py-4 flex flex-wrap justify-center space-x-6 lg:hidden">
                    {navigation.map((link) => (
                        <Link key={link.name} to={link.href} className="text-base font-medium text-white hover:text-blue-50">
                            {link.name}
                        </Link>
                    ))}
                </div>
            </nav>
        </header>
    )
}
