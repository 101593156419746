import {ArrowLeftIcon} from "@heroicons/react/solid";
import React from "react";
import {Link} from "react-router-dom";

export default function BlogPost13() {

    return (
        <div className="relative py-16 bg-white overflow-hidden">
            <div className="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
                <div className="relative h-full text-lg max-w-prose mx-auto" aria-hidden="true">
                    <svg
                        className="absolute top-12 left-full transform translate-x-32"
                        width={404}
                        height={384}
                        fill="none"
                        viewBox="0 0 404 384"
                    >
                        <defs>
                            <pattern
                                id="74b3fd99-0a6f-4271-bef2-e80eeafdf357"
                                x={0}
                                y={0}
                                width={20}
                                height={20}
                                patternUnits="userSpaceOnUse"
                            >
                                <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor"/>
                            </pattern>
                        </defs>
                        <rect width={404} height={384} fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)"/>
                    </svg>
                    <svg
                        className="absolute top-1/2 right-full transform -translate-y-1/2 -translate-x-32"
                        width={404}
                        height={384}
                        fill="none"
                        viewBox="0 0 404 384"
                    >
                        <defs>
                            <pattern
                                id="f210dbf6-a58d-4871-961e-36d5016a0f49"
                                x={0}
                                y={0}
                                width={20}
                                height={20}
                                patternUnits="userSpaceOnUse"
                            >
                                <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor"/>
                            </pattern>
                        </defs>
                        <rect width={404} height={384} fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)"/>
                    </svg>
                    <svg
                        className="absolute bottom-12 left-full transform translate-x-32"
                        width={404}
                        height={384}
                        fill="none"
                        viewBox="0 0 404 384"
                    >
                        <defs>
                            <pattern
                                id="d3eb07ae-5182-43e6-857d-35c643af9034"
                                x={0}
                                y={0}
                                width={20}
                                height={20}
                                patternUnits="userSpaceOnUse"
                            >
                                <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor"/>
                            </pattern>
                        </defs>
                        <rect width={404} height={384} fill="url(#d3eb07ae-5182-43e6-857d-35c643af9034)"/>
                    </svg>
                </div>
            </div>
            <div className="mt-4 relative px-4 sm:px-6 lg:px-8">
                <div className="text-lg max-w-prose mx-auto">
                    <Link to="/blog"
                          className="inline-flex space-x-4">
                        <span
                            className="inline-flex items-center text-sm font-medium text-blue-500 space-x-1">
                      <ArrowLeftIcon className="h-5 w-5" aria-hidden="true"/>
                            <span>Back to Blog</span>
                    </span>
                    </Link>
                    <span className="block text-base text-center text-blue-600 font-semibold tracking-wide uppercase">
                        Article
                    </span>
                    <h1>
                        <span
                            className="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              Document Roll Call: Post-Analytical, Laboratory Director & Proficiency Testing Procedures
            </span>
                    </h1>
                    <p className="mt-8 text-xl text-gray-500 leading-8">
                        This week Decoto Labs is excited to bring a jam packed installment of Document Roll Call. This
                        Tuesday, we are covering the policies and procedures your laboratory should have around
                        post-analytical procedures, proficiency testing and laboratory directors. Stay tuned in to our
                        blog every week for not only our Document Roll Call series but many more free resources for
                        managing your clinical laboratory’s quality program.
                    </p>
                </div>
                <figure className="my-2">
                    <img
                        className="mx-auto w-7/12 rounded-lg"
                        src={process.env.PUBLIC_URL+"/assets/blog/blog13_post_analytical_lab_director_pt.png"}
                        alt=""
                    />
                </figure>
                <div className="mt-6 prose prose-blue prose-lg text-gray-500 mx-auto">
                    <p>
                        Unsure how to cover your post-analytical, laboratory director and proficiency testing procedure
                        requirements? Here are some of the documents you may want to implement in your laboratory and the points to
                        make sure your documents touch on. Please note these documents are just our recommendations
                        based on our experience working in clinical laboratory settings and do not guarantee that this
                        list is exhaustive.
                    </p>
                    <h2>Post-Analytical Procedures & Policies</h2>
                    <ul>
                        <li className="list-disc">
                            Include your laboratory’s policy for ensuring the laboratory director reviews and approves
                            the content and format of paper and electronic patient reports every two years
                        </li>
                        <li className="list-disc">
                            Include your laboratory’s policy for ensuring the laboratory director provides input
                            regarding whether outside laboratory results are reported through the primary reporting system
                        </li>
                        <li className="list-disc">
                            Include your laboratory’s policy with defined turnaround times and procedure for notifying
                            the requester when testing is delayed
                        </li>
                        <li className="list-disc">
                            Include your laboratory’s procedure for including the reference range, interpretation,
                            limitations and contact information for a licensed health professional who can advise about
                            the clinical significance with test report
                        </li>
                        <li className="list-disc">
                            Include you laboratory’s procedure for when errors are detected in patient test reports
                            including:
                            <ul>
                                <li className="list-disc">
                                    Procedure for ensuring your laboratory promptly notifies responsible personnel or
                                    referring laboratory and issues a corrected report
                                </li>
                                <li className="list-disc">
                                    Procedure for ensuring all corrected reports of previously reported, incorrect
                                    patient results are identified as corrected and both the corrected and original
                                    data are clearly identified as such
                                </li>
                                <li className="list-disc">
                                    Procedure for ensuring when multiple sequential corrections of a single test result,
                                    all corrections are referenced in sequential order in subsequent reports
                                </li>
                            </ul>
                        </li>
                    </ul>
                    <h2>Laboratory Director Procedures & Policies</h2>
                    <ul>
                        <li className="list-disc">
                            Include your laboratory’s documentation of the laboratory director’s responsibilities
                            including:
                            <ul>
                                <li className="list-disc">
                                    Ensuring an effective quality management program
                                </li>
                                <li className="list-disc">
                                    Ensuring proficiency testing, alternative assessment, and QC procedures are sufficient
                                </li>
                                <li className="list-disc">
                                    Ensuring that performance specifications for new tests, instruments, and methods
                                    have been properly validated and/or verified
                                </li>
                                <li className="list-disc">
                                    Ensuring communication of laboratory data and appropriate patient result reporting
                                </li>
                                <li className="list-disc">
                                    Providing intralaboratory consultations and clinical consultations
                                </li>
                                <li className="list-disc">
                                    Ensuring provision of education programs, strategic planning and research and
                                    development
                                </li>
                                <li className="list-disc">
                                    Ensuring sufficient numbers of qualified personnel
                                </li>
                                <li className="list-disc">
                                    Ensuring implementation of safe laboratory environment
                                </li>
                                <li className="list-disc">
                                    Interacting with government and other agencies as applicable
                                </li>
                                <li className="list-disc">
                                    Selecting equipment, supplies and services with respect to quality
                                </li>
                            </ul>
                        </li>
                        <li className="list-disc">
                            Include your laboratory’s procedure for ensuring new laboratory directors will review and
                            approve all policies and procedures over a reasonable time
                        </li>
                        <li className="list-disc">
                            Include your laboratory’s procedure for written delegation of laboratory director functions
                            and responsibilities
                        </li>
                        <li className="list-disc">
                            Include your laboratory’s written agreement defining the frequency of and responsibility for
                            activities performed by the laboratory director during on-site and remote visits and
                            records to be kept of these activities
                        </li>
                    </ul>
                    <h2>Proficiency Testing Procedures & Policies</h2>
                    <ul>
                        <li className="list-disc">
                            Include your laboratory’s procedure for assessing PT challenges that were intended to be
                            graded but were not
                        </li>
                        <li className="list-disc">
                            Include your laboratory’s procedure for semi-annual alternative performance assessment for
                            labs exempt from proficiency testing
                        </li>
                        <li className="list-disc">
                            Include your laboratory’s procedure for proficiency testing that is commensurate with the
                            complexity of testing being performed in your laboratory including:
                            <ul>
                                <li className="list-disc">
                                    Policy for ensuring that samples will be integrated into routined laboratory workload
                                </li>
                                <li className="list-disc">
                                    Procedure for evaluation of results and appropriate corrective action to be taken
                                    for unacceptable results
                                </li>
                                <li className="list-disc">
                                    Policy for ensuring no inter-laboratory communication about samples happen until
                                    after the proficiency testing deadline
                                </li>
                            </ul>
                        </li>
                    </ul>
                    <h2>Still have questions on post-analytical, laboratory director and proficiency testing procedures?</h2>
                    <p>
                        The Decoto Labs team is happy to help however we can. Whether you need document templates,
                        alternative performance assessment advice or laboratory director delegation letter formatting
                        &#8212; we want to help.
                    </p>
                    <p>
                        Visit our <a href="https://decotolabs.com">homepage</a>{" "} to schedule time with our founders
                        to get your questions answered or reach out via email to
                        <a href="mailto:info@decotolabs.com">info@decotolabs.com</a>.
                        You can also keep up with our blog posts and resources by following our{" "}
                        <a href="https://www.linkedin.com/company/decoto-labs" target="_blank" rel="noreferrer">LinkedIn</a> page.
                    </p>
                    <h2>Interested in trying our QMS, Regulator?</h2>
                    <p>
                        Our Early Adopter program is still open, but spots are filling up quickly. You can reach us via
                        email at{" "}
                        <a href="mailto:info@decotolabs.com">info@decotolabs.com</a>{" "}
                        with “Early Adopter Program” as the subject line. Still not sure? Visit our{" "}
                        <a href="https://decotolabs.com">homepage</a>{" "}
                        where you can directly schedule time to speak with our founders and get your questions answered.
                    </p>
                </div>
            </div>
        </div>
    )
}
