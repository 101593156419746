import Footer from "./common/Footer";
import { Link } from "react-router-dom";
import React from "react";

const posts = [
    {
        title: "Regulator, the first QMS built for CLIA labs, has launched!",
        href: '/blog/cap_clia_qms_regulator_launch',
        category: {name: 'Press Release'},
        description:
            "Yes, you read that right! Regulator is now publicly available for you to use in your laboratory. Read on " +
            "to learn about what Regulator can do you for you, how to sign up and how to get in touch with us here at " +
            "Decoto Labs."
        ,
        date: 'July 19, 2022',
        datetime: '2022-07-19',
        imageUrl:
            process.env.PUBLIC_URL + "/assets/blog/blog16_regulator_launch.png",
        author: {
            name: 'Mujda Alamzai-Oh',
            href: 'https://www.linkedin.com/in/mujdaalamzai/',
            imageUrl: process.env.PUBLIC_URL + "/assets/headshots/blog_headshot_mujda.jpeg"
        },
    },
    {
        title: "Tech Talk: What's an API?",
        href: '/blog/what-is-an-api-tech-talk',
        category: {name: 'Article'},
        description:
            "This is the kickoff post for our new Tech Talk series! We know firsthand how difficult it can be to " +
            "navigate software and tech solutions for your laboratory. Let us be your guide! Throughout this series " +
            "we will teach you some of  the basics and let you know what to keep an eye out for. This week we’re " +
            "talking all things APIs. What is an API? Why should you care? What questions should you be asking? " +
            "Read on to learn about all this and more."
        ,
        date: 'July 14, 2022',
        datetime: '2022-07-14',
        imageUrl:
            process.env.PUBLIC_URL + "/assets/blog/blog15_api_tech_talk_clia_cap.png",
        author: {
            name: 'Mujda Alamzai-Oh',
            href: 'https://www.linkedin.com/in/mujdaalamzai/',
            imageUrl: process.env.PUBLIC_URL + "/assets/headshots/blog_headshot_mujda.jpeg"
        },
    },
    {
        title: 'Document Roll Call: Referral Laboratories, Personnel & Laboratory Information Systems',
        href: '/blog/clia-referral-lab-personnel-laboratory-information-systems-procedures',
        category: {name: 'Article'},
        description:
            "This is the final installment of our Document Roll Call series! This Tuesday, " +
            "we are covering the policies and procedures your laboratory should have around referral laboratories, " +
            "personnel and laboratory information systems. Even though our Document Roll Call series has come to an " +
            "end, stay tuned to the blog every week for more free resources for managing your clinical laboratory's " +
            "quality program."
        ,
        date: 'July 5, 2022',
        datetime: '2022-07-05',
        imageUrl:
            process.env.PUBLIC_URL + "/assets/blog/blog14_referral_labs_personnel_lab_info_systems.png",
        author: {
            name: 'Mujda Alamzai-Oh',
            href: 'https://www.linkedin.com/in/mujdaalamzai/',
            imageUrl: process.env.PUBLIC_URL + "/assets/headshots/blog_headshot_mujda.jpeg"
        },
    },
    {
        title: 'Document Roll Call: Post-Analytical, Laboratory Director & Proficiency Testing Procedures',
        href: '/blog/clia-post-analytical-lab-director-proficiency-testing',
        category: {name: 'Article'},
        description:
            "This week Decoto Labs is excited to bring a jam packed installment of Document Roll Call. This Tuesday, " +
            "we are covering the policies and procedures your laboratory should have around post-analytical procedures, " +
            "proficiency testing and laboratory directors. Stay tuned in to our blog every week for not only our " +
            "Document Roll Call series but many more free resources for managing your clinical laboratory’s quality " +
            "program."
        ,
        date: 'June 28, 2022',
        datetime: '2022-06-28',
        imageUrl:
            process.env.PUBLIC_URL + "/assets/blog/blog13_post_analytical_lab_director_pt.png",
        author: {
            name: 'Mujda Alamzai-Oh',
            href: 'https://www.linkedin.com/in/mujdaalamzai/',
            imageUrl: process.env.PUBLIC_URL + "/assets/headshots/blog_headshot_mujda.jpeg"
        },
    },
    {
        title: 'Build a Competency Assessment Program You Can Actually Keep Up With',
        href: '/blog/clia-cap-cola-competency-assessment-program',
        category: {name: 'Article'},
        description:
            "The Center for Medicare and Medicaid Services and accreditation organizations are pretty clear on the " +
            "procedures required to meet minimal regulatory requirements. Translating those procedures into an " +
            "efficient program that your laboratory can keep up with is less obvious. Read on for Decoto Labs’ " +
            "tips and tricks for dealing with competency assessments."
        ,
        date: 'June 23, 2022',
        datetime: '2022-06-23',
        imageUrl:
            process.env.PUBLIC_URL + "/assets/blog/blog12_competency_assessment_tips.png",
        author: {
            name: 'Mujda Alamzai-Oh',
            href: 'https://www.linkedin.com/in/mujdaalamzai/',
            imageUrl: process.env.PUBLIC_URL + "/assets/headshots/blog_headshot_mujda.jpeg"
        },
    },
    {
        title: 'Document Roll Call: Analytical Procedures',
        href: '/blog/clia-analytical-procedures',
        category: {name: 'Article'},
        description:
            "It’s Tuesday! That means it’s time for another Document Roll Call. Today, we are covering the policies " +
            "your laboratory should have around analytical procedures. Stay tuned in to our blog every week for not " +
            "only our Document Roll Call series but many more free resources for managing your clinical laboratory’s " +
            "quality program."
        ,
        date: 'June 21, 2022',
        datetime: '2022-06-21',
        imageUrl:
            process.env.PUBLIC_URL + "/assets/blog/blog11_analytical_drc.png",
        author: {
            name: 'Mujda Alamzai-Oh',
            href: 'https://www.linkedin.com/in/mujdaalamzai/',
            imageUrl: process.env.PUBLIC_URL + "/assets/headshots/blog_headshot_mujda.jpeg"
        },
    },
    {
        title: 'Document Roll Call: Pre-Analytical Procedures',
        href: '/blog/clia-pre-analytical-procedures',
        category: {name: 'Article'},
        description:
            "This week Decoto Labs is excited to bring another installment of Document Roll Call. This Tuesday, we " +
            "are covering the policies and procedures your laboratory should have around pre-analytical procedures. " +
            "Stay tuned in to our blog every week for not only our Document Roll Call series but many more free " +
            "resources for managing your clinical laboratory’s quality program."
        ,
        date: 'June 14, 2022',
        datetime: '2022-06-14',
        imageUrl:
            process.env.PUBLIC_URL + "/assets/blog/blog10_pre_analytical_drc.png",
        author: {
            name: 'Mujda Alamzai-Oh',
            href: 'https://www.linkedin.com/in/mujdaalamzai/',
            imageUrl: process.env.PUBLIC_URL + "/assets/headshots/blog_headshot_mujda.jpeg"
        },
    },
    {
        title: 'To Consult or Not to Consult?',
        href: '/blog/clia-consultant-considerations',
        category: {name: 'Article'},
        description:
            "Starting up a CLIA compliant laboratory can be incredibly daunting. Questions like \"What kind of " +
            "certificate should we pursue?” and “Where do we even start with the paperwork?” can seem impossible to " +
            "answer. Many laboratories choose to engage the services of a consultant to prepare their laboratory and " +
            "guide them through the process of obtaining CLIA licensure. However, is this the right move for your " +
            "laboratory? "
        ,
        date: 'June 9, 2022',
        datetime: '2022-06-09',
        imageUrl:
            process.env.PUBLIC_URL + "/assets/blog/blog9_consult_graphic.png",
        author: {
            name: 'Mujda Alamzai-Oh',
            href: 'https://www.linkedin.com/in/mujdaalamzai/',
            imageUrl: process.env.PUBLIC_URL + "/assets/headshots/blog_headshot_mujda.jpeg"
        },
    },
    {
        title: 'Document Roll Call: Reagents & Equipment',
        href: '/blog/clia-reagent-and-equipment-procedures',
        category: {name: 'Article'},
        description:
            "Congratulations! You’ve made it to the third installment of our Document Roll Call series. This Tuesday, " +
            "our Document Roll Call will cover the reagent and equipment policies and procedures your laboratory " +
            "should have. Follow our blog to see which document category we are covering each week and see if you " +
            "notice in gaps in your own policies and procedures. "
        ,
        date: 'June 7, 2022',
        datetime: '2022-06-07',
        imageUrl:
            process.env.PUBLIC_URL + "/assets/blog/blog8_rande_drc.png",
        author: {
            name: 'Mujda Alamzai-Oh',
            href: 'https://www.linkedin.com/in/mujdaalamzai/',
            imageUrl: process.env.PUBLIC_URL + "/assets/headshots/blog_headshot_mujda.jpeg"
        },
    },
    {
        title: 'Document Roll Call: Safety',
        href: '/blog/clia-safety-procedures',
        category: {name: 'Article'},
        description:
            "We are back with the second installment of our Document Roll Call series. This Tuesday, our Document " +
            "Roll Call will cover the safety related policies and procedures your laboratory will need. Follow our " +
            "blog to see which document category we are covering each week and see if you notice in gaps in your own " +
            "policies and procedures. "
        ,
        date: 'May 31, 2022',
        datetime: '2022-05-31',
        imageUrl:
            process.env.PUBLIC_URL + "/assets/blog/blog7_safety_drc.png",
        author: {
            name: 'Mujda Alamzai-Oh',
            href: 'https://www.linkedin.com/in/mujdaalamzai/',
            imageUrl: process.env.PUBLIC_URL + "/assets/headshots/blog_headshot_mujda.jpeg"
        },
    },
    {
        title: 'Inspection Day Tips',
        href: '/blog/cap-clia-cola-inspection-tips',
        category: {name: 'Article'},
        description:
            "Congratulations! You’ve made it  to inspection day. You’ve put in an immense amount of effort getting " +
            "your laboratory up and running. It is not easy to learn all of the requirements a clinical laboratory " +
            "must meet and discern how your laboratory will meet the high regulatory bar. Use these tips to help you " +
            "have a seamless inspection day."
        ,
        date: 'May 26, 2022',
        datetime: '2022-05-26',
        imageUrl:
            process.env.PUBLIC_URL + "/assets/blog/blog6_inspection_tips.png",
        author: {
            name: 'Mujda Alamzai-Oh',
            href: 'https://www.linkedin.com/in/mujdaalamzai/',
            imageUrl: process.env.PUBLIC_URL + "/assets/headshots/blog_headshot_mujda.jpeg"
        },
    },
    {
        title: 'Document Roll Call: Quality Management',
        href: '/blog/clia-quality-management-procedures',
        category: {name: 'Article'},
        description:
            "Decoto Labs is delighted to bring you our Document Roll Call series. Every Tuesday, visit our blog to " +
            "see which document category we are covering and see if you notice any gaps in your own policies and " +
            "procedures. Our first Document Roll Call will detail the quality management objectives and aspects you " +
            "may want to include in your own program."
        ,
        date: 'May 24, 2022',
        datetime: '2022-05-24',
        imageUrl:
            process.env.PUBLIC_URL + "/assets/blog/blog5_qm_drc.png",
        author: {
            name: 'Mujda Alamzai-Oh',
            href: 'https://www.linkedin.com/in/mujdaalamzai/',
            imageUrl: process.env.PUBLIC_URL + "/assets/headshots/blog_headshot_mujda.jpeg"
        },
    },
    {
        title: 'So you want to start a CLIA Lab?',
        href: '/blog/new-clia-lab-steps',
        category: {name: 'Article'},
        description:
            "You have finally gotten your diagnostic technology to work and now you have to jump through regulatory " +
            "hoops to actually deliver it to patients in need. Never fear! The process of getting your CLIA license " +
            "does not have to be daunting. Learn about the logistics of CLIA licensure and the activities you can " +
            "expect to spend your time on leading up to inspection day."
        ,
        date: 'May 19, 2022',
        datetime: '2022-05-19',
        imageUrl:
            process.env.PUBLIC_URL + "/assets/blog/blog4_new_clia_lab_img.png",
        author: {
            name: 'Mujda Alamzai-Oh',
            href: 'https://www.linkedin.com/in/mujdaalamzai/',
            imageUrl: process.env.PUBLIC_URL + "/assets/headshots/blog_headshot_mujda.jpeg"
        },
    },
    {
        title: 'Looking for a MediaLab alternative?',
        href: '/blog/medialab-alternative-regulator',
        category: {name: 'Article'},
        description:
            "Veterans of the clinical lab industry will tell you MediaLab is the go-to quality management " +
            "solution. But is it the best way just because it is the way things have always been done? " +
            "Read on to learn about what both Regulator and MediaLab bring to the table and which tool is " +
            "best for your laboratory's needs.",
        date: 'May 12, 2022',
        datetime: '2022-05-12',
        imageUrl:
            process.env.PUBLIC_URL + "/assets/blog/blog3_medialab_alternative_img.png",
        author: {
            name: 'Mujda Alamzai-Oh',
            href: 'https://www.linkedin.com/in/mujdaalamzai/',
            imageUrl: process.env.PUBLIC_URL + "/assets/headshots/blog_headshot_mujda.jpeg"
        },
    },
    {
        title: 'The Regulator Early Adopter Program is now Open!',
        href: '/blog/new-clia-qms-regulator-early-adoption',
        category: {name: 'Press Release'},
        description:
            "Decoto Labs is thrilled to announce our quality management system (QMS) offering, Regulator, is now " +
            "available for early adoption! Find out whether you would be a good fit to be an early adopter and how " +
            "to get involved.",
        date: 'Apr 28, 2022',
        datetime: '2022-04-28',
        imageUrl:
            process.env.PUBLIC_URL + "/assets/blog/blog2_regulator_early_img.png",
        author: {
            name: 'Mujda Alamzai-Oh',
            href: 'https://www.linkedin.com/in/mujdaalamzai/',
            imageUrl: process.env.PUBLIC_URL + "/assets/headshots/blog_headshot_mujda.jpeg"
        },
    },
    {
        title: 'The 10 most prevalent CLIA deficiencies and how to avoid them.',
        href: '/blog/top-10-clia-deficiencies-and-how-to-avoid',
        category: {name: 'Article'},
        description:
            "Inspectors will be keeping a keen eye out for these common mistakes. Ensure your laboratory passes with " +
            "flying colors. Learn about how Decoto Labs' quality management tool, Regulator, can make your lab work " +
            "better.",
        date: 'Apr 20, 2022',
        datetime: '2022-04-20',
        imageUrl:
            process.env.PUBLIC_URL + "/assets/blog/blog1_clia_def_img.png",
        author: {
            name: 'Mujda Alamzai-Oh',
            href: 'https://www.linkedin.com/in/mujdaalamzai/',
            imageUrl: process.env.PUBLIC_URL + "/assets/headshots/blog_headshot_mujda.jpeg"
        },
    }
]

export default function BlogPage() {
    return (
        <div>
            <div className="relative bg-white pt-16 px-4 pb-56 sm:px-6 lg:pt-24 lg:px-8">
                <div className="absolute inset-0">
                    <div className="bg-white h-1/3 sm:h-2/3"/>
                </div>
                <div className="relative max-w-7xl mx-auto">
                    <div className="text-center">
                        <h2 className="text-3xl tracking-tight font-extrabold text-gray-900 sm:text-4xl">From the
                            blog</h2>
                        <p className="mt-3 max-w-2xl mx-auto text-xl text-gray-500 sm:mt-4">
                            Keep up with the latest news in <br/> quality management and regulatory software tooling.
                        </p>
                    </div>
                    <div className="mt-12 max-w-lg mx-auto grid gap-5 lg:grid-cols-3 lg:max-w-none">
                        {posts.map((post) => (
                            <div key={post.title} className="flex flex-col rounded-lg shadow-lg overflow-hidden">
                                <div className="flex-shrink-0">
                                    <img className="h-48 w-full object-cover" src={post.imageUrl} alt=""/>
                                </div>
                                <div className="flex-1 bg-white p-6 flex flex-col justify-between">
                                    <div className="flex-1">
                                        <p className="text-sm font-medium text-blue-600">
                                            {post.category.name}
                                        </p>
                                        <Link to={post.href} className="block mt-2">
                                            <p className="text-xl font-semibold text-gray-900">{post.title}</p>
                                            <p className="mt-3 text-base text-gray-500">{post.description}</p>
                                        </Link>
                                    </div>
                                    <div className="mt-6 flex items-center">
                                        <div className="flex-shrink-0">
                                            <a href={post.author.href}
                                               target="_blank"
                                               rel="noreferrer"
                                            >
                                                <span className="sr-only">{post.author.name}</span>
                                                <img className="h-10 w-10 rounded-full" src={post.author.imageUrl} alt="" />
                                            </a>
                                        </div>
                                        <div className="ml-3">
                                            <p className="text-sm font-medium text-gray-900">
                                                <a href={post.author.href}
                                                   target="_blank"
                                                   rel="noreferrer"
                                                   className="hover:underline">
                                                    {post.author.name}
                                                </a>
                                            </p>
                                            <div className="flex space-x-1 text-sm text-gray-500">
                                                <time dateTime={post.datetime}>{post.date}</time>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    )
}
