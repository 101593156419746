import {ArrowLeftIcon} from "@heroicons/react/solid";
import React from "react";
import {Link} from "react-router-dom";

export default function BlogPost10() {

    return (
        <div className="relative py-16 bg-white overflow-hidden">
            <div className="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
                <div className="relative h-full text-lg max-w-prose mx-auto" aria-hidden="true">
                    <svg
                        className="absolute top-12 left-full transform translate-x-32"
                        width={404}
                        height={384}
                        fill="none"
                        viewBox="0 0 404 384"
                    >
                        <defs>
                            <pattern
                                id="74b3fd99-0a6f-4271-bef2-e80eeafdf357"
                                x={0}
                                y={0}
                                width={20}
                                height={20}
                                patternUnits="userSpaceOnUse"
                            >
                                <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor"/>
                            </pattern>
                        </defs>
                        <rect width={404} height={384} fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)"/>
                    </svg>
                    <svg
                        className="absolute top-1/2 right-full transform -translate-y-1/2 -translate-x-32"
                        width={404}
                        height={384}
                        fill="none"
                        viewBox="0 0 404 384"
                    >
                        <defs>
                            <pattern
                                id="f210dbf6-a58d-4871-961e-36d5016a0f49"
                                x={0}
                                y={0}
                                width={20}
                                height={20}
                                patternUnits="userSpaceOnUse"
                            >
                                <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor"/>
                            </pattern>
                        </defs>
                        <rect width={404} height={384} fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)"/>
                    </svg>
                    <svg
                        className="absolute bottom-12 left-full transform translate-x-32"
                        width={404}
                        height={384}
                        fill="none"
                        viewBox="0 0 404 384"
                    >
                        <defs>
                            <pattern
                                id="d3eb07ae-5182-43e6-857d-35c643af9034"
                                x={0}
                                y={0}
                                width={20}
                                height={20}
                                patternUnits="userSpaceOnUse"
                            >
                                <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor"/>
                            </pattern>
                        </defs>
                        <rect width={404} height={384} fill="url(#d3eb07ae-5182-43e6-857d-35c643af9034)"/>
                    </svg>
                </div>
            </div>
            <div className="mt-4 relative px-4 sm:px-6 lg:px-8">
                <div className="text-lg max-w-prose mx-auto">
                    <Link to="/blog"
                          className="inline-flex space-x-4">
                        <span
                            className="inline-flex items-center text-sm font-medium text-blue-500 space-x-1">
                      <ArrowLeftIcon className="h-5 w-5" aria-hidden="true"/>
                            <span>Back to Blog</span>
                    </span>
                    </Link>
                    <span className="block text-base text-center text-blue-600 font-semibold tracking-wide uppercase">
                        Article
                    </span>
                    <h1>
                        <span
                            className="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              Document Roll Call: Pre-Analytical Procedures
            </span>
                    </h1>
                    <p className="mt-8 text-xl text-gray-500 leading-8">
                        This week Decoto Labs is excited to bring another installment of Document Roll Call. This
                        Tuesday, we are covering the policies and procedures your laboratory should have around
                        pre-analytical procedures. Stay tuned in to our blog every week for not only our Document
                        Roll Call series but many more free resources for managing your clinical laboratory’s quality
                        program.
                    </p>
                </div>
                <figure className="my-2">
                    <img
                        className="mx-auto w-7/12 rounded-lg"
                        src={process.env.PUBLIC_URL+"/assets/blog/blog10_pre_analytical_drc.png"}
                        alt=""
                    />
                </figure>
                <div className="mt-6 prose prose-blue prose-lg text-gray-500 mx-auto">
                    <h2>Pre-Analytical Procedure Program Breakdown</h2>
                    <p>
                        Unsure where to start with your laboratory’s pre-analytical procedure program? Here are some of
                        the documents you may want to implement in your laboratory and the points to make sure your
                        documents touch on. Please note these documents are just our recommendations based on our
                        experience working in clinical laboratory settings and do not guarantee that this list is
                        exhaustive.
                    </p>
                    <h3>Specimen Collection Manual</h3>
                    <ul>
                       <li className="list-disc">
                           Include your laboratory’s procedure for preparing the client for specimen collection
                       </li>
                        <li className="list-disc">
                            Include your laboratory’s collection container type(s) and the amount of specimen to be
                            collected
                        </li>
                        <li className="list-disc">
                            Include your laboratory’s procedure for special timing of specimen collection as applicable
                        </li>
                        <li className="list-disc">
                            Include your laboratory’s selected preservatives and coagulants used for specimen collection
                        </li>
                        <li className="list-disc">
                            Include your laboratory’s procedure for special handling between time of collection and
                            time received as applicable
                        </li>
                        <li className="list-disc">
                            Include your laboratory’s procedure for proper specimen labeling
                        </li>
                        <li className="list-disc">
                            Include your laboratory’s procedure for collecting appropriate clinical data as applicable
                        </li>
                        <li className="list-disc">
                            Include your laboratory’s procedure to care for patients who experience adverse reactions
                            from phlebotomy
                        </li>
                    </ul>
                    <h3>Sample Accessioning Procedure</h3>
                    <ul>
                        <li className="list-disc">
                            Include your laboratory’s policies and procedures for ensuring all specimens are accompanied
                            by an adequate requisition
                        </li>
                        <li className="list-disc">
                            Include your laboratory’s procedure for ensuring test requisition data elements are entered
                            accurately into the laboratory information and/or record system
                        </li>
                        <li className="list-disc">
                            Include your laboratory’s procedure for positively identifying all patient specimens at all
                            times
                        </li>
                        <li className="list-disc">
                            Include your laboratory’s procedure for ensuring that the date and time of specimen receipt
                            is recorded
                        </li>
                        <li className="list-disc">
                            Include your laboratory’s procedure for ensuring specimens are only analyzed at the request
                            of an authorized person
                        </li>
                        <li className="list-disc">
                            Include your laboratory’s procedure for ensuring written and/or electronic authorization is
                            obtained for verbal orders within 30 days
                        </li>
                        <li className="list-disc">
                            Include your laboratory’s read-back policy for verbal and phone orders
                        </li>
                        <li className="list-disc">
                            Include your laboratory’s policy for confirmation of test orders that might be unclear
                        </li>
                        <li className="list-disc">
                            Include your laboratory’s procedure for procurement, transportation and handling of patient
                            specimens to ensure submitted specimens are appropriately labeled and with secure lid
                        </li>
                    </ul>
                    <h3>Pre-Analytical Quality Management</h3>
                    <ul>
                        <li className="list-disc">
                            Include your laboratory’s procedure for monitoring quality of submitted specimens,
                            correcting problems in transportation and improving performance of clients or sites that
                            frequently submit improperly
                        </li>
                        <li className="list-disc">
                            Include your laboratory’s procedure for providing feedback to collectors of specimens on
                            issues relating to specimen quality and labeling
                        </li>
                        <li className="list-disc">
                            Include your laboratory’s criteria for rejection of unacceptable specimens, handling of
                            sub-optimal specimens, and records of disposal of unaccepted specimens
                        </li>
                        <li className="list-disc">
                            Include your laboratory’s procedure for ensuring that specimen collection supplies will only
                            be used within the expiration date and stored per manufacturer’s instructions
                        </li>
                        <li className="list-disc">
                            Include your laboratory’s policy for correction of information on specimen labels
                        </li>
                        <li className="list-disc">
                            Include your laboratory’s procedure for ensuring that samples will package and ship
                            infectious material in accordance with federal, state and local regulations
                        </li>
                    </ul>
                    <h3>Pre-Analytical Procedures</h3>
                    <ul>
                        <li className="list-disc">
                            Include your laboratory’s procedure for patient identification, patient preparation,
                            specimen collection and labelling, specimen preservation, conditions for transport,
                            storage before testing
                        </li>
                        <li className="list-disc">
                            Include your laboratory’s policies for ensuring pre-analytical procedures are consistent
                            with good laboratory practice (GLP)
                        </li>
                        <li className="list-disc">
                            Include your laboratory’s procedure for ensuring primary specimen containers are labeled
                            with two patient specific identifiers
                        </li>
                        <li className="list-disc">
                            Include your laboratory’s procedure for specimen identification through all phases of
                            testing including but not limited to: aliquots, dilutions, nucleic acids, etc.
                        </li>
                        <li className="list-disc">
                            Include your laboratory’s procedure for ensuring the laboratory director will review and
                            approve all new specimen collection and handling procedures and all substantial changes
                        </li>
                        <li className="list-disc">
                            Include your laboratory’s procedure for ensuring the laboratory director will evaluate
                            significant changes to specimen containers to ensure that they do not contribute to analytic
                            interference and approve them for use
                        </li>
                    </ul>
                    <h2>Still have questions on pre-analytical procedures?</h2>
                    <p>
                        The Decoto Labs team is happy to help however we can. Whether you need document templates,
                        pre-analytical quality management guidance or tips on how to put together an effectice specimen
                        collection manual &#8212; we want to help.
                    </p>
                    <p>
                        Visit our <a href="https://decotolabs.com">homepage</a>{" "} to schedule time with our founders
                        to get your questions answered or reach out via email to
                        <a href="mailto:info@decotolabs.com">info@decotolabs.com</a>.
                        You can also keep up with our blog posts and resources by following our{" "}
                        <a href="https://www.linkedin.com/company/decoto-labs" target="_blank" rel="noreferrer">LinkedIn</a> page.
                    </p>
                    <h2>Interested in trying our QMS, Regulator?</h2>
                    <p>
                        Our Early Adopter program is still open, but spots are filling up quickly. You can reach us via
                        email at{" "}
                        <a href="mailto:info@decotolabs.com">info@decotolabs.com</a>{" "}
                        with “Early Adopter Program” as the subject line. Still not sure? Visit our{" "}
                        <a href="https://decotolabs.com">homepage</a>{" "}
                        where you can directly schedule time to speak with our founders and get your questions answered.
                    </p>
                </div>
            </div>
        </div>
    )
}
