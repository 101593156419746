import {Link} from "react-router-dom";
import {HashLink} from "react-router-hash-link";

const footerNavigation = {
    main: [
        {name: 'Home', href: '/'},
        { name: 'Pricing', href: '/pricing' },
        { name: 'Blog', href: '/blog' },
        { name: 'Team', href: '/team' },
    ]
}


export default function Footer() {
    return(
        <footer className="mt-24 bg-blue-600 sm:mt-12">
            <div className="mx-auto max-w-md py-12 px-4 overflow-hidden sm:max-w-3xl sm:px-6 lg:max-w-7xl lg:px-8">
                <nav className="-mx-5 -my-2 flex flex-wrap justify-center" aria-label="Footer">
                    {footerNavigation.main.map((item) => (
                        <div key={item.name} className="px-5 py-2">
                            <Link to={item.href} className="text-base text-white hover:text-blue-200">
                                {item.name}
                            </Link>
                        </div>
                    ))}
                    <div className="px-5 py-2">
                        <a className="text-base text-white hover:text-blue-200"
                           target="_blank"
                           rel="noreferrer"
                           href='https://decotolabs.notion.site/Decoto-Labs-Documentation-9c49392dc9b54c44af10973ee5f59b12'>
                            Documentation
                        </a>
                    </div>
                </nav>
                <p className="mt-8 text-center text-base text-white">2022 Decoto Labs, LLC. All rights reserved. | <HashLink
                    to={"/privacy-policy#top"}
                    className="font-medium underline"
                >
                    Privacy Policy
                </HashLink> | <HashLink
                    to={"/terms-of-service#top"}
                    className="font-medium underline"
                >
                   Terms of Service
                </HashLink></p>
            </div>
        </footer>
    )
}