import {ArrowLeftIcon} from "@heroicons/react/solid";
import React from "react";
import {Link} from "react-router-dom";

export default function BlogPost11() {

    return (
        <div className="relative py-16 bg-white overflow-hidden">
            <div className="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
                <div className="relative h-full text-lg max-w-prose mx-auto" aria-hidden="true">
                    <svg
                        className="absolute top-12 left-full transform translate-x-32"
                        width={404}
                        height={384}
                        fill="none"
                        viewBox="0 0 404 384"
                    >
                        <defs>
                            <pattern
                                id="74b3fd99-0a6f-4271-bef2-e80eeafdf357"
                                x={0}
                                y={0}
                                width={20}
                                height={20}
                                patternUnits="userSpaceOnUse"
                            >
                                <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor"/>
                            </pattern>
                        </defs>
                        <rect width={404} height={384} fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)"/>
                    </svg>
                    <svg
                        className="absolute top-1/2 right-full transform -translate-y-1/2 -translate-x-32"
                        width={404}
                        height={384}
                        fill="none"
                        viewBox="0 0 404 384"
                    >
                        <defs>
                            <pattern
                                id="f210dbf6-a58d-4871-961e-36d5016a0f49"
                                x={0}
                                y={0}
                                width={20}
                                height={20}
                                patternUnits="userSpaceOnUse"
                            >
                                <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor"/>
                            </pattern>
                        </defs>
                        <rect width={404} height={384} fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)"/>
                    </svg>
                    <svg
                        className="absolute bottom-12 left-full transform translate-x-32"
                        width={404}
                        height={384}
                        fill="none"
                        viewBox="0 0 404 384"
                    >
                        <defs>
                            <pattern
                                id="d3eb07ae-5182-43e6-857d-35c643af9034"
                                x={0}
                                y={0}
                                width={20}
                                height={20}
                                patternUnits="userSpaceOnUse"
                            >
                                <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor"/>
                            </pattern>
                        </defs>
                        <rect width={404} height={384} fill="url(#d3eb07ae-5182-43e6-857d-35c643af9034)"/>
                    </svg>
                </div>
            </div>
            <div className="mt-4 relative px-4 sm:px-6 lg:px-8">
                <div className="text-lg max-w-prose mx-auto">
                    <Link to="/blog"
                          className="inline-flex space-x-4">
                        <span
                            className="inline-flex items-center text-sm font-medium text-blue-500 space-x-1">
                      <ArrowLeftIcon className="h-5 w-5" aria-hidden="true"/>
                            <span>Back to Blog</span>
                    </span>
                    </Link>
                    <span className="block text-base text-center text-blue-600 font-semibold tracking-wide uppercase">
                        Article
                    </span>
                    <h1>
                        <span
                            className="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              Document Roll Call: Analytical Procedures
            </span>
                    </h1>
                    <p className="mt-8 text-xl text-gray-500 leading-8">
                        It’s Tuesday! That means it’s time for another Document Roll Call. Today, we are covering the
                        policies your laboratory should have around analytical procedures. Stay tuned in to our blog
                        every week for not only our Document Roll Call series but many more free resources for managing
                        your clinical laboratory’s quality program.
                    </p>
                </div>
                <figure className="my-2">
                    <img
                        className="mx-auto w-7/12 rounded-lg"
                        src={process.env.PUBLIC_URL+"/assets/blog/blog11_analytical_drc.png"}
                        alt=""
                    />
                </figure>
                <div className="mt-6 prose prose-blue prose-lg text-gray-500 mx-auto">
                    <h2>Analytical Procedure Program Breakdown</h2>
                    <p>
                        Unsure where to start with your laboratory’s analytical procedure program? Here are some of the
                        documents you may want to implement in your laboratory and the points to make sure your
                        documents touch on. Please note these documents are just our recommendations based on our
                        experience working in clinical laboratory settings and do not guarantee that this list is
                        exhaustive.
                    </p>
                    <h3>Analytical Procedure Quality Management</h3>
                    <ul>
                        <li className="list-disc">
                            Include your laboratory's policies and procedures for monitoring analytical performance
                        </li>
                        <li className="list-disc">
                            Include your laboratory's policy for following manufacturer's instructions for the
                            introduction of the instrument(s) or device(s) for each waived test
                        </li>
                        <li className="list-disc">
                            Include your laboratory’s procedure for special timing of specimen collection as applicable
                        </li>
                        <li className="list-disc">
                            Include your laboratory’s policy for ensuring that an evaluation of the test method
                            validation or verification study is reviewed and approved by the laboratory director prior
                            to use in patient testing
                        </li>
                        <li className="list-disc">
                            Include your laboratory’s procedure for calibration and quality control for all modified FDA
                            and laboratory developed tests
                        </li>
                    </ul>
                    <h3>Validation and Verification</h3>
                    <ul>
                        <li className="list-disc">
                            Include your laboratory’s procedure for establishing analytical accuracy and precision for
                            each test
                        </li>
                        <li className="list-disc">
                            Include your laboratory’s procedure for validation modified FDA and laboratory developed
                            tests (LDTs)
                            <ul>
                                <li className="list-disc">
                                    Include your laboratory’s procedure to establish analytical accuracy
                                </li>
                                <li className="list-disc">
                                    Include your laboratory’s procedure to establish analytical sensitivity
                                </li>
                                <li className="list-disc">
                                    Include your laboratory’s procedure to establish analytical specificity
                                </li>
                            </ul>
                        </li>
                        <li className="list-disc">
                            Include your laboratory’s plan of action when analytical interferences are present
                        </li>
                        <li className="list-disc">
                            Include your laboratory’s procedure for establishing the test’s reportable range
                        </li>
                        <li className="list-disc">
                            Include your laboratory’s procedure for establishing and verifying reference internals and
                            a corrective action plan for results outside of the intervals
                        </li>
                        <li className="list-disc">
                            Include your laboratory’s procedure for validating and establishing metrics for
                            interpretation for body fluid analysis methods
                        </li>
                    </ul>
                    <h3>Restarting a Test in Production</h3>
                    <ul>
                        <li className="list-disc">
                            Include your laboratory’s procedure for putting a test back into production
                            <ul>
                                <li className="list-disc">
                                    Include records of performing proficiency testing within 30 days prior to restarting
                                    testing
                                </li>
                                <li className="list-disc">
                                    Include records of performing method performance specification verification within
                                    30 days prior to restarting testing
                                </li>
                                <li className="list-disc">
                                    Include records for assessing competency of analysts within 12 months prior to
                                    restarting testing
                                </li>
                            </ul>
                        </li>
                    </ul>
                    <h3>Client Facing Documents</h3>
                    <ul>
                        <li className="list-disc">
                            A summary of the analytical performance specifications for each test
                        </li>
                        <li className="list-disc">
                            A compilation of supporting data for clinical performance claims for each test
                        </li>
                        <li className="list-disc">
                            Notification documents for any significant changes to the analytical methodology
                        </li>
                    </ul>
                    <h2>Still have questions on analytical procedures?</h2>
                    <p>
                        The Decoto Labs team is happy to help however we can. Whether you need document templates,
                        validation and verification guidance or tips on how to establish procedures for monitoring
                        analytical performance &#8212; we want to help.
                    </p>
                    <p>
                        Visit our <a href="https://decotolabs.com">homepage</a>{" "} to schedule time with our founders
                        to get your questions answered or reach out via email to
                        <a href="mailto:info@decotolabs.com">info@decotolabs.com</a>.
                        You can also keep up with our blog posts and resources by following our{" "}
                        <a href="https://www.linkedin.com/company/decoto-labs" target="_blank" rel="noreferrer">LinkedIn</a> page.
                    </p>
                    <h2>Interested in trying our QMS, Regulator?</h2>
                    <p>
                        Our Early Adopter program is still open, but spots are filling up quickly. You can reach us via
                        email at{" "}
                        <a href="mailto:info@decotolabs.com">info@decotolabs.com</a>{" "}
                        with “Early Adopter Program” as the subject line. Still not sure? Visit our{" "}
                        <a href="https://decotolabs.com">homepage</a>{" "}
                        where you can directly schedule time to speak with our founders and get your questions answered.
                    </p>
                </div>
            </div>
        </div>
    )
}
