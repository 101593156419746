import Footer from "./common/Footer";

const people = [
    {
        name: 'Mujda Alamzai-Oh',
        role: 'Co-Founder / CEO',
        imageUrl:
            process.env.PUBLIC_URL + "/assets/headshots/mujda_headshot.jpeg",
        bio: "Mujda began her professional career working in quality" +
            " management for laboratories ranging from startups to high-throughput. She went on to earn a Master's in" +
            " Computer Science and work as a Software Development Engineer at Amazon. In 2021, Mujda founded Decoto Labs" +
            " with the mission of bringing clinical laboratory software into the 21st century.",
        linkedinUrl: 'https://www.linkedin.com/in/mujdaalamzai/',
    },
    {
        name: 'Hugh Oh',
        role: 'Co-Founder / CTO',
        imageUrl:
            process.env.PUBLIC_URL + "/assets/headshots/hugh_headshot.jpeg",
        bio: "Hugh has been working in Silicon Valley over the last decade at companies ranging from small startups to " +
            "giants like Google and Facebook. Hugh and Mujda met at small biotech company where they discovered their " +
            "shared passion for building better tools for clinical laboratories.",
        linkedinUrl: 'https://www.linkedin.com/in/hughoh/',
    }
]

export default function TeamPage() {
    return (
        <div className="bg-white">
            <div className="h-full mx-auto py-12 px-4 max-w-7xl sm:px-6 lg:px-8 lg:py-24">
                <div className="space-y-12 lg:grid lg:grid-cols-3 lg:gap-8 lg:space-y-0">
                    <div className="space-y-5 sm:space-y-4">
                        <h2 className="text-3xl font-extrabold tracking-tight sm:text-4xl">Meet the Founders</h2>
                        <p className="text-xl text-gray-500">
                            The Decoto Labs team is made up of professionals passionate about leveraging technology to
                            afford scientists time to make the next big discovery and afford patients safety and dignity
                            in the laboratory.
                        </p>
                    </div>
                    <div className="lg:col-span-2">
                        <ul
                            className="space-y-12 sm:divide-y sm:divide-gray-200 sm:space-y-0 sm:-mt-8 lg:gap-x-8 lg:space-y-0"
                        >
                            {people.map((person) => (
                                <li key={person.name} className="sm:py-8">
                                    <div className="space-y-4 sm:grid sm:grid-cols-3 sm:items-start sm:gap-6 sm:space-y-0">
                                        <div className="aspect-w-3 aspect-h-2 sm:aspect-w-3 sm:aspect-h-4">
                                            <img className="object-contain shadow-lg rounded-lg" src={person.imageUrl} alt="" />
                                        </div>
                                        <div className="sm:col-span-2">
                                            <div className="space-y-4">
                                                <div className="text-lg leading-6 font-medium space-y-1">
                                                    <h3>{person.name}</h3>
                                                    <p className="text-blue-600">{person.role}</p>
                                                </div>
                                                <div className="text-lg">
                                                    <p className="text-gray-500">{person.bio}</p>
                                                </div>
                                                <ul className="flex space-x-5">
                                                    <li>
                                                        <a href={person.linkedinUrl} className="text-gray-400 hover:text-gray-500">
                                                            <span className="sr-only">LinkedIn</span>
                                                            <svg className="w-5 h-5" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20">
                                                                <path
                                                                    fillRule="evenodd"
                                                                    d="M16.338 16.338H13.67V12.16c0-.995-.017-2.277-1.387-2.277-1.39 0-1.601 1.086-1.601 2.207v4.248H8.014v-8.59h2.559v1.174h.037c.356-.675 1.227-1.387 2.526-1.387 2.703 0 3.203 1.778 3.203 4.092v4.711zM5.005 6.575a1.548 1.548 0 11-.003-3.096 1.548 1.548 0 01.003 3.096zm-1.337 9.763H6.34v-8.59H3.667v8.59zM17.668 1H2.328C1.595 1 1 1.581 1 2.298v15.403C1 18.418 1.595 19 2.328 19h15.34c.734 0 1.332-.582 1.332-1.299V2.298C19 1.581 18.402 1 17.668 1z"
                                                                    clipRule="evenodd"
                                                                />
                                                            </svg>
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
                <h1 className="mt-2 text-5xl font-extrabold text-center mb-8">Built by engineers and quality professionals from</h1>
                <div className="sm:pl-24 grid sm:grid-cols-7 grid-cols-3 text-center">
                    <img className="sm:mt-6 sm:ml-12 p-2" width="100" height="100"
                         src={process.env.PUBLIC_URL+"/assets/about/google.jpg"} alt="Google"/>
                    <img className="sm:mt-6 sm:ml-12 p-2" width="100" height="100"
                         src={process.env.PUBLIC_URL+"/assets/about/facebook.jpg"} alt="Facebook"/>
                    <img className="sm:mt-6 sm:ml-12 p-2" width="100" height="100"
                         src={process.env.PUBLIC_URL+"/assets/about/amazon.jpg"} alt="Amazon"/>
                    <img className="sm:mt-6 sm:ml-12 p-2" width="100" height="100"
                         src={process.env.PUBLIC_URL+"/assets/about/airbnb.jpg"} alt="Airbnb"/>
                    <img className="sm:mt-6 sm:ml-6 p-2" width="100" height="100"
                         src={process.env.PUBLIC_URL+"/assets/about/guardant.jpg"} alt="Guardant Health"/>
                    <img className="sm:mt-14 mt-6" src={process.env.PUBLIC_URL+"/assets/about/adaptive.jpg"}
                         alt="Adaptive Biotechnologies"
                    />
                </div>
            </div>
            <Footer/>
        </div>
    )
}
