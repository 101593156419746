import {ArrowLeftIcon} from "@heroicons/react/solid";
import React from "react";
import {Link} from "react-router-dom";

export default function BlogPost9() {

    return (
        <div className="relative py-16 bg-white overflow-hidden">
            <div className="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
                <div className="relative h-full text-lg max-w-prose mx-auto" aria-hidden="true">
                    <svg
                        className="absolute top-12 left-full transform translate-x-32"
                        width={404}
                        height={384}
                        fill="none"
                        viewBox="0 0 404 384"
                    >
                        <defs>
                            <pattern
                                id="74b3fd99-0a6f-4271-bef2-e80eeafdf357"
                                x={0}
                                y={0}
                                width={20}
                                height={20}
                                patternUnits="userSpaceOnUse"
                            >
                                <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor"/>
                            </pattern>
                        </defs>
                        <rect width={404} height={384} fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)"/>
                    </svg>
                    <svg
                        className="absolute top-1/2 right-full transform -translate-y-1/2 -translate-x-32"
                        width={404}
                        height={384}
                        fill="none"
                        viewBox="0 0 404 384"
                    >
                        <defs>
                            <pattern
                                id="f210dbf6-a58d-4871-961e-36d5016a0f49"
                                x={0}
                                y={0}
                                width={20}
                                height={20}
                                patternUnits="userSpaceOnUse"
                            >
                                <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor"/>
                            </pattern>
                        </defs>
                        <rect width={404} height={384} fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)"/>
                    </svg>
                    <svg
                        className="absolute bottom-12 left-full transform translate-x-32"
                        width={404}
                        height={384}
                        fill="none"
                        viewBox="0 0 404 384"
                    >
                        <defs>
                            <pattern
                                id="d3eb07ae-5182-43e6-857d-35c643af9034"
                                x={0}
                                y={0}
                                width={20}
                                height={20}
                                patternUnits="userSpaceOnUse"
                            >
                                <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor"/>
                            </pattern>
                        </defs>
                        <rect width={404} height={384} fill="url(#d3eb07ae-5182-43e6-857d-35c643af9034)"/>
                    </svg>
                </div>
            </div>
            <div className="mt-4 relative px-4 sm:px-6 lg:px-8">
                <div className="text-lg max-w-prose mx-auto">
                    <Link to="/blog"
                          className="inline-flex space-x-4">
                        <span
                            className="inline-flex items-center text-sm font-medium text-blue-500 space-x-1">
                      <ArrowLeftIcon className="h-5 w-5" aria-hidden="true"/>
                            <span>Back to Blog</span>
                    </span>
                    </Link>
                    <span className="block text-base text-center text-blue-600 font-semibold tracking-wide uppercase">
                        Article
                    </span>
                    <h1>
                        <span
                            className="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              To Consult or Not to Consult?
            </span>
                    </h1>
                    <p className="mt-8 text-xl text-gray-500 leading-8">
                        Starting up a CLIA compliant laboratory can be incredibly daunting. Questions like "What kind
                        of certificate should we pursue?” and “Where do we even start with the paperwork?” can seem
                        impossible to answer. Many laboratories choose to engage the services of a consultant to prepare
                        their laboratory and guide them through the process of obtaining CLIA licensure. However, is
                        this the right move for your laboratory?
                    </p>
                </div>
                <figure className="my-2">
                    <img
                        className="mx-auto w-7/12 rounded-lg"
                        src={process.env.PUBLIC_URL+"/assets/blog/blog9_consult_graphic.png"}
                        alt=""
                    />
                </figure>
                <div className="mt-6 prose prose-blue prose-lg text-gray-500 mx-auto">
                    <h2>Do you need a consultant?</h2>
                    <p>
                        As is the case with many of the other decisions you will have to make regarding your laboratory,
                        it depends. If your team lacks experience dealing with clinical laboratory regulations and your
                        budget allows it, a consultant can be an invaluable resource. Consultants have extensive
                        experience preparing laboratories for obtaining their license and passing their inspections.
                        Having a consultant assist you can help streamline your preparation process, ensure your systems
                        don’t leave any gaps and provide you with valuable insight regarding what inspectors will be
                        on the lookout for.
                        <br/><br/>
                        However, if your team has clinical regulatory expertise and you are not on strict timeline it
                        may make more sense for you to tackle your CLIA licensure activities yourself. Consider
                        exploring other cost effective options to facilitate your preparation. For instance, make
                        relationships with other laboratories seeking licensure that you could collaborate with on
                        establishing processes and have perform a mock inspection for your team. In addition, some
                        consultants may allow you to hire them for one-off services like mock inspections or gap
                        assessments which can supplement your own preparation activities.
                    </p>
                    <h2>What should you look for in a consultant?</h2>
                    <h3>Specialty and Complexity</h3>
                    <p>
                        First, any consultant you consider should have experience with testing in of the same complexity
                        and specialty that your laboratory is intending to perform. The compliance requirements vary
                        greatly between complexity level and testing speciality so having a consultant familiar with
                        regulations commensurate with your test menu can mean the difference between passing and
                        failing your survey.
                    </p>
                    <h3>Relevant Experience</h3>
                    <p>
                        In addition, potential consultants should have experience establishing compliance programs in
                        laboratories in a similar stage of development as your laboratory. Consultants may have years of
                        experience but if the majority of their time has been spent working in a larger organization
                        with a well-established quality management strategy, they may not have the expertise you are
                        looking for as a brand new start up lab.
                    </p>
                    <h3>Availability</h3>
                    <p>
                        Finally, an ideal consultant will have availability that corresponds to your needs. It is easy
                        for requirements to fall between the cracks or for consultants to give you only vague,
                        high-level guidance if they’re stretched thin with too many clients. Ensure the consultant you
                        ultimately select has the bandwidth to provide you with the level of support that your
                        laboratory and team require.
                    </p>
                    <h2>What questions should you ask potential consultants?</h2>
                    <ul>
                        <li className="list-disc">
                            What kinds of services are included in each of your packages?
                        </li>
                        <li className="list-disc">
                            How do you bill? Is it based on hours, on services? Do you offer any of your services “a la carte”?
                        </li>
                        <li className="list-disc">
                            What kind of support do you offer? Email, phone call? What will your availability be?
                        </li>
                        <li className="list-disc">
                            Do you provide any guarantees regarding inspection outcomes? Will you help with corrective
                            action in the event of an unsuccessful survey?
                        </li>
                    </ul>
                    <h2>Regulator Early Adopters Get Free Consulting Hours!</h2>
                    <p>
                        Regulator, Decoto Labs’ quality management system, has many of the regulatory requirements for
                        clinical laboratories built right in. Keeping track of your equipment calibrations,
                        reagent quality control, document review and training and competency is automatic for Regulator
                        users. But if you are still nervous about meeting your regulatory burdens, Decoto Labs provides
                        free consulting hours for Early Adopters.
                    </p>
                    <p>
                        Visit our <a href="https://decotolabs.com">homepage</a>{" "} to schedule time with our founders
                        to get your questions answered or reach out via email to <a href="mailto:info@decotolabs.com">info@decotolabs.com</a>.
                        You can also keep up with our blog posts and resources by following our{" "}
                        <a href="https://www.linkedin.com/company/decoto-labs" target="_blank" rel="noreferrer">LinkedIn</a> page.
                    </p>
                    <h2>Interested in trying our QMS, Regulator?</h2>
                    <p>
                        Our Early Adopter program is still open, but spots are filling up quickly. You can reach us via
                        email at{" "}
                        <a href="mailto:info@decotolabs.com">info@decotolabs.com</a>{" "}
                        with “Early Adopter Program” as the subject line. Still not sure? Visit our{" "}
                        <a href="https://decotolabs.com">homepage</a>{" "}
                        where you can directly schedule time to speak with our founders and get your questions answered.
                    </p>
                </div>
            </div>
        </div>
    )
}
