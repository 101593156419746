import {ArrowLeftIcon} from "@heroicons/react/solid";
import React from "react";
import {Link} from "react-router-dom";

export default function BlogPost6() {

    return (
        <div className="relative py-16 bg-white overflow-hidden">
            <div className="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
                <div className="relative h-full text-lg max-w-prose mx-auto" aria-hidden="true">
                    <svg
                        className="absolute top-12 left-full transform translate-x-32"
                        width={404}
                        height={384}
                        fill="none"
                        viewBox="0 0 404 384"
                    >
                        <defs>
                            <pattern
                                id="74b3fd99-0a6f-4271-bef2-e80eeafdf357"
                                x={0}
                                y={0}
                                width={20}
                                height={20}
                                patternUnits="userSpaceOnUse"
                            >
                                <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor"/>
                            </pattern>
                        </defs>
                        <rect width={404} height={384} fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)"/>
                    </svg>
                    <svg
                        className="absolute top-1/2 right-full transform -translate-y-1/2 -translate-x-32"
                        width={404}
                        height={384}
                        fill="none"
                        viewBox="0 0 404 384"
                    >
                        <defs>
                            <pattern
                                id="f210dbf6-a58d-4871-961e-36d5016a0f49"
                                x={0}
                                y={0}
                                width={20}
                                height={20}
                                patternUnits="userSpaceOnUse"
                            >
                                <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor"/>
                            </pattern>
                        </defs>
                        <rect width={404} height={384} fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)"/>
                    </svg>
                    <svg
                        className="absolute bottom-12 left-full transform translate-x-32"
                        width={404}
                        height={384}
                        fill="none"
                        viewBox="0 0 404 384"
                    >
                        <defs>
                            <pattern
                                id="d3eb07ae-5182-43e6-857d-35c643af9034"
                                x={0}
                                y={0}
                                width={20}
                                height={20}
                                patternUnits="userSpaceOnUse"
                            >
                                <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor"/>
                            </pattern>
                        </defs>
                        <rect width={404} height={384} fill="url(#d3eb07ae-5182-43e6-857d-35c643af9034)"/>
                    </svg>
                </div>
            </div>
            <div className="mt-4 relative px-4 sm:px-6 lg:px-8">
                <div className="text-lg max-w-prose mx-auto">
                    <Link to="/blog"
                          className="inline-flex space-x-4">
                        <span
                            className="inline-flex items-center text-sm font-medium text-blue-500 space-x-1">
                      <ArrowLeftIcon className="h-5 w-5" aria-hidden="true"/>
                            <span>Back to Blog</span>
                    </span>
                    </Link>
                    <span className="block text-base text-center text-blue-600 font-semibold tracking-wide uppercase">
                        Article
                    </span>
                    <h1>
                        <span
                            className="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              Inspection Day Tips
            </span>
                    </h1>
                    <p className="mt-8 text-xl text-gray-500 leading-8">
                        Congratulations! You’ve made it  to inspection day. You’ve put in an immense amount of effort
                        getting your laboratory up and running. It is not easy to learn all of the requirements a
                        clinical laboratory must meet and discern how your laboratory will meet the high regulatory bar.
                        Use these tips to help you have a seamless inspection day.
                    </p>
                </div>
                <figure className="my-2">
                    <img
                        className="mx-auto w-7/12 rounded-lg"
                        src={process.env.PUBLIC_URL+"/assets/blog/blog6_inspection_tips.png"}
                        alt=""
                    />
                </figure>
                <div className="mt-6 prose prose-blue prose-lg text-gray-500 mx-auto">
                    <ol>
                        <h2>
                            <li>Perform a self-inspection.</li>
                        </h2>
                            <p>
                                Prioritize performing a self-inspection early in your preparation effort. Conducting a
                                self-inspection can help you get a better understanding of where your laboratory
                                currently stands in comparison to what is required. You can eliminate unnecessary
                                effort by documenting which regulatory requirements are already being met by your
                                existing processes and procedures. To ensure your self-inspection simulates a real
                                on-site inspection event, formalize your self-inspection process in advance. Organize a
                                team to perform the inspection that includes a variety of staff levels and determine a
                                date to perform the inspection unannounced.
                            </p>
                        <h2>
                            <li>Know your guidelines.</li>
                        </h2>
                        <p>
                            Ensure you are using the most update version of the guidelines. Whether you are pursing a
                            Certificate of Compliance or a Certificate of Accreditation, it is critical to ensure the
                            version of the inspection checklists your inspectors will be using corresponds to the
                            requirements you have been using to prepare. Take the time to thoroughly read through each
                            requirement and ensure that your laboratory can supply evidence of compliance with each
                            requirement. Do not give in to the desire to skim the regulations.
                        </p>
                        <h2>
                            <li>Prepare your space and your personnel.</li>
                        </h2>
                        <p>
                            Put your best foot forward and ensure that your space and staff are prepared for inspection
                            day. Conduct frequent walkthroughs of your laboratory and make sure the space is organized.
                            Check to ensure procedures manuals are readily available, logs and records are correctly
                            maintained and scan for expired reagents and consumables. Prepare your laboratory personnel
                            by letting them know the expectations you have for them on inspection day. Make sure they
                            are playing an active role in going through requirements and complying with newly
                            established processes. Take special care to help scientists new the profession learn about
                            inspection logistics and what it takes to maintain a compliant laboratory.
                        </p>
                        <h2>
                            <li>Be confident.</li>
                        </h2>
                        <p>
                            During inspection day, it is important to be calm and present to help the day run as
                            smoothly as possible. Make sure inspectors have all the resources they require to make an
                            informed assessment of your laboratory’s performance. During conversations with your
                            inspectors, make sure to answer honestly and accept feedback in a professional manner.
                            The best thing you can do is remain clam, exude confidence and greet your inspection team
                            with a smile. Remind yourself of the extensive preparation you have done and use that
                            knowledge to bolster your confidence.
                        </p>
                        <h2>
                            <li>Know your reasoning.</li>
                        </h2>
                        <p>
                            You may find yourself in a situation where an inspector wants to note a deficiency that you
                            disagree with. You have every right to contest this but your approach can make the
                            difference between being successful or opening yourself up to greater scrutiny.  Do not
                            speak rashly or start intervening early. You may find that your inspector is talking through
                            the issue and will reach the correct conclusion on their own. Make sure you do not contest a
                            potential citation without clear documented evidence of how you are compliant. Gently direct
                            them to the procedure or records that correspond to that requirement and ask if they can
                            clarify the gap between your evidence and true compliance.
                        </p>
                    </ol>
                    <h2>Still have questions on what to expect come inspection day?</h2>
                    <p>
                        The Decoto Labs team is happy to help however we can. Whether you need tips on how to organize
                        your laboratory space, how to prep your personnel or how to tackle your self-inspection &#8212;
                        we want to help.
                    </p>
                    <p>
                        Visit our <a href="https://decotolabs.com">homepage</a>{" "} to schedule time with our founders
                        to get your questions answered or reach out via email to <a href="mailto:info@decotolabs.com">info@decotolabs.com</a>.
                        You can also keep up with our blog posts and resources by following our{" "}
                        <a href="https://www.linkedin.com/company/decoto-labs" target="_blank" rel="noreferrer">LinkedIn</a> page.
                    </p>
                    <h2>Interested in trying our QMS, Regulator?</h2>
                    <p>
                        Our Early Adopter program is still open, but spots are filling up quickly. You can reach us via
                        email at{" "}
                        <a href="mailto:info@decotolabs.com">info@decotolabs.com</a>{" "}
                        with “Early Adopter Program” as the subject line. Still not sure? Visit our{" "}
                        <a href="https://decotolabs.com">homepage</a>{" "}
                        where you can directly schedule time to speak with our founders and get your questions answered.
                    </p>
                </div>
            </div>
        </div>
    )
}
