import {
    ArrowDownIcon,
    CalendarIcon,
    ChevronRightIcon,
    ClipboardListIcon,
    ColorSwatchIcon,
    CursorClickIcon,
    DesktopComputerIcon, PlusSmIcon,
    PresentationChartLineIcon,
    ShieldCheckIcon, VideoCameraIcon,
} from '@heroicons/react/solid'
import Footer from "./common/Footer";
import React, {useState} from "react";
import SuccessFeedback from "./common/SuccessFeedback";
import FailFeedback from "./common/FailFeedback";
import Header from "./common/Header";

global.Buffer = global.Buffer || require("buffer").Buffer;

const CLIENT_EMAIL = process.env.REACT_APP_CLIENT_EMAIL
const PRIVATE_KEY = process.env.REACT_APP_PRIVATE_KEY
const MAILING_SPREADSHEET_ID = "19h0IGaG-eD4y75z0w5VYLlHVKs78kxOAabQQ42kwIIU"
const MAILING_SHEET_INDEX = 0

const { GoogleSpreadsheet } = require("google-spreadsheet")

const mailingList = new GoogleSpreadsheet(MAILING_SPREADSHEET_ID)

const features = [
    {
        name: 'Centralized quality management',
        description: 'Have all of your quality documentation in one unified place for easy management and ' +
            'inspection readiness.',
        icon: ClipboardListIcon,
    },
    {
        name: 'No excessive customization',
        description:
            'Use a system designed by clinical regulatory professionals for the unique needs of clinical laboratory users.',
        icon: ColorSwatchIcon,
    },
    {
        name: 'Real-time validation',
        description:
            'Prevent unnecessary time and effort on avoidable non-conformance and deviations with real time ' +
            'system validation.',
        icon: ShieldCheckIcon,
    },
    {
        name: 'Intuitive UI',
        description:
            'Avoid the outdated and difficult user interfaces of solutions on the market today with a seamless ' +
            'and simple user interface.',
        icon: DesktopComputerIcon,
    },
    {
        name: 'Scale Effortlessly',
        description: 'Never worry about if your quality management system can keep up with you. Process 1 or 1 million ' +
            'samples. Regulator will see you through it all.',
        icon: PresentationChartLineIcon
    },
    {
        name: 'Self-Service',
        description: "Don't spend another dime on expensive implementation specialists and system trainings. Zero to" +
            " QMS in just a few clicks.",
        icon: CursorClickIcon
    }
]

export default function HomePage() {
    const [showSuccessAlert, setShowSuccessAlert] = useState(false)
    const [showFailAlert, setShowFailAlert] = useState(false)

    const appendSpreadsheet = async (row: string[]) => {
        try {
            await mailingList.useServiceAccountAuth({
                client_email: CLIENT_EMAIL,
                private_key: PRIVATE_KEY
            })

            await mailingList.loadInfo()

            const sheet = mailingList.sheetsByIndex[MAILING_SHEET_INDEX]
            await sheet.addRow(row)
            setShowSuccessAlert(true)
        } catch (e) {
            console.error('Error: ', e);
            setShowFailAlert(true)
        }
    }


    function handleCTAEmail(e: React.SyntheticEvent) {
        e.preventDefault()

        const target = e.target as typeof e.target & {
            "cta-email": { value: string }
        }

        const email = target["cta-email"].value

        const newRow = [new Date().toLocaleDateString(), email]

        appendSpreadsheet(newRow).finally(() => {
            target["cta-email"].value = ''
        })

    }
    
    return (
        <div className="bg-white">
            <main>
                {/* Hero section */}
                <div id="hero-section" className="pt-8 overflow-hidden sm:pt-12 lg:relative lg:py-48">
                    <div
                        className="mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl lg:grid lg:grid-cols-2 lg:gap-24">
                        <div>
                            <div>
                                <img
                                    className="h-auto w-auto sm:mt-12 mt-40"
                                    src={process.env.PUBLIC_URL + "/assets/decoto-labs_logo-full_color-b.svg"}
                                    alt="Decoto Labs"
                                />
                            </div>
                            <div className="mt-20">
                                <div className="mt-6 sm:max-w-xl">
                                    <h1 className="text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl">
                                        A tool to manage clinical lab quality,
                                        even if you're not sure what CLIA stands for.
                                    </h1>
                                    <p className="mt-6 text-xl text-gray-500">
                                        Stop trying to force unwieldy, out-of-date systems to work for you. Adopt
                                        Regulator, Decoto Labs' simple solution, built for clinical laboratories by
                                        quality professionals.
                                    </p>
                                </div>
                                <div className="mt-8">
                                    <a href="https://www.youtube.com/watch?v=9sMsfvtfUAc&t=13s"
                                       target="_blank"
                                       rel="noreferrer"
                                       className="inline-flex space-x-4">
                    <span
                        className="rounded bg-blue-50 px-2.5 py-1 text-medium font-semibold text-blue-500 tracking-wide uppercase">
                      Learn more
                    </span>
                                        <span
                                            className="sm:inline-flex items-center text-lg font-bold text-blue-500 space-x-1 focus:text-blue-600 hover:text-blue-600">
                                            <span className="hidden sm:block"><VideoCameraIcon className="h-5 w-5" aria-hidden="true"/></span>
                                            <span className="underline sm:no-underline">View our video tour of Regulator</span>
                                            <ChevronRightIcon className="hidden sm:block h-5 w-5" aria-hidden="true"/>
                                        </span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="sm:mx-auto sm:max-w-3xl sm:px-6">
                        <div
                            className="py-12 sm:relative sm:mt-12 sm:py-16 lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
                            <div className="hidden sm:block">
                                <div
                                    className="absolute inset-y-0 left-1/2 w-screen bg-gray-50 rounded-l-3xl lg:left-80 lg:right-0 lg:w-full"/>
                                <svg
                                    className="absolute top-8 right-1/2 -mr-3 lg:m-0 lg:left-0"
                                    width={404}
                                    height={392}
                                    fill="none"
                                    viewBox="0 0 404 392"
                                >
                                    <defs>
                                        <pattern
                                            id="837c3e70-6c3a-44e6-8854-cc48c737b659"
                                            x={0}
                                            y={0}
                                            width={20}
                                            height={20}
                                            patternUnits="userSpaceOnUse"
                                        >
                                            <rect x={0} y={0} width={4} height={4} className="text-gray-200"
                                                  fill="currentColor"/>
                                        </pattern>
                                    </defs>
                                    <rect width={404} height={392} fill="url(#837c3e70-6c3a-44e6-8854-cc48c737b659)"/>
                                </svg>
                            </div>
                            <div
                                className="relative pl-4 -mr-40 sm:mx-auto sm:max-w-3xl sm:px-0 lg:max-w-none lg:h-full lg:pl-12">
                                <img
                                    className="w-full rounded-md shadow-xl ring-1 ring-black ring-opacity-5 lg:h-full lg:w-auto lg:max-w-none"
                                    src={process.env.PUBLIC_URL + "/assets/home/regulator-app-img-2.png"}
                                    alt=""
                                />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Testimonial/stats section */}
                <div className="relative mt-20">
                    <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:gap-24 lg:items-start">
                        <div className="relative sm:py-16 lg:py-0">
                            <div aria-hidden="true"
                                 className="hidden sm:block lg:absolute lg:inset-y-0 lg:right-0 lg:w-screen">
                                <div
                                    className="absolute inset-y-0 right-1/2 w-full bg-gray-50 rounded-r-3xl lg:right-72"/>
                                <svg
                                    className="absolute top-8 left-1/2 -ml-3 lg:-right-8 lg:left-auto lg:top-12"
                                    width={404}
                                    height={392}
                                    fill="none"
                                    viewBox="0 0 404 392"
                                >
                                    <defs>
                                        <pattern
                                            id="02f20b47-fd69-4224-a62a-4c9de5c763f7"
                                            x={0}
                                            y={0}
                                            width={20}
                                            height={20}
                                            patternUnits="userSpaceOnUse"
                                        >
                                            <rect x={0} y={0} width={4} height={4} className="text-gray-200"
                                                  fill="currentColor"/>
                                        </pattern>
                                    </defs>
                                    <rect width={404} height={392} fill="url(#02f20b47-fd69-4224-a62a-4c9de5c763f7)"/>
                                </svg>
                            </div>
                            <div
                                className="relative mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-0 lg:max-w-none lg:py-20">
                                {/* Testimonial card*/}
                                <div className="relative pt-64 pb-10 rounded-2xl shadow-xl overflow-hidden">
                                    <img
                                        className="absolute inset-0 h-full w-full object-cover"
                                        src={process.env.PUBLIC_URL + '/assets/home/home-lab-image.jpg'}
                                        alt=""
                                    />
                                    <div className="absolute inset-0 bg-blue-500 mix-blend-multiply"/>
                                    <div
                                        className="absolute inset-0 bg-gradient-to-t from-blue-600 via-blue-600 opacity-90"/>
                                    <div className="relative px-8">
                                        <div>
                                            <img
                                                className="h-40 w-auto"
                                                src={process.env.PUBLIC_URL + '/assets/dl_regulator_logo-full_color-b.svg'}
                                                alt="Regulator"
                                            />
                                        </div>
                                        <blockquote className="mt-8">
                                            <div className="relative text-lg font-medium text-white md:flex-grow">
                                                <p className="relative">
                                                    It is time for quality management to <i>keep up</i> with the
                                                    innovation going on in
                                                    clinical laboratories rather than <i>slowing it down</i>.
                                                </p>
                                            </div>
                                            <footer className="mt-4">
                                                <p className="text-base font-semibold text-blue-200">Mujda Alamzai-Oh,
                                                    CEO at Decoto Labs</p>
                                            </footer>
                                        </blockquote>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="relative mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-0">
                            {/* Content area */}
                            <div className="pt-12 sm:pt-16 lg:pt-20">
                                <h2 className="text-3xl text-gray-900 font-extrabold tracking-tight sm:text-4xl">
                                    Let your scientists be innovators instead of paper-pushers.
                                </h2>
                                <div className="mt-6 text-gray-500 space-y-6">
                                    <p className="text-lg">
                                        Decoto Labs is excited to introduce their first product offering: Regulator.
                                        A comprehensive quality management solution designed with only clinical
                                        laboratory needs in mind. Built by a team of experienced regulatory
                                        professionals who have led perfect CLIA inspections and ex-FAANG software
                                        developers, Regulator provides clinical labs with everything they need to make
                                        their lab work better. And nothing they don't.
                                    </p>
                                    <p className="text-base">
                                        Manage your: <br/>
                                        <ul className={"list-disc"}>
                                            <li className="ml-4">samples</li>
                                            <li className="ml-4">reagents</li>
                                            <li className="ml-4">equipment</li>
                                            <li className="ml-4">personnel records</li>
                                            <li className="ml-4">standard operating procedures</li>
                                            <li className="ml-4">and much more</li>
                                        </ul>
                                        <br/><br/><span className="font-semibold text-lg text-gray-900">Be inspection ready everyday.</span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Feature section */}
                <div className="bg-gray-50 overflow-hidden">
                    <div className="relative max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:px-8">
                        <svg
                            className="absolute top-0 left-full transform -translate-x-1/2 -translate-y-3/4 lg:left-auto lg:right-full lg:translate-x-2/3 lg:translate-y-1/4"
                            width={404}
                            height={784}
                            fill="none"
                            viewBox="0 0 404 784"
                            aria-hidden="true"
                        >
                            <defs>
                                <pattern
                                    id="8b1b5f72-e944-4457-af67-0c6d15a99f38"
                                    x={0}
                                    y={0}
                                    width={20}
                                    height={20}
                                    patternUnits="userSpaceOnUse"
                                >
                                    <rect x={0} y={0} width={4} height={4} className="text-gray-200"
                                          fill="currentColor"/>
                                </pattern>
                            </defs>
                            <rect width={404} height={784} fill="url(#8b1b5f72-e944-4457-af67-0c6d15a99f38)"/>
                        </svg>

                        <div className="relative lg:grid lg:grid-cols-3 lg:gap-x-8">
                            <div className="lg:col-span-1">
                                <h2 className="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                                    Make your inspectors swoon.
                                </h2>
                            </div>
                            <dl className="mt-10 space-y-10 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-x-8 sm:gap-y-10 lg:mt-0 lg:col-span-2">
                                {features.map((feature) => (
                                    <div key={feature.name}>
                                        <dt>
                                            <div
                                                className="flex items-center justify-center h-12 w-12 rounded-md bg-blue-500 text-white">
                                                <feature.icon className="h-6 w-6" aria-hidden="true"/>
                                            </div>
                                            <p className="mt-5 text-lg leading-6 font-medium text-gray-900">{feature.name}</p>
                                        </dt>
                                        <dd className="mt-2 text-base text-gray-500">{feature.description}</dd>
                                    </div>
                                ))}
                            </dl>
                        </div>
                    </div>
                </div>
                <h2 className="text-4xl mt-12 font-extrabold text-gray-900 text-center tracking-tight sm:text-5xl">
                    Quality Management is <i>not</i> just policies and procedures.
                </h2>
                <p className="mt-8 px-8 sm:px-28 text-gray-500 font-medium text-xl">
                    Your samples, reagents, equipment, personnel, workflow runs, records and documents are all
                    intricately connected. Regulator is the first QMS to understand this. Your quality management
                    system should keep all of your lab's information in one place and make it easier for you to
                    maintain the highest standards of quality &#8212; not harder.
                </p>
                <div className="bg-white overflow-hidden">
                    <div className="relative max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:px-8">
                        <div className="relative lg:grid lg:grid-cols-3 lg:gap-x-8">
                            <div className="lg:col-span-1">
                                <h2 className="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                                    Inspection Day
                                </h2>
                                <p className="mt-8 font-medium text-gray-500">
                                    Save your team from hours of inspection preparation. Regulator ensures
                                    you are inspection-ready everyday with no additional work.
                                    <br/><br/>
                                    Navigate seamlessly between all of the entities your inspectors will ask about.
                                    Relationships between workflow runs, equipment, reagents, samples, personnel and
                                    standard operating procedures are simple to track and move through.
                                </p>
                            </div>
                            <div className="mt-2 lg:col-span-2 relative rounded-2xl shadow-xl overflow-hidden">
                                <img
                                    src={process.env.PUBLIC_URL + '/assets/home/workflow_run.gif'}
                                    alt=""
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="bg-gray-50 overflow-hidden">
                    <div className="relative max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:px-8">
                        <div className="relative lg:grid lg:grid-cols-3 lg:gap-x-8">
                            <div className="lg:col-span-2 relative rounded-2xl shadow-xl overflow-hidden">
                                <img
                                    src={process.env.PUBLIC_URL + '/assets/home/reagent_recall.gif'}
                                    alt=""
                                />
                            </div>
                            <div className="lg:col-span-1">
                                <h2 className="mt-8 sm:mt-0 text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                                    Reagent Recall
                                </h2>
                                <p className="mt-8 font-medium text-gray-500">
                                    Do you dread receiving a recall notice from your reagent manufacturer? Regulator
                                    automates away the endless cross-referencing usually required in a recall scenario.
                                    <br/><br/>
                                    Use Regulator's built-in filtering to filter reagents based on lot, date received
                                    and much more. Track every run a reagent has been used on with one simple click.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="bg-white overflow-hidden">
                    <div className="relative max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:px-8">
                        <div className="relative lg:grid lg:grid-cols-3 lg:gap-x-8">
                            <div className="lg:col-span-1">
                                <h2 className="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                                    Document Control
                                </h2>
                                <p className="mt-8 font-medium text-gray-500">
                                    Collaboration, approvals and 21 CFR Part 11 compliant e-signatures are all built
                                    into the SOPs module with a clean, streamlined user-interface.
                                    <br/><br/>
                                    Regulator also automatically notifies approvers and assigns document approval
                                    tasks preventing your documents from languishing in review and saving time.
                                </p>
                            </div>
                            <div className="mt-2 lg:col-span-2 relative rounded-2xl shadow-xl overflow-hidden">
                                <img
                                    src={process.env.PUBLIC_URL + '/assets/home/document_control.gif'}
                                    alt=""
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="bg-gray-50 overflow-hidden">
                    <div className="relative max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:px-8">
                        <div className="relative lg:grid lg:grid-cols-3 lg:gap-x-8">
                            <div className="lg:col-span-2 relative rounded-2xl shadow-xl overflow-hidden">
                                <img
                                    src={process.env.PUBLIC_URL + '/assets/home/competency.gif'}
                                    alt=""
                                />
                            </div>
                            <div className="lg:col-span-1">
                                <h2 className="mt-8 sm:mt-0 text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                                    Training and Competency
                                </h2>
                                <p className="mt-8 font-medium text-gray-500">
                                    Competency timings are complicated. Other systems force you to configure the renewal
                                    cadence and often results in missing dates and additional non-conformance effort.
                                    Regulator builds in reminders and notifications so you never have to worry.
                                    <br/><br/>
                                    No matter your competency assessment process, Regulator's Training and Competency
                                    module is flexible enough to accommodate and keep track of every associated record
                                    and document.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <h2 className="text-4xl mt-12 font-extrabold text-gray-900 text-center tracking-tight sm:text-5xl">
                    But that's not all. Want to see everything Regulator can do?
                </h2>
                <p className="mt-8 px-8 sm:px-28 text-gray-500 font-medium text-xl text-center">
                    Schedule a demo with the Decoto Labs founders to learn more about how Regulator can help in your
                    specific use case.
                    <div>
                        <a href="https://savvycal.com/decoto-labs/regulator-demo"
                           target="_blank"
                           rel="noreferrer"
                           className="inline-flex space-x-4">
                            <div
                            className="sm:inline-flex items-center text-lg font-bold text-blue-500 space-x-1 focus:text-blue-600 hover:text-blue-600">
                                            <span className="hidden sm:block"><CalendarIcon className="h-5 w-5" aria-hidden="true"/></span>
                                            <span className="underline sm:no-underline">Schedule a demo</span>
                                            <ChevronRightIcon className="hidden sm:block h-5 w-5" aria-hidden="true"/>
                            </div>
                        </a>
                    </div>
                </p>
                {/* CTA section */}
                <div className="relative mt-24 sm:mt-32 sm:py-16">
                    <div aria-hidden="true" className="hidden sm:block">
                        <div className="absolute inset-y-0 left-0 w-1/2 bg-gray-50 rounded-r-3xl"/>
                        <svg className="absolute top-8 left-1/2 -ml-3" width={404} height={392} fill="none"
                             viewBox="0 0 404 392">
                            <defs>
                                <pattern
                                    id="8228f071-bcee-4ec8-905a-2a059a2cc4fb"
                                    x={0}
                                    y={0}
                                    width={20}
                                    height={20}
                                    patternUnits="userSpaceOnUse"
                                >
                                    <rect x={0} y={0} width={4} height={4} className="text-gray-200"
                                          fill="currentColor"/>
                                </pattern>
                            </defs>
                            <rect width={404} height={392} fill="url(#8228f071-bcee-4ec8-905a-2a059a2cc4fb)"/>
                        </svg>
                    </div>
                    <div className="mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:max-w-7xl lg:px-8">
                        <div
                            className="relative rounded-2xl px-6 py-10 bg-blue-500 overflow-hidden shadow-xl sm:px-12 sm:py-20">
                            <div aria-hidden="true" className="absolute inset-0 -mt-72 sm:-mt-32 md:mt-0">
                                <svg
                                    className="absolute inset-0 h-full w-full"
                                    preserveAspectRatio="xMidYMid slice"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 1463 360"
                                >
                                    <path
                                        className="text-blue-400 text-opacity-40"
                                        fill="currentColor"
                                        d="M-82.673 72l1761.849 472.086-134.327 501.315-1761.85-472.086z"
                                    />
                                    <path
                                        className="text-blue-600 text-opacity-40"
                                        fill="currentColor"
                                        d="M-217.088 544.086L1544.761 72l134.327 501.316-1761.849 472.086z"
                                    />
                                </svg>
                            </div>
                            <div className="relative">
                                <div className="sm:text-center">
                                    <h2 className="text-3xl font-extrabold text-white tracking-tight sm:text-4xl">
                                        Make Decoto Labs your own quality expert.
                                    </h2>
                                    <p className="mt-6 mx-auto max-w-2xl text-lg text-blue-100">
                                        Sign up for our mailing list and supercharge your quality management team.
                                    </p>
                                </div>
                                <form
                                    onSubmit={handleCTAEmail}
                                    className="mt-12 sm:mx-auto sm:max-w-lg sm:flex">
                                    <div className="min-w-0 flex-1">
                                        <label htmlFor="cta-email" className="sr-only">
                                            Email address
                                        </label>
                                        <input
                                            id="cta-email"
                                            type="email"
                                            className="block w-full border border-transparent rounded-md px-5 py-3 text-base text-gray-900 placeholder-gray-500 shadow-sm focus:outline-none focus:border-transparent focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-blue-500"
                                            placeholder="Enter your email"
                                        />
                                    </div>
                                    <div className="mt-4 sm:mt-0 sm:ml-3">
                                        <button
                                            type="submit"
                                            className="block w-full rounded-md border border-transparent px-5 py-3 bg-gray-900 text-base font-medium text-white shadow hover:bg-black focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-blue-500 sm:px-10"
                                        >
                                            Sign me up!
                                        </button>
                                    </div>
                                </form>
                                <div className="mt-2 z-50 animate-fade-in-down" hidden={!showSuccessAlert}>
                                    <SuccessFeedback message={"Successfully added to mailing list!"} show={setShowSuccessAlert}/>
                                </div>
                                <div id="fail" className="mt-2 z-50 animate-fade-in-down" hidden={!showFailAlert}>
                                    <FailFeedback message={"Sorry! We couldn't save your email at this time. Please try again later!"} show={setShowFailAlert}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>

            {/* Footer section */}
            <Footer/>
        </div>
    )
}

