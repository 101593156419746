import {ArrowLeftIcon} from "@heroicons/react/solid";
import React from "react";
import {Link} from "react-router-dom";

export default function BlogPost12() {

    return (
        <div className="relative py-16 bg-white overflow-hidden">
            <div className="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
                <div className="relative h-full text-lg max-w-prose mx-auto" aria-hidden="true">
                    <svg
                        className="absolute top-12 left-full transform translate-x-32"
                        width={404}
                        height={384}
                        fill="none"
                        viewBox="0 0 404 384"
                    >
                        <defs>
                            <pattern
                                id="74b3fd99-0a6f-4271-bef2-e80eeafdf357"
                                x={0}
                                y={0}
                                width={20}
                                height={20}
                                patternUnits="userSpaceOnUse"
                            >
                                <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor"/>
                            </pattern>
                        </defs>
                        <rect width={404} height={384} fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)"/>
                    </svg>
                    <svg
                        className="absolute top-1/2 right-full transform -translate-y-1/2 -translate-x-32"
                        width={404}
                        height={384}
                        fill="none"
                        viewBox="0 0 404 384"
                    >
                        <defs>
                            <pattern
                                id="f210dbf6-a58d-4871-961e-36d5016a0f49"
                                x={0}
                                y={0}
                                width={20}
                                height={20}
                                patternUnits="userSpaceOnUse"
                            >
                                <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor"/>
                            </pattern>
                        </defs>
                        <rect width={404} height={384} fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)"/>
                    </svg>
                    <svg
                        className="absolute bottom-12 left-full transform translate-x-32"
                        width={404}
                        height={384}
                        fill="none"
                        viewBox="0 0 404 384"
                    >
                        <defs>
                            <pattern
                                id="d3eb07ae-5182-43e6-857d-35c643af9034"
                                x={0}
                                y={0}
                                width={20}
                                height={20}
                                patternUnits="userSpaceOnUse"
                            >
                                <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor"/>
                            </pattern>
                        </defs>
                        <rect width={404} height={384} fill="url(#d3eb07ae-5182-43e6-857d-35c643af9034)"/>
                    </svg>
                </div>
            </div>
            <div className="mt-4 relative px-4 sm:px-6 lg:px-8">
                <div className="text-lg max-w-prose mx-auto">
                    <Link to="/blog"
                          className="inline-flex space-x-4">
                        <span
                            className="inline-flex items-center text-sm font-medium text-blue-500 space-x-1">
                      <ArrowLeftIcon className="h-5 w-5" aria-hidden="true"/>
                            <span>Back to Blog</span>
                    </span>
                    </Link>
                    <span className="block text-base text-center text-blue-600 font-semibold tracking-wide uppercase">
                        Article
                    </span>
                    <h1>
                        <span
                            className="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              Build a Competency Assessment Program You Can Actually Keep Up With
            </span>
                    </h1>
                    <p className="mt-8 text-xl text-gray-500 leading-8">
                        The Center for Medicare and Medicaid Services and accreditation organizations are pretty clear
                        on the procedures required to meet minimal regulatory requirements. Translating those procedures
                        into an efficient program that your laboratory can keep up with is less obvious. Read on for
                        Decoto Labs’ tips and tricks for dealing with competency assessments.
                    </p>
                </div>
                <figure className="my-2">
                    <img
                        className="mx-auto w-7/12 rounded-lg"
                        src={process.env.PUBLIC_URL+"/assets/blog/blog12_competency_assessment_tips.png"}
                        alt=""
                    />
                </figure>
                <div className="mt-6 prose prose-blue prose-lg text-gray-500 mx-auto">
                    <h2>Testing Personnel vs. Other Roles</h2>
                    <p>
                        While competency assessments are necessary for a variety of roles in the laboratory this post
                        will focus specifically on the assessment of personnel performing laboratory testing. If you are
                        interested in learning more about establishing competency assessments for other personnel, feel
                        free to reach out and schedule a chat with our founders.
                    </p>
                    <h2>The Requirements</h2>
                    <ol>
                        <li>
                            Direct observations of routine patient test performance, including patient preparation,
                            if applicable, specimen handling, processing and testing
                        </li>
                        <li>
                            Monitoring the recording and reporting of test results
                        </li>
                        <li>
                            Review of intermediate test results or worksheets, quality control records, proficiency
                            testing results, and preventative maintenance records
                        </li>
                        <li>
                            Direct observations of performance of instrument maintenance and function checks
                        </li>
                        <li>
                            Assessment of test performance through testing previously analyzed specimens, internal blind
                            testing samples or external proficiency testing samples
                        </li>
                        <li>
                            Assessment of problem solving skills
                        </li>
                    </ol>
                    <h2>Our Tips and Tricks</h2>
                    <h3>Schedule monthly competency days</h3>
                    <p>
                        Instead of scrambling to make time for assessing competency as your personnel’s renewal date
                        approaches, institute a couple of standing monthly competency days for each test your laboratory
                        does.  Having at least two days set aside can help ensure that you have enough staff available
                        to keep up with production testing volume. Testing personnel whose competency will lapse before
                        the next competency day should ensure they complete a competency run on one of those days.
                    </p>
                    <h3>Create a buddy system</h3>
                    <p>
                        When onboarding new testing personnel, assign a technical consultant, technical supervisor or
                        personnel meeting the regulatory qualification requirements who has been delegated the
                        responsibility of assessing competency. Grouping your testing personnel into clear cohorts with
                        a specified leader responsible for their competency eliminates ambiguity and streamlines the
                        process of scheduling an assessment. In addition, sharing the responsibility of staying on top
                        of assessments between the testing personnel and assessor makes it less likely for assessments
                        to be missed.
                    </p>
                    <h3>Keep Problem Solving Skills Assessments Simple</h3>
                    <p>
                        While the other five competency assessment requirements are pretty clear, the “assessment of
                        problem solving skills” can be frustratingly vague. It can be tempting to go overboard and
                        implement full courses which can be costly and time-consuming to both create and grade. Problem
                        solving skill assessments can be as simple as having the assessor pose a hypothetical problem to
                        the testing personnel and assess their response. This in addition to assessing their skills with
                        any problems that naturally arise during observation is not only sufficient, but more efficient
                        and a better indicator of how testing personnel will perform in a production setting.
                    </p>
                    <h3>Make Assessments as Self-Directed and Asynchronous as Possible</h3>
                    <p>
                        Competency assessments can be made more efficient by optimizing the process to maximize
                        self-direction and asynchronous work. Have testing personnel do review of results and monitoring
                        of recording and reporting in a self-directed manner. Allow assessors to document their
                        assessments of test performance and problem-solving skills in an asynchronous manner. Taking
                        these steps can help your competency assessment program use time and resources more efficiently.
                    </p>
                    <h2>Regulator Early Adopters Get Free Consulting Hours!</h2>
                    <p>
                        Regulator, Decoto Labs’ quality management system, has many of the regulatory requirements for
                        clinical laboratories built right in. Competency assessment renewal tasks are automated and
                        each workflow run record links to the competency record of the analyst that performed the run.
                        But if you are still nervous about meeting your competency assessment program requirements,
                        Decoto Labs provides free consulting hours for Early Adopters.
                    </p>
                    <p>
                        Visit our <a href="https://decotolabs.com">homepage</a>{" "} to schedule time with our founders
                        to get your questions answered or reach out via email to <a href="mailto:info@decotolabs.com">info@decotolabs.com</a>.
                        You can also keep up with our blog posts and resources by following our{" "}
                        <a href="https://www.linkedin.com/company/decoto-labs" target="_blank" rel="noreferrer">LinkedIn</a> page.
                    </p>
                    <h2>Interested in trying our QMS, Regulator?</h2>
                    <p>
                        Our Early Adopter program is still open, but spots are filling up quickly. You can reach us via
                        email at{" "}
                        <a href="mailto:info@decotolabs.com">info@decotolabs.com</a>{" "}
                        with “Early Adopter Program” as the subject line. Still not sure? Visit our{" "}
                        <a href="https://decotolabs.com">homepage</a>{" "}
                        where you can directly schedule time to speak with our founders and get your questions answered.
                    </p>
                </div>
            </div>
        </div>
    )
}
