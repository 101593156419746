import {ArrowLeftIcon} from "@heroicons/react/solid";
import React from "react";
import {Link} from "react-router-dom";

export default function BlogPost3() {

    return (
        <div className="relative py-16 bg-white overflow-hidden">
            <div className="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
                <div className="relative h-full text-lg max-w-prose mx-auto" aria-hidden="true">
                    <svg
                        className="absolute top-12 left-full transform translate-x-32"
                        width={404}
                        height={384}
                        fill="none"
                        viewBox="0 0 404 384"
                    >
                        <defs>
                            <pattern
                                id="74b3fd99-0a6f-4271-bef2-e80eeafdf357"
                                x={0}
                                y={0}
                                width={20}
                                height={20}
                                patternUnits="userSpaceOnUse"
                            >
                                <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor"/>
                            </pattern>
                        </defs>
                        <rect width={404} height={384} fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)"/>
                    </svg>
                    <svg
                        className="absolute top-1/2 right-full transform -translate-y-1/2 -translate-x-32"
                        width={404}
                        height={384}
                        fill="none"
                        viewBox="0 0 404 384"
                    >
                        <defs>
                            <pattern
                                id="f210dbf6-a58d-4871-961e-36d5016a0f49"
                                x={0}
                                y={0}
                                width={20}
                                height={20}
                                patternUnits="userSpaceOnUse"
                            >
                                <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor"/>
                            </pattern>
                        </defs>
                        <rect width={404} height={384} fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)"/>
                    </svg>
                    <svg
                        className="absolute bottom-12 left-full transform translate-x-32"
                        width={404}
                        height={384}
                        fill="none"
                        viewBox="0 0 404 384"
                    >
                        <defs>
                            <pattern
                                id="d3eb07ae-5182-43e6-857d-35c643af9034"
                                x={0}
                                y={0}
                                width={20}
                                height={20}
                                patternUnits="userSpaceOnUse"
                            >
                                <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor"/>
                            </pattern>
                        </defs>
                        <rect width={404} height={384} fill="url(#d3eb07ae-5182-43e6-857d-35c643af9034)"/>
                    </svg>
                </div>
            </div>
            <div className="mt-4 relative px-4 sm:px-6 lg:px-8">
                <div className="text-lg max-w-prose mx-auto">
                    <Link to="/blog"
                          className="inline-flex space-x-4">
                        <span
                            className="inline-flex items-center text-sm font-medium text-blue-500 space-x-1">
                      <ArrowLeftIcon className="h-5 w-5" aria-hidden="true"/>
                            <span>Back to Blog</span>
                    </span>
                    </Link>
                    <span className="block text-base text-center text-blue-600 font-semibold tracking-wide uppercase">
                        Article
                    </span>
                    <h1>
                        <span
                            className="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              Looking for a MediaLab alternative?
            </span>
                    </h1>
                    <p className="mt-8 text-xl text-gray-500 leading-8">
                        Veterans of the clinical lab industry will tell you MediaLab is the go-to quality management
                        solution. But is it the best way just because it is the way things have always been done?
                        Read on to learn about what both Regulator and MediaLab bring to the table and which tool is
                        best for your laboratory's needs.
                    </p>
                </div>
                <figure className="my-2">
                    <img
                        className="mx-auto w-7/12 rounded-lg"
                        src={process.env.PUBLIC_URL+"/assets/blog/blog3_medialab_alternative_img.png"}
                        alt=""
                    />
                </figure>
                <div className="mt-6 prose prose-blue prose-lg text-gray-500 mx-auto">
                    <h2>What MediaLab and Regulator Have in Common</h2>
                    <p>
                        Enterprise quality management systems, which were not designed for clinical lab use, are
                        typically more expensive and time-consuming for laboratories to onboard. For labs feeling the
                        pain of trying to maintain a paper based quality management system as their sample throughput
                        grows, that additional time and customization cost for non-industry specific systems is usually
                        too much to bear. MediaLab and Regulator are the only quality management systems available that
                        were designed specifically for use in clinical laboratories.
                    </p>
                    <p>
                        In addition, both MediaLab and Regulator offer a variety of modules to support quality
                        management activities beyond just document control.
                    </p>
                    <div className="-mt-8 sm:grid sm:grid-cols-2">
                        <div>
                            <h3 className="font-bold">MediaLab</h3>
                            <ul className="list-disc">
                                <li>Document Control</li>
                                <li>InspectionProof</li>
                                <li>Compliance & CE (Histology CE)</li>
                                <li>Mandatory Annual HR Courses</li>
                                <li>POCT Compass</li>
                                <li>Personnel Documentation</li>
                                <li>IQE</li>
                                <li>Exam & Case Simulators</li>
                            </ul>
                        </div>
                        <div>
                            <h3 className="font-bold">Regulator</h3>
                            <ul className="list-disc">
                                <li>Standard Operating Procedures</li>
                                <li>Reagents</li>
                                <li>Equipment</li>
                                <li>Samples</li>
                                <li>Workflow Templates & History</li>
                                <li>Training & Competency</li>
                                <li>Personnel</li>
                                <li>Forms & Records</li>
                                <li>Tasks</li>
                            </ul>
                        </div>
                    </div>
                    <figure className="my-2">
                        <img
                            className="mx-auto rounded-lg"
                            src={process.env.PUBLIC_URL+"/assets/blog/blog3_comparison_img.png"}
                            alt=""
                        />
                    </figure>
                    <h2>Where MediaLab Wins</h2>
                    <h3>Complex document approval workflows</h3>
                    <p>
                        MediaLab’s Document Control module allows laboratories to configure more complex workflows.
                        You can configure your documents to move conditionally through approvers and tier approvers
                        based on the order in which you require approvals to be obtained.
                        For instance, you could configure a workflow that requires Quality to sign off first, then the
                        Clinical Laboratory team and finally the Laboratory Director.
                    </p>
                    <h3>Conditional and staged forms</h3>
                    <p>
                        MediaLab’s IQE module allows laboratories to create complex forms with conditional and staged
                        behavior. Let’s use the example of a form for managing installation qualification of a piece of
                        equipment. If a piece of equipment fails a specific installation qualification check, you can
                        have a conditional field for a technician to provide explanation that is only displayed in case
                        of failure or even prevent the form from moving forward in the approval process.
                    </p>
                    <h3>Native Self-Inspection Support</h3>
                    <p>
                        InspectionProof, MediaLab’s self-inspection module, allows laboratories to unify their self
                        inspection efforts in one place. Labs can map their documentation, processes and forms to CAP
                        requirements and generate self inspection reports natively in the tool.
                    </p>
                    <h3>Comprehensive Training Capabilities</h3>
                    <p>
                        MediaLab’s various modules for continuing education, case simulators, and competency management
                        allow laboratories to develop sophisticated courses to manage these requirements. Courses can
                        include quizzes, assessments, varied content and approval workflows similar to their
                        Document Control module.
                    </p>
                    <h2>Where Regulator Wins</h2>
                    <h3>Centralized management of all quality management records</h3>
                    <p>
                        Regulator allows all of your quality management records to be stored and accessible in one
                        centralized place, not just standard operating procedures. No need for binders! All of those
                        miscellaneous pieces of documentation like MSDS for reagents and vendor preventative maintenance
                        reports for equipment have a place within Regulator that makes it easy to reconcile with
                        everything else going on in your laboratory.
                    </p>
                    <h3>Integration and communication between various entities</h3>
                    <p>
                        Quality management reviews, root cause analysis and inspections have never been easier than
                        with Regulator. Noticing mysterious run failures and need to do a root cause analysis? Is an
                        inspector demanding you to produce the reagent, equipment and competency records for a given
                        workflow run in the next few minutes? Regulator’s integration across modules gets you the
                        information you need in a few easy clicks.
                    </p>
                    <h3>Internal validation to prevent avoidable non-conformances</h3>
                    <p>
                        Regulator is built for scientists to focus on the science by stripping away
                        the responsibility of repetitive and time-consuming regulatory checks. A workflow run cannot
                        even be created unless the technician is deemed competent, the reagents are qualified and
                        not-expired and the equipment is within calibration. Gone are the days of extensive
                        non-conformance and corrective action efforts for a simple miss that any human could make.
                    </p>
                    <h3>Better user permission management</h3>
                    <p>
                        Regulator makes it simple to manage permissions for each user and streamline laboratory quality
                        management efforts. The Regulator Admin dashboard allows admin users to instantly provide and
                        revoke read and write permissions on any module. For example, if you have a laboratory associate
                        whose only responsibility is equipment maintenance, you can seamlessly provide them access to
                        only the equipment module. Standard Operating Procedure draft reviews are only visible to the
                        users marked as approvers and the draft author providing users with a streamlined task inbox
                        that only shows them tasks they are responsible for.
                    </p>
                    <h3>Simple filtering</h3>
                    <p>
                        Every Regulator module provides a simple filtering feature to allow users to navigate through
                        their quality management records seamlessly. For instance, reagents and can be filtered by lot,
                        equipment can be filtered by calibration date, in addition to many more useful filtering
                        criteria. Putting together metrics and reports for quality management reviews is a now a simple
                        minutes long process instead of an arduous effort.
                    </p>
                    <h3>Self-service setup flow</h3>
                    <p>
                        No quotes. No demos. No training. In a few simple steps, you can have Regulator up and running
                        for your laboratory completely independently. With our 30 day money back guarantee, you can test
                        out Regulator in your laboratory at no risk before many of the other providers even get back to
                        you with a quote.
                    </p>
                    <h2>Interested in trying Regulator?</h2>
                    <p>
                        Our Early Adopter program is still open! You can reach us via email at{" "}
                        <a href="mailto:info@decotolabs.com">info@decotolabs.com</a>{" "}
                        with “Early Adopter Program” as the subject line. Still not sure? Visit our{" "}
                        <a href="https://decotolabs.com">website</a>{" "}
                        where you can directly schedule time to speak with our founders and get your questions answered.
                    </p>
                </div>
            </div>
        </div>
    )
}
