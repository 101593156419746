import {ArrowLeftIcon} from "@heroicons/react/solid";
import React from "react";
import {Link} from "react-router-dom";

export default function BlogPost1() {

    return (
        <div className="relative py-16 bg-white overflow-hidden">
            <div className="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
                <div className="relative h-full text-lg max-w-prose mx-auto" aria-hidden="true">
                    <svg
                        className="absolute top-12 left-full transform translate-x-32"
                        width={404}
                        height={384}
                        fill="none"
                        viewBox="0 0 404 384"
                    >
                        <defs>
                            <pattern
                                id="74b3fd99-0a6f-4271-bef2-e80eeafdf357"
                                x={0}
                                y={0}
                                width={20}
                                height={20}
                                patternUnits="userSpaceOnUse"
                            >
                                <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor"/>
                            </pattern>
                        </defs>
                        <rect width={404} height={384} fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)"/>
                    </svg>
                    <svg
                        className="absolute top-1/2 right-full transform -translate-y-1/2 -translate-x-32"
                        width={404}
                        height={384}
                        fill="none"
                        viewBox="0 0 404 384"
                    >
                        <defs>
                            <pattern
                                id="f210dbf6-a58d-4871-961e-36d5016a0f49"
                                x={0}
                                y={0}
                                width={20}
                                height={20}
                                patternUnits="userSpaceOnUse"
                            >
                                <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor"/>
                            </pattern>
                        </defs>
                        <rect width={404} height={384} fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)"/>
                    </svg>
                    <svg
                        className="absolute bottom-12 left-full transform translate-x-32"
                        width={404}
                        height={384}
                        fill="none"
                        viewBox="0 0 404 384"
                    >
                        <defs>
                            <pattern
                                id="d3eb07ae-5182-43e6-857d-35c643af9034"
                                x={0}
                                y={0}
                                width={20}
                                height={20}
                                patternUnits="userSpaceOnUse"
                            >
                                <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor"/>
                            </pattern>
                        </defs>
                        <rect width={404} height={384} fill="url(#d3eb07ae-5182-43e6-857d-35c643af9034)"/>
                    </svg>
                </div>
            </div>
            <div className="mt-4 relative px-4 sm:px-6 lg:px-8">
                <div className="text-lg max-w-prose mx-auto">
                    <Link to="/blog"
                          className="inline-flex space-x-4">
                        <span
                            className="inline-flex items-center text-sm font-medium text-blue-500 space-x-1">
                      <ArrowLeftIcon className="h-5 w-5" aria-hidden="true"/>
                            <span>Back to Blog</span>
                    </span>
                    </Link>
                    <span className="block text-base text-center text-blue-600 font-semibold tracking-wide uppercase">
                        Article
                    </span>
                    <h1>
                        <span
                            className="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              The 10 most prevalent CLIA deficiencies and how to avoid them.
            </span>
                    </h1>
                    <p className="mt-8 text-xl text-gray-500 leading-8">
                        Inspectors will be keeping a keen eye out for these common mistakes. Ensure your laboratory
                        passes with flying colors. Learn about how Decoto Labs' quality management tool, Regulator, can
                        make your lab work better.
                    </p>
                </div>
                <div className="mt-6 prose prose-blue prose-lg text-gray-500 mx-auto">
                    <h2>10. Labs are not enrolled in approved proficiency testing programs.</h2>
                    <p>
                        Proficiency testing refers to the process of clinical laboratories performing testing with
                        well-characterized specimens and comparing their results with what is expected.
                        In order to be confident that patient samples are being processed safely and catch undetected
                        issues with testing procedures, clinical laboratories are required to participate in proficiency
                        testing twice annually.
                    </p>
                    <h3 className="italic">How to avoid it</h3>
                    <p>
                        The Centers for Medicare and Medicaid Services provides a&nbsp;
                        <a href="https://www.cms.gov/Regulations-and-Guidance/Legislation/CLIA/Proficiency_Testing_Providers"
                           target="_blank"
                           rel="noreferrer"
                        >
                            list
                        </a>
                        &nbsp;of approved proficiency testing
                        programs. Enrolling in one of these is the most straightforward way ensure your lab is compliant
                        with this requirement. Make sure to maintain a calendar with the dates proficiency testing are
                        performed and set reminders for the next time PT will be due.
                    </p>
                    <h2>9. Labs are not including all of the required information on test reports.</h2>
                    <p>
                        Test reports must include information for positive patient identification and provide the name
                        and address of the laboratory location where the test was performed.
                    </p>
                    <h3 className="italic">How to avoid it</h3>
                    <p>
                        Create a report template for your laboratory that includes the name and address of your
                        laboratory. You can meet the patient identification requirement by providing either (1) the
                        patient’s name and identification number or (2) a unique patient identifier and identification
                        number. Implementing a secondary review process with a corresponding checklist of items to
                        confirm is an excellent way to ensure no reports slip by.
                    </p>
                    <h2>8. Labs are using expired reagents.</h2>
                    <p>
                        The CLIA regulations are clear that reagents, solutions, culture media, control materials,
                        calibration materials, and other supplies should not be used when they have exceeded their
                        expiration date. However, many labs are responsible for tracking a large inventory of reagents
                        to support their day-to-day testing activities. Staying on top of reagent quality is easier
                        said than done.
                    </p>
                    <h3 className="italic">How to avoid it</h3>
                    <p>
                        In theory, laboratories could perform weekly reagent audits to find and dispose of expired and
                        degraded reagents. However, having resources and bandwidth to support this can be tough.
                        Adopting Regulator automates this problem away. Regulator provide real-time validation making
                        it impossible for expired reagents to be accidentally used. In addition, detailed traceability
                        makes it trivial to find all runs associated with a particular reagent.
                    </p>
                    <h2>7. Labs are not keeping up with equipment maintenance.</h2>
                    <p>
                        Similar to the reagent conundrum, labs usually have a wide assortment of equipment from a
                        variety of manufacturers. Prescribed maintenance routines can be difficult to stay ahead of,
                        especially when considering how repairs, upgrades and replacements can affect tracking.
                    </p>
                    <h3 className="italic">How to avoid it</h3>
                    <p>
                        Maintaining a calendar with equipment receipt and required maintenance dates can help with
                        preventing missed up keep. Regulator, provides automated notifications when equipment maintenance
                        is due and prevents out-of-specification instruments from being used for patient testing.
                        Regulator's form builder functionality can also be leveraged to track equipment maintenance
                        efforts among other laboratory activities.
                    </p>
                    <h2>6. Labs don’t have their procedure manual readily available.</h2>
                    <p>
                        Even though laboratory personnel become seasoned experts performing the same procedures on a
                        daily basis, ensuring the laboratory procedure manual is readily available is one of the
                        simplest ways to ensure quality management. In fast-paced laboratories making constant
                        improvements, procedures can change rapidly. Making sure hard copies in binders are always
                        up-to-date often times slip between the cracks.
                    </p>
                    <h3 className="italic">How to avoid it</h3>
                    <p>
                        As part of the procedure approval process, make sure all procedure manuals are updated with the
                        newest version. A document control system can be helpful to manage approvals, keep an archive
                        and centralize all active policies and procedures. Regulator’s standard operating procedure
                        module makes it simple to use the most up-to-date procedure by linking to workflow templates.
                        When personnel commence a test run, they will be provided the current procedure automatically.
                    </p>
                    <figure>
                        <img
                            className="w-full rounded-lg"
                            src={process.env.PUBLIC_URL+"/assets/blog/blog1_clia_def_img.png"}
                            alt=""
                            width={1310}
                            height={873}
                        />
                    </figure>
                    <h2>5.  Labs don’t have documented requirements for specimen acceptability.</h2>
                    <p>
                        Accepting samples is usually the first activity in the morning in the laboratory. It can seem
                        unnecessary to document acceptability requirements for something so obvious. However,
                        leaving acceptability up to interpretation can lead to unpredictable test results and even
                        endanger patient safety.
                    </p>
                    <h3 className="italic">How to avoid it</h3>
                    <p>
                        Accounting for every possible state a sample may arrive in is obviously impossible. However,
                        making a best effort to document the obvious criteria can go a long way with satisfying
                        auditors. Scenarios to begin with can include broken/compromised containers, depleted slides
                        and/or blocks, and obviously contaminated specimens. Remember to update these requirements as
                        new criteria become known.
                    </p>
                    <h2>4.  Labs are not verifying the performance of unmodified FDA cleared and approved test systems</h2>
                    <p>
                        A common misconception is that use of FDA cleared and approved systems can be adopted by
                        clinical laboratories without any form of validation. While the requirements for these systems
                        are less stringent than the requirements in place for laboratory developed systems, some form
                        of verification is still required.
                    </p>
                    <h3 className="italic">How to avoid it</h3>
                    <p>
                        Laboratories can adopt a lightweight version of their validation process in these cases. A small
                        number of well-characterized specimens run in accordance with manufacturers specifications can
                        be enough to ensure compliance in this area. CMS provides more details on how to accomplish this
                        &nbsp;
                        <a href="https://www.cms.gov/regulations-and-guidance/legislation/clia/downloads/6064bk.pdf"
                           target="_blank"
                           rel="noreferrer"
                        >here
                       </a>.
                    </p>
                    <h2>3.  Labs are not verifying test accuracy at least twice annually.</h2>
                    <p>
                        Per #10 on this list, many labs are already struggling with keeping up with proficiency testing.
                        However, it is also easy for labs to assume that they are exempt from verifying performance if
                        an approved proficiency testing program is not available for their test menu.
                    </p>
                    <h3 className="italic">How to avoid it</h3>
                    <p>
                        You can develop your own internal proficiency testing program that fits your use case better.
                        Be creative with using previously tested patient samples, quality control materials with known
                        values or commercially available calibrators. Accuracy is most important to gauge, but taking
                        this time to verify precision and reportable range can make your audits much simpler by
                        providing all this information in one report.
                    </p>
                    <h2>2.  Labs are not defining the storage conditions for reagent and specimens.</h2>
                    <p>
                        Reagents and specimen preservation methods usually have clear storage conditions provided by
                        the manufacturer. Unfortunately, this usually means that laboratories have to have a selection
                        of refrigerators and freezers, even liquid nitrogen tanks, to accommodate the different needs.
                    </p>
                    <h3 className="italic">How to avoid it</h3>
                    <p>
                        Create a master storage condition document that contains tables organized by both storage
                        condition and reagent type to make quick lookups easier for laboratory personnel. You can even
                        take this a step further by assigning a color coding scheme for each storage condition and
                        affix corresponding stickers to reagents and specimens upon receipt to make organization more
                        intuitive.
                    </p>
                    <h2>1. Labs are not establishing policies and procedures for assessing employees.</h2>
                    <p>
                        It is obvious how laboratories with an expansive test menu and a multitude of personnel could
                        struggle with developing a procedures for assessing personnel competency in a way that doesn’t
                        impact workload. While there are clear regulatory requirements for assessment, how to implement
                        a program that fits those requirements is more subjective.
                    </p>
                    <h3 className="italic">How to avoid it</h3>
                    <p>
                        CMS provides general information on how to assess personnel competency&nbsp;
                        <a href="https://www.cms.gov/regulations-and-guidance/legislation/clia/downloads/clia_compbrochure_508.pdf"
                           target="_blank"
                           rel="noreferrer"
                        >
                            here
                        </a>
                        . The key to maintaining compliance is to schedule competency activities regularly to be prepared
                        for competency assessments in advance of their expiration. Regulator provides automated
                        competency notifications 30 days in advance of expiration providing laboratories
                        with plenty of advance notice to meet all regulatory requirements.
                    </p>
                </div>
            </div>
        </div>
    )
}
