import React from "react";
import { Link } from "react-router-dom";
import Footer from "./common/Footer";

export default function PrivacyPolicy() {
    return (
        <div>
            <span id="top"/>
            <div className="relative py-16 bg-white overflow-hidden rounded-lg">
                <div className="relative px-4 sm:px-6 lg:px-8">
                    <div className="text-lg max-w-prose mx-auto">
                        <h1>
              <span className="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                Privacy Policy
              </span>
                        </h1>
                    </div>
                    <div className="mt-6 prose prose-blue prose-lg text-gray-500 mx-auto">
                        <p>
                            Your privacy is critically important to us. At Decoto Labs, we
                            have a few fundamental principles:
                            <ul>
                                <li>
                                    We are thoughtful about the personal information we ask you to
                                    provide and the personal information that we collect about you
                                    through the operation of our services.
                                </li>
                                <li>
                                    We store personal information for only as long as we have a
                                    reason to keep it.
                                </li>
                                <li>
                                    We help protect you from overreaching government demands for
                                    your personal information.
                                </li>
                                <li>
                                    We aim for full transparency on how we gather, use, and share
                                    your personal information.
                                </li>
                            </ul>{" "}
                            Below is our Privacy Policy, which incorporates and clarifies
                            these principles.
                            <h3>Who We Are and What This Policy Covers</h3> Howdy! We are the
                            folks behind a variety of products and services designed to allow
                            clinical laboratories to take full advantage of the power and
                            promise of software in running day-to-day laboratory operations.
                            Our mission is to empower scientists to spend more time on
                            innovation and less time on paper work. This Privacy Policy
                            applies to information that we collect about you when you use:
                            <ul>
                                <li>Our website (decotolabs.com)</li>
                                <li>
                                    Our Decoto Labs products, services, and features that are
                                    available on or through our websites (ex. Regulator)
                                </li>
                            </ul>{" "}
                            This Privacy Policy also applies to information we collect when
                            you apply for a job at Decoto Labs or one of our subsidiaries.
                            Throughout this Privacy Policy we’ll refer to our websites and
                            other products and services collectively as “Services.” Please
                            note that this Privacy Policy does not apply to any of our
                            products or services that have a separate privacy policy. Below we
                            explain how we collect, use, and share information about you,
                            along with the choices that you have with respect to that
                            information.
                            <h3>Information We Collect</h3> We only collect information about
                            you if we have a reason to do so — for example, to provide our
                            Services, to communicate with you, or to make our Services better.
                            We collect this information from three sources: if and when you
                            provide information to us, automatically through operating our
                            Services, and from outside sources. Let’s go over the information
                            that we collect.{" "}
                            <h4 className="italic"> Information You Provide to Us </h4> It’s
                            probably no surprise that we collect information that you provide
                            to us directly. Here are some examples:
                            <ul>
                                <li>
                                    <b>Basic account information:</b> We ask for basic information
                                                                      from you in order to set up your account. For example, we
                                                                      require individuals who sign up for Regulator to provide an
                                                                      email address and password, along with a full name and
                                                                      laboratory name— and that’s it. There may be opportunities to
                                                                      provide us with more information — like your address and other
                                                                      information you want to share — but we don’t require that
                                                                      information to create a Regulator account.
                                </li>
                                <li>
                                    <b>Payment and contact information:</b> If you buy something
                                                                            from us, we’ll collect information to process those payments
                                                                            and contact you. If you buy something from us — a subscription
                                                                            to Regulator or additional custom services — you’ll provide
                                                                            additional personal and payment information like your name,
                                                                            credit card information, and contact information. We also keep
                                                                            a record of the purchases you’ve made.
                                </li>
                                <li>
                                    <b>Communications with us (hi there!):</b> You may also
                                                                               provide us with information when you respond to surveys,
                                                                               communicate with our support team about a support question,
                                                                               post a question in our public forums, or sign up for a
                                                                               newsletter. When you communicate with us via form, email,
                                                                               phone, or otherwise, we store a copy of our communications
                                                                               (including any call recordings as permitted by applicable
                                                                               law).
                                </li>
                                <li>
                                    <b>Job applicant information:</b> If you apply for a job with
                                                                      us — awesome! You may provide us with information like your
                                                                      name, contact information, resume or CV, and work
                                                                      authorization verification as part of the application process.
                                </li>
                            </ul>
                            <h4 className="italic">Information We Collect Automatically</h4>
                            We also collect some information automatically:
                            <ul>
                                <li>
                                    <b>Log information:</b> Like most online service providers, we
                                                            collect information that web browsers, mobile devices, and
                                                            servers typically make available, including the browser type,
                                                            IP address, unique device identifiers, language preference,
                                                            referring site, the date and time of access, operating system,
                                                            and mobile network information. We collect log information
                                                            when you use our Services.
                                </li>
                                <li>
                                    <b>Usage information:</b> We collect information about your
                                                              usage of our Services. For example, we collect information
                                                              about the actions that site administrators and users perform.
                                                              We also collect information about what happens when you use
                                                              our Services (e.g., page views, support document searches,
                                                              features enabled for your website, and other parts of our
                                                              Services) along with information about your device (e.g.,
                                                              screen size, name of cellular network, and mobile device
                                                              manufacturer). We use this information to, for example,
                                                              provide our Services to you, get insights on how people use
                                                              our Services so we can make our Services better, and
                                                              understand and make predictions about user retention.
                                </li>
                                <li>
                                    <b>Location information:</b> We may determine the approximate
                                                                 location of your device from your IP address. We collect and
                                                                 use this information to, for example, calculate how many
                                                                 people visit our Services from certain geographic regions.
                                </li>
                                <li>
                                    <b>Information from cookies & other technologies:</b> A cookie
                                                                                          is a string of information that a website stores on a
                                                                                          visitor’s computer, and that the visitor’s browser provides to
                                                                                          the website each time the visitor returns. Pixel tags (also
                                                                                          called web beacons) are small blocks of code placed on
                                                                                          websites and emails. Decoto Labs uses cookies and other
                                                                                          technologies like pixel tags to help us identify and track
                                                                                          visitors, usage, and access preferences for our Services, as
                                                                                          well as track and understand email campaign effectiveness and
                                                                                          to deliver targeted ads. For more information about our use of
                                                                                          cookies and other technologies for tracking, including how you
                                                                                          can control the use of cookies, please see our{" "}
                                    <Link to="/cookie-policy" target="_blank" rel="noreferrer">
                                        Cookie Policy
                                    </Link>
                                                                                          .
                                </li>
                            </ul>{" "}
                            <h4 className="italic">
                                Information We Collect from Other Sources
                            </h4>{" "}
                            We may also get information about you from other sources. For
                            example:
                            <ul>
                                <li>
                                    <b>Third Party Login:</b> If you create or log in to your
                                                              account through another service (like Google) we’ll receive
                                                              associated login information (e.g. a connection token, your
                                                              username, your email address)
                                </li>
                            </ul>{" "}
                            <h3>How and Why We Use Information</h3>
                            <h4 className="italic">Purposes for Using Information</h4>
                            We use information about you for the purposes listed below:
                            <ul>
                                <li>
                                    <b>To provide our Services.</b> For example, to set up and
                                                                    maintain your account, host your laboratory instance, backup
                                                                    and restore your data, provide customer service, process
                                                                    payments and orders, and verify user information.{" "}
                                </li>
                                <li>
                                    <b>
                                        To ensure quality, maintain safety, and improve our
                                        Services.{" "}
                                    </b>
                                    For example, by providing automatic upgrades and new versions
                                    of our Services. Or, for example, by monitoring and analyzing
                                    how users interact with our Services so we can create new
                                    features that we think our users will enjoy and that will help
                                    them manage their laboratories more efficiently or make our
                                    Services easier to use.
                                </li>
                                <li>
                                    <b>
                                        To market our Services and measure, gauge, and improve the
                                        effectiveness of our marketing.
                                    </b>{" "}
                                    For example, by targeting our marketing messages to groups of
                                    our users (like those who have a particular plan with us or
                                    have been users for a certain length of time), advertising our
                                    Services, analyzing the results of our marketing campaigns
                                    (like how many people purchased a paid plan after receiving a
                                    marketing message), and understanding and forecasting user
                                    retention.
                                </li>
                                <li>
                                    <b>To protect our Services, our users, and the public.</b> For
                                                                                               example, by detecting security incidents; detecting and
                                                                                               protecting against malicious, deceptive, fraudulent, or
                                                                                               illegal activity; fighting spam; complying with our legal
                                                                                               obligations; and protecting the rights and property of Decoto
                                                                                               Labs and others, which may result in us, for example,
                                                                                               declining a transaction or terminating Services.
                                </li>
                                <li>
                                    <b>To fix problems with our Services.</b> For example, by
                                                                              monitoring, debugging, repairing, and preventing issues.
                                </li>
                                <li>
                                    <b>To customize the user experience.</b> For example, to
                                                                             personalize your experience by serving you relevant
                                                                             notifications and advertisements for our Services, preventing
                                                                             unauthorized access to laboratory data and managing user
                                                                             sessions.
                                </li>
                                <li>
                                    <b>To communicate with you.</b> For example, by emailing you
                                                                    to ask for your feedback, share tips for getting the most out
                                                                    of our products, or keep you up to date on Decoto Labs;
                                                                    texting you to verify your payment; or calling you to share
                                                                    offers and promotions that we think will be of interest to
                                                                    you. If you don’t want to hear from us, you can opt out of
                                                                    marketing communications at any time. (If you opt out, we’ll
                                                                    still send you important updates relating to your account.)
                                </li>
                                <li>
                                    <b>To recruit and hire new Decoto Labs team members.</b> For
                                                                                             example, by evaluating job applicants and communicating with
                                                                                             them.
                                </li>
                            </ul>
                            <h4 className="italic">
                                Legal Bases for Collecting and Using Information
                            </h4>{" "}
                            A note here for those in the European Union about our legal
                            grounds for processing information about you under EU data
                            protection laws, which is that our use of your information is
                            based on the grounds that: (1) The use is necessary in order to
                            fulfill our commitments to you under the applicable terms of
                            service or other agreements with you or is necessary to administer
                            your account — for example, in order to enable access to our
                            website on your device or charge you for a paid plan; or (2) The
                            use is necessary for compliance with a legal obligation; or (3)
                            The use is necessary in order to protect your vital interests or
                            those of another person; or (4) We have a legitimate interest in
                            using your information — for example, to provide and update our
                            Services; to improve our Services so that we can offer you an even
                            better user experience; to safeguard our Services; to communicate
                            with you; to measure, gauge, and improve the effectiveness of our
                            advertising; and to understand our user retention and attrition;
                            to monitor and prevent any problems with our Services; and to
                            personalize your experience; or (5) You have given us your consent
                            — for example before we place certain cookies on your device and
                            access and analyze them later on, as described in our{" "}
                            <Link to="/cookie-policy" target="_blank" rel="noreferrer">
                                Cookie Policy
                            </Link>
                            <h3> Sharing Information</h3>
                            <h4 className="italic">How We Share Information</h4>
                            We share information about you in limited circumstances, and with
                            appropriate safeguards on your privacy. These are spelled out
                            below :
                            <ul>
                                <li>
                                    <b>Subsidiaries and independent contractors:</b> We may
                                                                                     disclose information about you to our subsidiaries and
                                                                                     independent contractors who need the information to help us
                                                                                     provide our Services or process the information on our behalf.
                                                                                     We require our subsidiaries and independent contractors to
                                                                                     follow this Privacy Policy for any personal information that
                                                                                     we share with them.
                                </li>
                                <li>
                                    <b>Third-party vendors:</b> We may share information about you
                                                                with third-party vendors who need the information in order to
                                                                provide their services to us, or to provide their services to
                                                                you or your site. This includes vendors that help us provide
                                                                our Services to you (like Stripe, which powers our payment
                                                                processing, payment providers that process your credit and
                                                                debit card information, fraud prevention services that allow
                                                                us to analyze fraudulent payment transactions, cloud storage
                                                                services, postal and email delivery services that help us stay
                                                                in touch with you, and customer chat and email support
                                                                services that help us communicate with you; those that assist
                                                                us with our marketing efforts (e.g., by providing tools for
                                                                identifying a specific marketing target group or improving our
                                                                marketing campaigns, and by placing ads to market our
                                                                services); those that help us understand and enhance our
                                                                Services (like analytics providers); those that make tools to
                                                                help us run our operations (like programs that help us with
                                                                task management, scheduling, word processing, email and other
                                                                communications, and collaboration among our teams); and other
                                                                third-party tools that help us manage operations; We require
                                                                vendors to agree to privacy commitments in order to share
                                                                information with them.
                                </li>
                                <li>
                                    <b>Legal and regulatory requirements:</b> We may disclose
                                                                              information about you in response to a subpoena, court order,
                                                                              or other governmental request. For more information on how we
                                                                              respond to requests for information about users, please see
                                                                              our{" "}
                                    <Link to="/terms-of-service" target="_blank" rel="noreferrer">
                                        Terms of Service
                                    </Link>
                                                                              .
                                </li>
                                <li>
                                    <b>To protect rights, property, and others:</b> We may
                                                                                    disclose information about you when we believe in good faith
                                                                                    that disclosure is reasonably necessary to protect the
                                                                                    property or rights of Decoto Labs, third parties, or the
                                                                                    public at large. For example, if we have a good faith belief
                                                                                    that there is an imminent danger of death or serious physical
                                                                                    injury, we may disclose information related to the emergency
                                                                                    without delay .
                                </li>
                                <li>
                                    <b>Business transfers:</b> In connection with any merger, sale
                                                               of company assets, or acquisition of all or a portion of our
                                                               business by another company, or in the unlikely event that
                                                               Decoto Labs goes out of business or enters bankruptcy, user
                                                               information would likely be one of the assets that is
                                                               transferred or acquired by a third party. If any of these
                                                               events were to happen, this Privacy Policy would continue to
                                                               apply to your information and the party receiving your
                                                               information may continue to use your information, but only
                                                               consistent with this Privacy Policy.
                                </li>
                                <li>
                                    <b>With your consent:</b> We may share and disclose
                                                              information with your consent or at your direction.
                                </li>
                                <li>
                                    <b>Aggregated or de-identified information:</b> We may share
                                                                                    information that has been aggregated or de-identified, so that
                                                                                    it can no longer reasonably be used to identify you. For
                                                                                    instance, we may publish aggregate statistics about the use of
                                                                                    our Services, or share a hashed version of your email address
                                                                                    to facilitate customized ad campaigns on other platforms.
                                </li>
                                <li>
                                    <b>Published support requests:</b> If you send us a request
                                                                       for assistance (for example, via a support email or one of our
                                                                       other feedback mechanisms), we reserve the right to publish
                                                                       that request in order to clarify or respond to your request,
                                                                       or to help us support other users.
                                </li>
                            </ul>
                            We have a long-standing policy that we do not sell our users'
                            data. We aren't a data broker, we don't sell your personal
                            information to data brokers, and we don't sell your information to
                            other companies that want to spam you with marketing emails.{" "}
                            <h4 className="italic">Information Shared Publicly</h4>{" "}
                            Information that you choose to make public is — you guessed it —
                            disclosed publicly. Decoto Labs may contact you to obtain feedback
                            and/or testimonials about our Services and reserves the right to
                            make this information public. Public information may also be
                            indexed by search engines or used by third parties. Please keep
                            all of this in mind when deciding what you would like to share
                            publicly.
                            <h3>How Long We Keep Information</h3> We generally discard
                            information about you when it’s no longer needed for the purposes
                            for which we collect and use it — described in the section above
                            on How and Why We Use Information — and we’re not legally required
                            to keep it.
                            <h3>Security</h3>
                            While no online service is 100% secure, we work very hard to
                            protect information about you against unauthorized access, use,
                            alteration, or destruction, and take reasonable measures to do so.
                            We monitor our Services for potential vulnerabilities and attacks.
                            <h3>Choices</h3> You have several choices available when it comes
                            to information about you:
                            <ul>
                                <li>
                                    <b>Limit the information that you provide:</b> If you have an
                                                                                   account with us, you can choose not to provide the optional
                                                                                   account information, profile information, and transaction and
                                                                                   billing information. Please keep in mind that if you do not
                                                                                   provide this information, certain features of our Services may
                                                                                   not be accessible.
                                </li>
                                <li>
                                    <b>Limit access to information on your mobile device:</b> Your
                                                                                              mobile device operating system should provide you with the
                                                                                              option to discontinue our ability to collect stored
                                                                                              information or location information via our mobile apps. If
                                                                                              you choose to limit this, you may not be able to use certain
                                                                                              features.
                                </li>
                                <li>
                                    <b>Opt out of marketing communications:</b> You may opt out of
                                                                                receiving promotional communications from us. Just follow the
                                                                                instructions in those communications or let us know. If you
                                                                                opt out of promotional communications, we may still send you
                                                                                other communications, like those about your account and legal
                                                                                notices.
                                </li>
                                <li>
                                    <b>Set your browser to reject cookies:</b> At this time,
                                                                               Decoto Labs does not respond to “do not track” signals across
                                                                               all of our Services. However, you can usually choose to set
                                                                               your browser to remove or reject browser cookies before using
                                                                               Decoto Labs’ website, with the drawback that certain features
                                                                               of Decoto Labs’ website may not function properly without the
                                                                               aid of cookies.
                                </li>
                                <li>
                                    <b>Close your account:</b> While we’d be very sad to see you
                                                               go, you can close your account if you no longer want to use
                                                               our Services. Please keep in mind that we may continue to
                                                               retain your information after closing your account, as
                                                               described in How Long We Keep Information above — for example,
                                                               when that information is reasonably needed to comply with (or
                                                               demonstrate our compliance with) legal obligations such as law
                                                               enforcement requests, or reasonably needed for our legitimate
                                                               business interests.
                                </li>
                            </ul>{" "}
                            <h3>Your Rights</h3> If you are located in certain parts of the
                            world, including California and countries that fall under the
                            scope of the European General Data Protection Regulation (aka the
                            “GDPR”), you may have certain rights regarding your personal
                            information, like the right to request access to or deletion of
                            your data.{" "}
                            <h4>European General Data Protection Regulation (GDPR)</h4>
                            If you are located in a country that falls under the scope of the
                            GDPR, data protection laws give you certain rights with respect to
                            your personal data, subject to any exemptions provided by the law,
                            including the rights to:
                            <ul>
                                <li>Request access to your personal data;</li>
                                <li>Request correction or deletion of your personal data;</li>
                                <li>Object to our use and processing of your personal data;</li>
                                <li>
                                    Request that we limit our use and processing of your personal
                                    data; and
                                </li>
                                <li>
                                    Request portability of your personal data. You also have the
                                    right to make a complaint to a government supervisory
                                    authority.
                                </li>
                            </ul>{" "}
                            <h4>California Consumer Privacy Act (CCPA)</h4> The California
                            Consumer Privacy Act (“CCPA”) requires us to provide California
                            residents with some additional information about the categories of
                            personal information we collect and share, where we get that
                            personal information, and how and why we use it. The CCPA also
                            requires us to provide a list of the “categories” of personal
                            information we collect, as that term is defined in the law, so,
                            here it is. In the last 12 months, we collected the following
                            categories of personal information from California residents,
                            depending on the Services used:
                            <ul>
                                <li>
                                    Identifiers (like your name, contact information, and device
                                    and online identifiers);
                                </li>
                                <li>
                                    Commercial information (your billing information and purchase
                                    history, for example);
                                </li>
                                <li>
                                    Characteristics protected by law (for example, you might
                                    provide your gender as part of a research survey for us);
                                </li>
                                <li>
                                    Internet or other electronic network activity information
                                    (such as your usage of our Services, like the actions you take
                                    as an administrator of a WordPress.com site);
                                </li>
                                <li>
                                    Geolocation data (such as your location based on your IP
                                    address);
                                </li>
                                <li>
                                    Audio, electronic, visual or similar information (such as your
                                    profile picture, if you uploaded one);
                                </li>
                                <li>
                                    Professional or employment-related information (for example,
                                    your company and team information if you are a Happy Tools
                                    user, or information you provide in a job application); and
                                </li>
                                <li>
                                    Inferences we make (such as likelihood of retention or
                                    attrition).
                                </li>
                            </ul>{" "}
                            You can find more information about what we collect and sources of
                            that information in the{" "}
                            <span className="font-bold">Information We Collect</span> section
                            above. We collect personal information for the business and
                            commercial purposes described in the{" "}
                            <span className="font-bold">How and Why We Use Information</span>{" "}
                            section. And we share this information with the categories of
                            third parties described in the{" "}
                            <span className="font-bold">Sharing Information</span> section. If
                            you are a California resident, you have additional rights under
                            the CCPA, subject to any exemptions provided by the law, including
                            the right to:
                            <ul>
                                <li>
                                    Request to know the categories of personal information we
                                    collect, the categories of business or commercial purpose for
                                    collecting and using it, the categories of sources from which
                                    the information came, the categories of third parties we share
                                    it with, and the specific pieces of information we collect
                                    about you;
                                </li>
                                <li>
                                    Request deletion of personal information we collect or
                                    maintain;
                                </li>
                                <li>Opt out of any sale of personal information; and</li>
                                <li>
                                    Not receive discriminatory treatment for exercising your
                                    rights under the CCPA.
                                </li>
                            </ul>{" "}
                            <h4>Contacting Us About These Rights</h4> You can usually access,
                            correct, or delete your personal data using your account settings
                            and tools that we offer, but if you aren’t able to or you’d like
                            to contact us about one of the other rights, reach out to{" "}
                            <a href="mailto: support@decotolabs.com">
                                support@decotolabs.com
                            </a>
                            . When you contact us about one of your rights under this section,
                            we’ll need to verify that you are the right person before we
                            disclose or delete anything. For example, if you are a user, we
                            will need you to contact us from the email address associated with
                            your account. You can also designate an authorized agent to make a
                            request on your behalf by giving us written authorization. We may
                            still require you to verify your identity with us.{" "}
                            <h3>Controllers and Responsible Companies</h3>
                            Decoto Labs’ Services are available in the United States. Decoto
                            Labs is the controller of personal information, which means that
                            they are the company responsible for processing that information,
                            for all of our Services.
                            <h3>How to Reach Us</h3> If you have a question about this Privacy
                            Policy, or you would like to contact us about any of the rights
                            mentioned in the <span className="font-bold">Your Rights</span>{" "}
                            section above, please contact us via{" "}
                            <a
                                href="mailto:support@decotolabs.com"
                                target="_blank"
                                rel="noreferrer"
                            >
                                email
                            </a>
                            .<h3>Sources</h3>
                            This Privacy Policy is adapted from{" "}
                            <a
                                href="https://github.com/Automattic/legalmattic"
                                target="_blank"
                                rel="noreferrer"
                            >
                                Automattic's open source
                            </a>{" "}
                            legal documents. We have revised the policy to reflect Decoto
                            Labs' actual practices.
                        </p>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}
