import {ArrowLeftIcon} from "@heroicons/react/solid";
import React from "react";
import {Link} from "react-router-dom";

export default function BlogPost4() {

    return (
        <div className="relative py-16 bg-white overflow-hidden">
            <div className="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
                <div className="relative h-full text-lg max-w-prose mx-auto" aria-hidden="true">
                    <svg
                        className="absolute top-12 left-full transform translate-x-32"
                        width={404}
                        height={384}
                        fill="none"
                        viewBox="0 0 404 384"
                    >
                        <defs>
                            <pattern
                                id="74b3fd99-0a6f-4271-bef2-e80eeafdf357"
                                x={0}
                                y={0}
                                width={20}
                                height={20}
                                patternUnits="userSpaceOnUse"
                            >
                                <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor"/>
                            </pattern>
                        </defs>
                        <rect width={404} height={384} fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)"/>
                    </svg>
                    <svg
                        className="absolute top-1/2 right-full transform -translate-y-1/2 -translate-x-32"
                        width={404}
                        height={384}
                        fill="none"
                        viewBox="0 0 404 384"
                    >
                        <defs>
                            <pattern
                                id="f210dbf6-a58d-4871-961e-36d5016a0f49"
                                x={0}
                                y={0}
                                width={20}
                                height={20}
                                patternUnits="userSpaceOnUse"
                            >
                                <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor"/>
                            </pattern>
                        </defs>
                        <rect width={404} height={384} fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)"/>
                    </svg>
                    <svg
                        className="absolute bottom-12 left-full transform translate-x-32"
                        width={404}
                        height={384}
                        fill="none"
                        viewBox="0 0 404 384"
                    >
                        <defs>
                            <pattern
                                id="d3eb07ae-5182-43e6-857d-35c643af9034"
                                x={0}
                                y={0}
                                width={20}
                                height={20}
                                patternUnits="userSpaceOnUse"
                            >
                                <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor"/>
                            </pattern>
                        </defs>
                        <rect width={404} height={384} fill="url(#d3eb07ae-5182-43e6-857d-35c643af9034)"/>
                    </svg>
                </div>
            </div>
            <div className="mt-4 relative px-4 sm:px-6 lg:px-8">
                <div className="text-lg max-w-prose mx-auto">
                    <Link to="/blog"
                          className="inline-flex space-x-4">
                        <span
                            className="inline-flex items-center text-sm font-medium text-blue-500 space-x-1">
                      <ArrowLeftIcon className="h-5 w-5" aria-hidden="true"/>
                            <span>Back to Blog</span>
                    </span>
                    </Link>
                    <span className="block text-base text-center text-blue-600 font-semibold tracking-wide uppercase">
                        Article
                    </span>
                    <h1>
                        <span
                            className="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              So you want to start a CLIA lab?
            </span>
                    </h1>
                    <p className="mt-8 text-xl text-gray-500 leading-8">
                        You have finally gotten your diagnostic technology to work and now you have to jump through regulatory
                        hoops to actually deliver it to patients in need. Never fear! The process of getting your CLIA license
                        does not have to be daunting. Learn about the logistics of CLIA licensure and the activities you can
                        expect to spend your time on leading up to inspection day.
                    </p>
                </div>
                <figure className="my-2">
                    <img
                        className="mx-auto w-7/12 rounded-lg"
                        src={process.env.PUBLIC_URL+"/assets/blog/blog4_new_clia_lab_img.png"}
                        alt=""
                    />
                </figure>
                <div className="mt-6 prose prose-blue prose-lg text-gray-500 mx-auto">
                    <h2>The Logistics</h2>
                    <p>
                        The Centers for Medicare and Medicaid Services provide a{" "}
                        <a href="https://www.cms.gov/files/document/cms-clia-laboratory-quick-start-guide-remediated.pdf"
                           target="_blank"
                           rel="noreferrer">
                            quick start guide
                        </a> with the required
                        forms and guidance on which certificate type laboratories should pursue based on the complexity
                        of testing. However, if your laboratory is performing non-waived testing this guidance can be
                        lacking in preparing you for your inspection.
                    </p>
                    <blockquote>
                        <p>
                            Nonwaived testing is the term used to refer to moderate and high complexity testing collectively.
                            Laboratories performing these tests must have a CLIA certificate and are subject to regular
                            inspection.
                        </p>
                    </blockquote>
                    <h2>Your War Plan</h2>
                    <p>From a high level, new laboratories should prioritize: </p>
                    <ul>
                        <li>
                            <h4>Ensuring the laboratory has a qualified Laboratory Director in accordance with CLIA{" "}
                                <a href="https://www.ecfr.gov/current/title-42/part-493/subpart-M"
                                   target="_blank"
                                   rel="noreferrer"
                                >regulations</a> and applicable accreditation organization requirements.</h4>
                            <p>
                                We recommend completing this step as early on in your CLIA certification process as
                                possible. Laboratory Director approval and review is required on virtually all of the
                                documentation your lab will need to meet regulatory requirements.
                            </p>
                        </li>
                        <li>
                            <h4>Enroll in a proficiency testing program for all tests with nonwaived status. A list of
                                approved programs can be found{" "}
                                <a href="https://www.cms.gov/Regulations-and-Guidance/Legislation/CLIA/Proficiency_Testing_Providers"
                                   target="_blank"
                                   rel="noreferrer"
                                >
                                    here</a>.
                            </h4>
                            <p>
                                This is a relatively straight-forward step if the testing in your laboratory is common
                                enough to have a corresponding proficiency testing program. However, labs performing
                                ground-breaking high-complexity testing may have to develop and implement their own
                                proficiency testing program to meet requirements.
                            </p>
                        </li>
                        <li>
                            <h4>Perform validation of new methods that have nonwaived status. This validation must establish
                            the following performance specifications. More information about requirements can be found{" "}
                                <a href="https://www.ecfr.gov/current/title-42/chapter-IV/subchapter-G/part-493/subpart-K/subject-group-ECFRc96daead380f6ed/section-493.1253"
                                   target="_blank"
                                   rel="noreferrer"
                                >here</a>.
                            </h4>
                            <p>
                                Validation efforts should document the following results:
                                <ol>
                                    <li>Accuracy</li>
                                    <li>Precision</li>
                                    <li>Analytical sensitivity</li>
                                    <li>Analytical specificity to include interfering substances</li>
                                    <li>Reportable range of test results</li>
                                    <li>Reference intervals (normal values)</li>
                                </ol>
                            </p>
                        </li>
                        <li>
                            <h4>Establish and implement a procedure manual. Note that the manual must be readily available
                                and followed by laboratory personnel. Regulations specify more details{" "}
                                <a href="https://www.ecfr.gov/current/title-42/chapter-IV/subchapter-G/part-493/subpart-K/subject-group-ECFRc96daead380f6ed/section-493.1251"
                                   target="_blank"
                                   rel="noreferrer"
                                >here.</a>
                            </h4>
                            <p>
                                It is important to note that the procedures required by the above linked regulations do
                                not cover all of the policies a CLIA lab should have developed and implemented. Keep an
                                eye out for an upcoming blog post providing a guide to all the policies and procedures
                                your laboratory needs.
                            </p>
                        </li>
                        <li>
                            <h4>Develop and implement quality control and quality assurance programs. The regulatory{" "}
                                <a href="https://www.ecfr.gov/current/title-42/part-493/subpart-K"
                                   target="_blank"
                                   rel="noreferrer"
                                >guidance</a>{" "}
                            for creating a quality system is extensive and extremely detailed. Be prepared to dedicate
                                the bulk of your laboratory establishment efforts to implementing your quality systems.
                            </h4>
                            <p>
                                Quality control refers to procedures in place to
                                <ul>
                                    <li>detect immediate errors that occur due to test failure,
                                    adverse environmental conditions,
                                    and/or operator performance and</li>
                                    <li>monitor the accuracy and precision of test performance over time.</li>
                                </ul>
                                Quality assurance refers to the systems in place to monitor, evaluate and correct
                                identified problems in pre-analytic (systems used prior to testing), analytic (systems
                                used during testing), and post-analytic (systems used after testing) phases.
                            </p>
                        </li>
                        <li>
                            <h4>
                                Maintain records for all testing personnel that include a written description of their
                                responsibilities and duties, proof of qualification in accordance with their duties and
                                records of their competency assessments.
                            </h4>
                            <p>
                                Documentation you should maintain for personnel includes (but is not limited to):
                                <ul>
                                    <li>Resume/CV</li>
                                    <li>Diploma</li>
                                    <li>License</li>
                                    <li>Job Description</li>
                                    <li>Letter of Delegation</li>
                                    <li>Training Records</li>
                                    <li>Competency Records</li>
                                    <li>Continuing Education</li>
                                </ul>
                            </p>
                        </li>
                        <li>
                            <h4>
                                Establish a system for organizing and retaining records. Records to be maintained include
                                but are not limited to: temperature logs, maintenance logs, master logs, specimen referral
                                logs, reagent logs, action logs, communication logs, quality control logs and patient test
                                result logs.
                            </h4>
                            <p>
                                Organizing and maintaining records is one of the most time-consuming tasks that laboratories
                                have to keep up with. If your laboratory chooses to pursue using a paper system to keep
                                track of all of this information, there are two considerations to keep in mind.
                                <ol>
                                    <li>Your system should make it straight-forward to locate any given record.</li>
                                    <li>Your system should make it straight-forward to cross-reference related records.</li>
                                </ol>
                            </p>
                        </li>
                    </ul>
                    <h2>Still have questions on how to start a lab?</h2>
                    <p>
                        The Decoto Labs team is happy to help however we can. Whether you need SOP templates, tips for
                        nailing the laboratory walk through portion of your inspection or how to approach proficiency
                        testing if your nonwaived test does not have an appropriate program match; we want to help.
                    </p>
                    <p>
                        Visit our <a href="https://decotolabs.com">homepage</a>{" "} to schedule time with our founders
                        to get your questions answered or reach out via email to <a href="mailto:info@decotolabs.com">info@decotolabs.com</a>.
                        You can also keep up with our blog posts and resources by following our{" "}
                        <a href="https://www.linkedin.com/company/decoto-labs" target="_blank" rel="noreferrer">LinkedIn</a> page.
                    </p>
                    <h2>Interested in trying our QMS, Regulator?</h2>
                    <p>
                        Our Early Adopter program is still open, but spots are filling up quickly. You can reach us via
                        email at{" "}
                        <a href="mailto:info@decotolabs.com">info@decotolabs.com</a>{" "}
                        with “Early Adopter Program” as the subject line. Still not sure? Visit our{" "}
                        <a href="https://decotolabs.com">homepage</a>{" "}
                        where you can directly schedule time to speak with our founders and get your questions answered.
                    </p>
                </div>
            </div>
        </div>
    )
}
