"use strict";
// Copyright 2018 Google LLC
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//    http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
Object.defineProperty(exports, "__esModule", { value: true });
exports.request = exports.instance = exports.Gaxios = void 0;
const gaxios_1 = require("./gaxios");
Object.defineProperty(exports, "Gaxios", { enumerable: true, get: function () { return gaxios_1.Gaxios; } });
var common_1 = require("./common");
Object.defineProperty(exports, "GaxiosError", { enumerable: true, get: function () { return common_1.GaxiosError; } });
/**
 * The default instance used when the `request` method is directly
 * invoked.
 */
exports.instance = new gaxios_1.Gaxios();
/**
 * Make an HTTP request using the given options.
 * @param opts Options for the request
 */
async function request(opts) {
    return exports.instance.request(opts);
}
exports.request = request;
