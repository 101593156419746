import {ArrowLeftIcon} from "@heroicons/react/solid";
import React from "react";
import {Link} from "react-router-dom";

export default function BlogPost14() {

    return (
        <div className="relative py-16 bg-white overflow-hidden">
            <div className="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
                <div className="relative h-full text-lg max-w-prose mx-auto" aria-hidden="true">
                    <svg
                        className="absolute top-12 left-full transform translate-x-32"
                        width={404}
                        height={384}
                        fill="none"
                        viewBox="0 0 404 384"
                    >
                        <defs>
                            <pattern
                                id="74b3fd99-0a6f-4271-bef2-e80eeafdf357"
                                x={0}
                                y={0}
                                width={20}
                                height={20}
                                patternUnits="userSpaceOnUse"
                            >
                                <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor"/>
                            </pattern>
                        </defs>
                        <rect width={404} height={384} fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)"/>
                    </svg>
                    <svg
                        className="absolute top-1/2 right-full transform -translate-y-1/2 -translate-x-32"
                        width={404}
                        height={384}
                        fill="none"
                        viewBox="0 0 404 384"
                    >
                        <defs>
                            <pattern
                                id="f210dbf6-a58d-4871-961e-36d5016a0f49"
                                x={0}
                                y={0}
                                width={20}
                                height={20}
                                patternUnits="userSpaceOnUse"
                            >
                                <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor"/>
                            </pattern>
                        </defs>
                        <rect width={404} height={384} fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)"/>
                    </svg>
                    <svg
                        className="absolute bottom-12 left-full transform translate-x-32"
                        width={404}
                        height={384}
                        fill="none"
                        viewBox="0 0 404 384"
                    >
                        <defs>
                            <pattern
                                id="d3eb07ae-5182-43e6-857d-35c643af9034"
                                x={0}
                                y={0}
                                width={20}
                                height={20}
                                patternUnits="userSpaceOnUse"
                            >
                                <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor"/>
                            </pattern>
                        </defs>
                        <rect width={404} height={384} fill="url(#d3eb07ae-5182-43e6-857d-35c643af9034)"/>
                    </svg>
                </div>
            </div>
            <div className="mt-4 relative px-4 sm:px-6 lg:px-8">
                <div className="text-lg max-w-prose mx-auto">
                    <Link to="/blog"
                          className="inline-flex space-x-4">
                        <span
                            className="inline-flex items-center text-sm font-medium text-blue-500 space-x-1">
                      <ArrowLeftIcon className="h-5 w-5" aria-hidden="true"/>
                            <span>Back to Blog</span>
                    </span>
                    </Link>
                    <span className="block text-base text-center text-blue-600 font-semibold tracking-wide uppercase">
                        Article
                    </span>
                    <h1>
                        <span
                            className="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              Document Roll Call: Referral Laboratories, Personnel, Laboratory Information Systems
            </span>
                    </h1>
                    <p className="mt-8 text-xl text-gray-500 leading-8">
                        This is the final installment of our Document Roll Call series! This Tuesday,
                        we are covering the policies and procedures your laboratory should have around referral laboratories,
                        personnel and laboratory information systems. Even though our Document Roll Call series has come to an
                        end, stay tuned to the blog every week for more free resources for managing your clinical laboratory's
                        quality program.
                    </p>
                </div>
                <figure className="my-2">
                    <img
                        className="mx-auto w-7/12 rounded-lg"
                        src={process.env.PUBLIC_URL+"/assets/blog/blog14_referral_labs_personnel_lab_info_systems.png"}
                        alt=""
                    />
                </figure>
                <div className="mt-6 prose prose-blue prose-lg text-gray-500 mx-auto">
                    <p>
                        Unsure how to cover your referral laboratories, personnel and laboratory information system procedure
                        requirements? Here are some of the documents you may want to implement in your laboratory and the points to
                        make sure your documents touch on. Please note these documents are just our recommendations
                        based on our experience working in clinical laboratory settings and do not guarantee that this
                        list is exhaustive.
                    </p>
                    <h2>Referral Laboratories Procedures & Policies</h2>
                    <ul>
                        <li className="list-disc">
                            Include your laboratory’s policy for ensuring specimens sent to referral laboratories follow
                            all requisition, collection and handling specifications of the referral laboratory
                        </li>
                        <li className="list-disc">
                            Include your laboratory's procedure for the selection and evaluation of laboratories to which
                            it refers specimens or materials for testing
                        </li>
                        <li className="list-disc">
                            Include your laboratory's procedure for retaining the original or an exact copy of the
                            testing laboratory's report is retained by the referring laboratory
                        </li>
                        <li className="list-disc">
                            Include your laboratory’s procedure for reporting essential elements of referred test results
                            are reported by the referring laboratory as received from the referral laboratory
                        </li>

                    </ul>
                    <h2>Personnel Procedures & Policies</h2>
                    <ul>
                        <li className="list-disc">
                            Include your laboratory’s documentation of the required qualifications for:
                            <ul>
                                <li className="list-disc">
                                    Section Directors/Technical Supervisors
                                </li>
                                <li className="list-disc">
                                    Supervisors/General Supervisors
                                </li>
                                <li className="list-disc">
                                    Technical consultants
                                </li>
                                <li className="list-disc">
                                    Clinical Consultants
                                </li>
                                <li className="list-disc">
                                    Testing Personnel
                                </li>
                            </ul>
                        </li>
                        <li className="list-disc">
                            Include your laboratory’s written organization chart or narrative description that describes
                            the reporting relationships
                        </li>
                        <li className="list-disc">
                            Include your laboratory’s procedure for maintaining a continuing education program
                        </li>
                        <li className="list-disc">
                            Include your laboratory's procedure for testing visual color discrimination
                        </li>
                        <li className="list-disc">
                            Include your laboratory's procedure for training and assessing competency ensuring you
                            include the procedure if testing personnel fail to demonstrate satisfactory performance
                            on competency assessment and corrective action
                        </li>
                        <li className="list-disc">
                            Include your laboratory's procedure for assessing section directors, general supervisors,
                            technical consultants for performance
                        </li>
                        <li className="list-disc">
                            Include your laboratory's procedure for "hand off" communication
                        </li>
                        <li className="list-disc">
                            Include your laboratory's procedure for maintaining the following documentation for technical
                            personnel:
                            <ul>
                                <li className="list-disc">
                                    Diploma
                                </li>
                                <li className="list-disc">
                                    License/Certification
                                </li>
                                <li className="list-disc">
                                    Resume/CV
                                </li>
                                <li className="list-disc">
                                    Job Description
                                </li>
                                <li className="list-disc">
                                    Continuing education records
                                </li>
                                <li className="list-disc">
                                    Records of work-related incidents, accidents, and/or radiation exposure
                                </li>
                                <li className="list-disc">
                                    Dates of employment
                                </li>
                            </ul>
                        </li>
                    </ul>
                    <h2>Laboratory information Systems Procedures & Policies</h2>
                    <ul>
                        <li className="list-disc">
                            Include your laboratory’s procedure for ensuring internal and external storage and transfer
                            of data maintains patient confidentiality and security
                        </li>
                        <li className="list-disc">
                            Include your laboratory's policy to ensure that patient data are accessible in a timely
                            manner only to authorized individuals
                        </li>
                        <li className="list-disc">
                            Include your laboratory's system for being able to establish the identity of the analyst
                            and the date the test was performed for every test
                        </li>
                        <li className="list-disc">
                            Include your laboratory's evidence that any remote LIS facility complies with regulatory
                            requirements
                        </li>
                        <li className="list-disc">
                            Include your laboratory’s procedure for adequately testing for proper functioning and
                            obtaining laboratory director approval when the laboratory information system (LIS) is first
                            installed and after any modifications
                        </li>
                        <li className="list-disc">
                            Include your laboratory's policy to customized software is appropriately documented
                        </li>
                        <li className="list-disc">
                            Include your laboratory's procedure for tracking the identity of persons that have added or
                            modified customized software
                        </li>
                        <li className="list-disc">
                            Include your laboratory's procedure for ensuring laboratory director review and approval of
                            all new laboratory information system policies and procedures and substantial changes to
                            existing documents
                        </li>
                        <li className="list-disc">
                            Include your laboratory's procedure for contacting responsible persons in case of laboratory
                            information system malfunction
                        </li>
                        <li className="list-disc">
                            Include your laboratory's written policies that specify who may use the laboratory information
                            system or access patient data, change results, change billing or alter programs
                        </li>
                        <li className="list-disc">
                            Include your laboratory's policy for ensuring computer access codes are in place to confine
                            access to functions they are authorized to do and maintaining the security of those codes
                        </li>
                        <li className="list-disc">
                            Include your laboratory's written documentation of network security measures in place to
                            ensure confidentiality or patient data
                        </li>
                        <li className="list-disc">
                            Include your laboratory's policy to ensure that calculated values are reported with patient
                            results and are reviewed every two years or when a system change affecting calculations is
                            made
                        </li>
                        <li className="list-disc">
                            Include your laboratory's procedure to ensure reporting of patient results in a prompt and
                            useful fashion during partial or complete downtime and recovery of the system
                        </li>
                        <li className="list-disc">
                            Include your laboratory's procedure for the preservation of data and equipment in case of an
                            unexpected destructive event
                        </li>
                        <li className="list-disc">
                            Include your laboratory's procedure to verify that patient results are accurately transmitted
                            from point of entry to patient reports
                        </li>
                        <li className="list-disc">
                            Include your laboratory's procedure for handling changes in laboratory functions necessary
                            during partial or complete shutdown and recovery of system that interface with the laboratory
                            information system
                        </li>
                    </ul>
                    <h2>Still have questions on referral laboratories, personnel and laboratory information systems?</h2>
                    <p>
                        The Decoto Labs team is happy to help however we can. Whether you need referral lab assessment
                        criteria, laboratory information system advice or performance assessment strategies
                        &#8212; we want to help.
                    </p>
                    <p>
                        Visit our <a href="https://decotolabs.com">homepage</a>{" "} to schedule time with our founders
                        to get your questions answered or reach out via email to
                        <a href="mailto:info@decotolabs.com">info@decotolabs.com</a>.
                        You can also keep up with our blog posts and resources by following our{" "}
                        <a href="https://www.linkedin.com/company/decoto-labs" target="_blank" rel="noreferrer">LinkedIn</a> page.
                    </p>
                    <h2>Interested in trying our QMS, Regulator?</h2>
                    <p>
                        Our Early Adopter program is still open, but spots are filling up quickly. You can reach us via
                        email at{" "}
                        <a href="mailto:info@decotolabs.com">info@decotolabs.com</a>{" "}
                        with “Early Adopter Program” as the subject line. Still not sure? Visit our{" "}
                        <a href="https://decotolabs.com">homepage</a>{" "}
                        where you can directly schedule time to speak with our founders and get your questions answered.
                    </p>
                </div>
            </div>
        </div>
    )
}
