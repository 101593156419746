import React from "react";
import Footer from "./common/Footer";
import { Link } from "react-router-dom";

export default function TermsOfService() {
    return (
        <div>
      <span id="top">
      </span>
            <div className="relative py-16 bg-white overflow-hidden rounded-lg">
                <div className="relative px-4 sm:px-6 lg:px-8">
                    <div className="text-lg max-w-prose mx-auto">
                        <h1>
              <span className="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                Terms of Service: Regulator
                <p className="text-sm font-normal">
                  Effective Date: April 21, 2022
                </p>
              </span>
                        </h1>
                        <div className="mt-6 prose prose-blue prose-lg text-gray-500 mx-auto">
                            <p>
                                <strong>Binding Agreement</strong>. This is a binding contract
                                                                  (&quot;Terms&quot;) between you and Regulator, which is wholly
                                                                  owned and operated by the{" "}
                                <Link to="/">folks at Decoto Labs</Link> (&quot;we,&quot;
                                &quot;us,&quot; &quot;our,&quot; &quot;Decoto Labs,&quot;
                                &quot;Regulator&quot;). These Terms apply whenever you use any
                                                                  of the sites, apps, products, or services (&quot;Services&quot;)
                                                                  we offer, in existence now to created in the future. Further, we
                                                                  may automatically upgrade our Services, and these Terms will
                                                                  apply to such upgrades. By accessing or using the Services, you
                                                                  agree to be bound by these Terms. If you use our services on
                                                                  behalf of an organization, you agree to these terms on behalf of
                                                                  that organization. If you do not agree to these Terms, you may
                                                                  not use the Services.
                            </p>
                            <p>
                                <strong>Privacy</strong>. See our{" "}
                                <Link to="/privacy-policy">Privacy Policy</Link> for details on
                                                        how we collect, store, and share user information.
                            </p>
                            <p>
                                <strong>Your Data and Permissions</strong>. Regulator allows you
                                                                          to store information about your procedures, personnel, samples,
                                                                          equipment, reagents and competency (&quot;Your Data&quot;). Your
                                                                          Data is yours. Regulator does not claim any right, title, or
                                                                          interest in Your Data.
                            </p>
                            <p>
                                You grant us a non-exclusive, worldwide, royalty free license to
                                do the things we need to do to provide the Services, including
                                but not limited to storing, analyzing, and reproducing Your
                                Data. This license extends to trusted third parties we work
                                with.
                            </p>
                            <p>
                                <strong>Account Security</strong>. You are responsible for
                                                                 safeguarding your password to the Services, making sure that
                                                                 others don&#39;t have access to it, and keeping your account
                                                                 information current. You must immediately notify Regulator of
                                                                 any unauthorized uses of your account or any other breaches of
                                                                 security. Regulator will not be liable for your acts or
                                                                 omissions, including any damages of any kind incurred as a
                                                                 result of your acts or omissions.
                            </p>
                            <p>
                                <strong>Changes to these Terms</strong>. We are constantly
                                                                       updating our Services, and that means sometimes we have to
                                                                       change the legal terms under which our Services are offered. If
                                                                       we make changes that are material, we will let you know, for
                                                                       example by posting on our blog, or by sending you an email or
                                                                       other communication before the changes take effect. The notice
                                                                       will designate a reasonable period of time after which the new
                                                                       Terms will take effect. If you disagree with our changes, then
                                                                       you should stop using Regulator within the designated notice
                                                                       period. Your continued use of Regulator will be subject to the
                                                                       new Terms. However, any dispute that arose before the changes
                                                                       shall be governed by the Terms (including the binding individual
                                                                       arbitration clause) that were in place when the dispute arose.
                            </p>
                            <p>
                                <strong>Our Intellectual Property</strong>: The Services and all
                                                                          materials contained therein, including, without limitation,
                                                                          Regulator logo, and all designs, text, graphics, pictures,
                                                                          information, data, software, other files, and the selection and
                                                                          arrangement thereof (collectively, the &quot;Regulator
                                                                          Materials&quot;) are the property of Regulator or its licensors
                                                                          or users and are protected by U.S. and international
                                                                          intellectual property laws. You are granted a personal, limited,
                                                                          non-sublicensable, non-exclusive, revocable license to access
                                                                          and use Regulator Materials in accordance with these Terms for
                                                                          the sole purpose of enabling you to use and enjoy the Services.
                            </p>
                            <p>
                                Other trademarks, service marks, graphics and logos used in
                                connection with the Services may be the trademarks of other
                                third parties. Your use of the Services grants you no right or
                                license to reproduce or otherwise use any Regulator, Decoto
                                Labs, or third-party trademarks.
                            </p>
                            <p>
                                <strong>Termination</strong>. You are free to stop using the
                                                            Services at any time. We also reserve the right to suspend or
                                                            end the Services at any time at our discretion and without
                                                            notice. For example, we may suspend or terminate your use of the
                                                            Services if you fail to comply with these Terms, or use the
                                                            Services in a manner that would cause us legal liability,
                                                            disrupt the Services, or disrupt others&#39; use of the
                                                            Services.
                            </p>
                            <p>
                                <strong>Disclaimer of Warranties</strong>. Decoto Labs makes no
                                                                         warranties of any kind with respect to Regulator or your use of
                                                                         the Services.
                            </p>
                            <p>
                                <strong>Limitation of Liability</strong>. Decoto Labs shall not
                                                                        have any liability for any indirect, incidental, consequential,
                                                                        special, exemplary, or damages under any theory of liability
                                                                        arising out of, or relating to, these Terms or your use of
                                                                        Regulator. As a condition of access to Regulator, you understand
                                                                        and agree that Decoto Labs&#39; liability shall not exceed
                                                                        $5.23.
                            </p>
                            <p>
                                <strong>Regulatory Outcomes</strong>. Decoto Labs is not
                                                                    responsible and does not guarantee any outcome with respect to
                                                                    inspections, audits, surveys or any other regulatory activities
                                                                    conducted by regulatory bodies or other third parties. As a
                                                                    condition of access to Regulator, you understand and agree that
                                                                    Decoto Labs is not liable for the outcome of any of the
                                                                    previously mentioned.
                            </p>
                            <p>
                                <strong>Indemnification</strong>. You will indemnify, defend,
                                                                and hold Decoto Labs, its subsidiaries, affiliates, officers,
                                                                and employees, harmless from any and all claims, damages,
                                                                losses, liabilities, actions, judgments, costs, and expenses
                                                                (including reasonable attorneys&#39; fees) brought by a third
                                                                party arising out of or in connection with: (i) any act or
                                                                omission by you, in connection with your use of Regulator or
                                                                (ii) your breach or alleged breach of any of these Terms. Decoto
                                                                Labs may, at its option, elect to take over control of the
                                                                defense and settlement of a claim subject to indemnification.
                                                                You agree not to settle any such claim without the prior written
                                                                consent of Decoto Labs.
                            </p>
                            <p>
                                <strong>Arbitration Agreement</strong>. We both agree to resolve
                                                                      any disputes arising out of these Terms or your use of Regulator
                                                                      through final and binding arbitration. Arbitration is a less
                                                                      formal procedure than a lawsuit in court, with the goal of
                                                                      resolving disputes more quickly. We both agree to waive our
                                                                      rights to have our claims decided by a judge or jury. Instead,
                                                                      the arbitration shall be finally settled in accordance with the
                                                                      Comprehensive Arbitration Rules of the Judicial Arbitration and
                                                                      Mediation Service, Inc. (&quot;JAMS&quot;) by three arbitrators
                                                                      appointed in accordance with the Rules. The arbitration will be
                                                                      held in the United States county where you live or work, San
                                                                      Francisco (CA), or any other location we agree to, and shall be
                                                                      conducted in the English language. The arbitrators&#39; decision
                                                                      may be enforced in any court of competent jurisdiction. Decoto
                                                                      Labs will pay all arbitration fees. The prevailing party shall
                                                                      be entitled to costs and attorneys&#39; fees, but Decoto Labs
                                                                      will not seek its attorneys&#39; fees and costs in arbitration
                                                                      unless the arbitrators determine that your claim is frivolous.
                            </p>
                            <p>
                                <strong>Exceptions to Agreement to Arbitrate</strong>. Claims
                                                                                     for injunctive or equitable relief or claims regarding
                                                                                     intellectual property rights may be brought in any competent
                                                                                     court without the posting of a bond.
                            </p>
                            <p>
                                <strong>No Class Actions</strong>. You may resolve disputes with
                                                                 us only on an individual basis; you may not bring a claim as a
                                                                 plaintiff or a class member in a class, consolidated, or
                                                                 representative action.{" "}
                                <strong>
                                    Class arbitrations, class actions, private attorney general
                                    actions, and consolidation with other arbitrations are not
                                    permitted.
                                </strong>
                            </p>
                            <p>
                                <strong>Governing Law</strong>. You agree that these Terms, and
                                                              your use of Regulator, are governed by California law, in the
                                                              United States of America, without regard to its principles of
                                                              conflicts of law.
                            </p>
                            <p>
                                <strong>Sources</strong>
                                This Privacy Policy is adapted from{" "}
                                <a
                                    href="https://github.com/Automattic/legalmattic"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    Automattic's open source
                                </a>{" "}
                                legal documents. We have revised the policy to reflect Decoto
                                Labs' actual practices.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}
