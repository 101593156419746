import {ArrowLeftIcon} from "@heroicons/react/solid";
import React from "react";
import {Link} from "react-router-dom";

export default function BlogPost7() {

    return (
        <div className="relative py-16 bg-white overflow-hidden">
            <div className="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
                <div className="relative h-full text-lg max-w-prose mx-auto" aria-hidden="true">
                    <svg
                        className="absolute top-12 left-full transform translate-x-32"
                        width={404}
                        height={384}
                        fill="none"
                        viewBox="0 0 404 384"
                    >
                        <defs>
                            <pattern
                                id="74b3fd99-0a6f-4271-bef2-e80eeafdf357"
                                x={0}
                                y={0}
                                width={20}
                                height={20}
                                patternUnits="userSpaceOnUse"
                            >
                                <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor"/>
                            </pattern>
                        </defs>
                        <rect width={404} height={384} fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)"/>
                    </svg>
                    <svg
                        className="absolute top-1/2 right-full transform -translate-y-1/2 -translate-x-32"
                        width={404}
                        height={384}
                        fill="none"
                        viewBox="0 0 404 384"
                    >
                        <defs>
                            <pattern
                                id="f210dbf6-a58d-4871-961e-36d5016a0f49"
                                x={0}
                                y={0}
                                width={20}
                                height={20}
                                patternUnits="userSpaceOnUse"
                            >
                                <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor"/>
                            </pattern>
                        </defs>
                        <rect width={404} height={384} fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)"/>
                    </svg>
                    <svg
                        className="absolute bottom-12 left-full transform translate-x-32"
                        width={404}
                        height={384}
                        fill="none"
                        viewBox="0 0 404 384"
                    >
                        <defs>
                            <pattern
                                id="d3eb07ae-5182-43e6-857d-35c643af9034"
                                x={0}
                                y={0}
                                width={20}
                                height={20}
                                patternUnits="userSpaceOnUse"
                            >
                                <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor"/>
                            </pattern>
                        </defs>
                        <rect width={404} height={384} fill="url(#d3eb07ae-5182-43e6-857d-35c643af9034)"/>
                    </svg>
                </div>
            </div>
            <div className="mt-4 relative px-4 sm:px-6 lg:px-8">
                <div className="text-lg max-w-prose mx-auto">
                    <Link to="/blog"
                          className="inline-flex space-x-4">
                        <span
                            className="inline-flex items-center text-sm font-medium text-blue-500 space-x-1">
                      <ArrowLeftIcon className="h-5 w-5" aria-hidden="true"/>
                            <span>Back to Blog</span>
                    </span>
                    </Link>
                    <span className="block text-base text-center text-blue-600 font-semibold tracking-wide uppercase">
                        Article
                    </span>
                    <h1>
                        <span
                            className="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              Document Roll Call: Safety
            </span>
                    </h1>
                    <p className="mt-8 text-xl text-gray-500 leading-8">
                        We are back with the second installment of our Document Roll Call series. This Tuesday, our
                        Document Roll Call will cover the safety related policies and procedures your laboratory will
                        need. Follow our blog to see which document category we are covering each week and see if you
                        notice in gaps in your own policies and procedures.
                    </p>
                </div>
                <figure className="my-2">
                    <img
                        className="mx-auto w-7/12 rounded-lg"
                        src={process.env.PUBLIC_URL+"/assets/blog/blog7_safety_drc.png"}
                        alt=""
                    />
                </figure>
                <div className="mt-6 prose prose-blue prose-lg text-gray-500 mx-auto">
                    <h2>Safety Program Breakdown</h2>
                    <p>
                        Unsure where to start with your laboratory’s safety program? Here are some of the documents you
                        may want to implement in your laboratory and the points to make sure your documents touch on.
                        Please note these documents are just our recommendations based on our experience working in
                        clinical laboratory settings and do not guarantee that this list is exhaustive.
                    </p>
                    <h3>Injury and Illness Prevention</h3>
                    <ul>
                       <li className="list-disc">
                           Include your laboratory’s procedure for evaluating laboratory accident and occupational
                           injury/illness reports
                       </li>
                        <li className="list-disc">
                            Include your laboratory’s policy for providing and maintaining in a sanitary and reliable
                            condition personal protective equipment in all technical work areas in which body substances
                            are handled
                        </li>
                        <li className="list-disc">
                            Include your laboratory’s policy for personnel removing gloves and cleaning hands with an
                            effective antimicrobial method
                        </li>
                        <li className="list-disc">
                            Include your laboratory’s policy and procedure for regular safety walkthroughs to prevent
                            accidents/injuries
                        </li>
                    </ul>
                    <h3>Biological Safety Program</h3>
                    <ul>
                        <li className="list-disc">
                            Include your laboratory’s policies and procedures for infection control that comply with
                            national, state and local guidelines on occupational exposure to blood-borne pathogens
                        </li>
                        <li className="list-disc">
                            Include your laboratory’s procedures for the procurement, transportation and handling of
                            patient specimens to ensure that all specimens are submitted in a well-constructed container
                            to prevent leakage during transport
                        </li>
                        <li className="list-disc">
                            Include your laboratory’s policy for follow-up after possible and known percutaneous, mucous
                            membrane or abraded skin exposure to HIV, HBV or HCV
                        </li>
                        <li className="list-disc">
                            Include your laboratory’s tuberculosis exposure control plan
                        </li>
                        <li className="list-disc">
                            Include your laboratory’s policies and procedures for the safe handling of tissues that may
                            contain radioactive material
                        </li>
                        <li className="list-disc">
                            Include your laboratory’s policies and procedures for disposing of infectious wastes in
                            “biohazard” labeled containers that do not leak and have tight fitting covers
                        </li>
                        <li className="list-disc">
                            Include your laboratory’s policies for the usage of blood-letting devices that are capable
                            of transmitting infection
                        </li>
                    </ul>
                    <h3>Chemical Hygiene Plan</h3>
                    <ul>
                        <li className="list-disc">
                            Include your laboratory’s safety policies and procedures for all chemicals used within the
                            laboratory
                        </li>
                        <li className="list-disc">
                            Include your laboratory’s procedures for handling spills of body fluids
                        </li>
                        <li className="list-disc">
                            Include your laboratory’s method for ensuring employees have access to safety data sheets
                            chemical hygiene plan, and occupational exposure regulations
                        </li>
                        <li className="list-disc">
                            Include your laboratory’s policy for ensuring containers of hazardous chemical are labeled
                            to indicate type of hazard and what to do if accident occurs
                        </li>
                        <li className="list-disc">
                            Include your laboratory’s approach to ensuring explicit instructions for the emergency
                            treatment of chemical splashes and injuries and the control of chemical spills
                        </li>
                        <li className="list-disc">
                            Include your laboratory’s approach to ensuring that areas with volatile solvents are
                            ventilated adequately
                        </li>
                    </ul>
                    <h3>Disaster Preparedness Plan</h3>
                    <ul>
                        <li className="list-disc">
                            Include your laboratory’s policies and procedures defining the roles and responsibilities
                            of the laboratory in internal and external disaster preparedness
                        </li>
                        <li className="list-disc">
                            Include your laboratory’s procedures for the preservation of data and equipment in case of
                            an unexpected destructive event
                        </li>
                        <li className="list-disc">
                            Include your laboratory’s comprehensive and workable evacuation plan
                        </li>
                    </ul>
                    <h3>Occupational Safety</h3>
                    <ul>
                        <li className="list-disc">
                            Include your laboratory’s procedure for instructing personnel in the proper use of personal
                            protective clothing/equipment and maintaining records
                        </li>
                        <li className="list-disc">
                            Include your laboratory’s policy prohibiting smoking, eating, drinking, application of
                            cosmetics, manipulation of contact lenses and mouth pipetting in all technical work areas
                        </li>
                        <li className="list-disc">
                            Include your laboratory’s procedure for monitoring sterilizing devices periodically with a
                            biologic indicator for effectiveness of sterility
                        </li>
                        <li className="list-disc">
                            Include your laboratory’s guidance for proper handling of corrosive, flammable, biohazardous
                            and carcinogenic substances
                        </li>
                        <li className="list-disc">
                            Include your laboratory’s policies and procedures for the safe handling of liquid nitrogen
                        </li>
                        <li className="list-disc">
                            Include your laboratory’s procedures to prevent or reduce UV light exposure from instrument
                            sources
                        </li>
                        <li className="list-disc">
                            Include your laboratory’s procedure to protect personnel and patients from allergic
                            reactions from exposures to latex
                        </li>
                        <li className="list-disc">
                            Include your laboratory’s procedures for hazardous waste disposal
                        </li>
                    </ul>
                    <h3>Electrical Ground Testing / Sound Level Monitoring</h3>
                    <ul>
                        <li className="list-disc">
                            Include your laboratory’s policy for ensuring the computer system is protected against power
                            interruptions and surges
                        </li>
                        <li className="list-disc">
                            Include your laboratory’s procedure for recording that all laboratory instruments and
                            appliances are grounded and checked for current leakage before use, after repair, and when
                            a problem is suspected
                        </li>
                        <li className="list-disc">
                            Include your laboratory’s procedure for protecting personnel from excessive noise levels
                        </li>
                    </ul>
                    <h3>Fire Prevention Program</h3>
                    <ul>
                        <li className="list-disc">
                            Include your laboratory’s policies and procedures for fire prevention and control
                        </li>
                        <li className="list-disc">
                            Include your laboratory’s approach to ensuring fire-fighting equipment appropriate for
                            electrical components is available
                        </li>
                        <li className="list-disc">
                            Include that your laboratory is properly separated from inpatient areas and or equipped
                            with AFE (automatic fire extinguishing) systems
                        </li>
                        <li className="list-disc">
                            Include that rooms greater than 1000 sq. ft has at least two exit access doors remote from
                            each other
                        </li>
                        <li className="list-disc">
                            Include your laboratory’s procedure for fire safety training and ensuring it is performed
                            for new employees with a review conducted at least annually (including fire extinguisher use)
                        </li>
                        <li className="list-disc">
                            Include your laboratory’s method for ensuring an automatic fire detection and alarm system
                            is present
                        </li>
                        <li className="list-disc">
                            Include your laboratory’s approach to ensuring a fire alarm station is present in or near
                            the laboratory
                        </li>
                        <li className="list-disc">
                            Include your laboratory’s approach to ensuring appropriate fire extinguishers are provided
                            for all areas in which flammable and combustible liquids are stored or handled
                        </li>
                    </ul>
                    <h2>Still have questions on safety policies and procedures?</h2>
                    <p>
                        The Decoto Labs team is happy to help however we can. Whether you need document templates,
                        recommendations on how to create procedures that fit your lab workflow or tips for doing your
                        laboratory safety walk-through &#8212; we want to help.
                    </p>
                    <p>
                        Visit our <a href="https://decotolabs.com">homepage</a>{" "} to schedule time with our founders
                        to get your questions answered or reach out via email to <a href="mailto:info@decotolabs.com">info@decotolabs.com</a>.
                        You can also keep up with our blog posts and resources by following our{" "}
                        <a href="https://www.linkedin.com/company/decoto-labs" target="_blank" rel="noreferrer">LinkedIn</a> page.
                    </p>
                    <h2>Interested in trying our QMS, Regulator?</h2>
                    <p>
                        Our Early Adopter program is still open, but spots are filling up quickly. You can reach us via
                        email at{" "}
                        <a href="mailto:info@decotolabs.com">info@decotolabs.com</a>{" "}
                        with “Early Adopter Program” as the subject line. Still not sure? Visit our{" "}
                        <a href="https://decotolabs.com">homepage</a>{" "}
                        where you can directly schedule time to speak with our founders and get your questions answered.
                    </p>
                </div>
            </div>
        </div>
    )
}
