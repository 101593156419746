import {ArrowLeftIcon} from "@heroicons/react/solid";
import React from "react";
import {Link} from "react-router-dom";

export default function BlogPost16() {

    return (
        <div className="relative py-16 bg-white overflow-hidden">
            <div className="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
                <div className="relative h-full text-lg max-w-prose mx-auto" aria-hidden="true">
                    <svg
                        className="absolute top-12 left-full transform translate-x-32"
                        width={404}
                        height={384}
                        fill="none"
                        viewBox="0 0 404 384"
                    >
                        <defs>
                            <pattern
                                id="74b3fd99-0a6f-4271-bef2-e80eeafdf357"
                                x={0}
                                y={0}
                                width={20}
                                height={20}
                                patternUnits="userSpaceOnUse"
                            >
                                <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor"/>
                            </pattern>
                        </defs>
                        <rect width={404} height={384} fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)"/>
                    </svg>
                    <svg
                        className="absolute top-1/2 right-full transform -translate-y-1/2 -translate-x-32"
                        width={404}
                        height={384}
                        fill="none"
                        viewBox="0 0 404 384"
                    >
                        <defs>
                            <pattern
                                id="f210dbf6-a58d-4871-961e-36d5016a0f49"
                                x={0}
                                y={0}
                                width={20}
                                height={20}
                                patternUnits="userSpaceOnUse"
                            >
                                <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor"/>
                            </pattern>
                        </defs>
                        <rect width={404} height={384} fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)"/>
                    </svg>
                    <svg
                        className="absolute bottom-12 left-full transform translate-x-32"
                        width={404}
                        height={384}
                        fill="none"
                        viewBox="0 0 404 384"
                    >
                        <defs>
                            <pattern
                                id="d3eb07ae-5182-43e6-857d-35c643af9034"
                                x={0}
                                y={0}
                                width={20}
                                height={20}
                                patternUnits="userSpaceOnUse"
                            >
                                <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor"/>
                            </pattern>
                        </defs>
                        <rect width={404} height={384} fill="url(#d3eb07ae-5182-43e6-857d-35c643af9034)"/>
                    </svg>
                </div>
            </div>
            <div className="mt-4 relative px-4 sm:px-6 lg:px-8">
                <div className="text-lg max-w-prose mx-auto">
                    <Link to="/blog"
                          className="inline-flex space-x-4">
                        <span
                            className="inline-flex items-center text-sm font-medium text-blue-500 space-x-1">
                      <ArrowLeftIcon className="h-5 w-5" aria-hidden="true"/>
                            <span>Back to Blog</span>
                    </span>
                    </Link>
                    <span className="block text-base text-center text-blue-600 font-semibold tracking-wide uppercase">
                        Press Release
                    </span>
                    <h1>
                        <span
                            className="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              Regulator, the first QMS built for CLIA labs, has launched!
            </span>
                    </h1>
                    <p className="mt-8 text-xl text-gray-500 leading-8">
                        Yes, you read that right! Regulator is now publicly available for you to use in your laboratory. Read on
                        to learn about what Regulator can do you for you, how to sign up and how to get in touch with us here at
                        Decoto Labs.
                    </p>
                </div>
                <figure className="my-2">
                    <img
                        className="mx-auto w-7/12 rounded-lg"
                        src={process.env.PUBLIC_URL+"/assets/blog/blog16_regulator_launch.png"}
                        alt=""
                    />
                </figure>
                <div className="mt-6 prose prose-blue prose-lg text-gray-500 mx-auto">
                    <h2>What is Regulator?</h2>
                    <p>
                        We know that when it comes to quality management most
                        clinical lab teams are being crushed under the weight of multiple systems that don’t talk to
                        each other and are not audit friendly. We also understand that new laboratories can be
                        overwhelmed by the daunting task of setting up a QMS. Regulator is a new kind of quality
                        management system built for clinical laboratories by clinical quality professionals.
                        Our QMS is an all-in-one solution that seamlessly manages your reagents, equipment, samples,
                        standard operating procedures, personnel, workflows,
                        training and competency. Regulator brings in all of your quality management activities and
                        serves it to you with an intuitive user interface and real-time validation to help you avoid
                        common non-conformances.
                    </p>
                    <h2>What can Regulator do for you?</h2>
                    <p>
                        <li className="list-disc">
                            <b className="text-gray-900">Manage all of your quality records in one place</b>{" "}
                            -  Anyone with experience in a clinical laboratory know that quality extends far beyond
                            just document control. Regulator lets you manage your standard operating procedures, reagents,
                            equipment, samples, training and competency and much more all in one centralized place.
                        </li>
                        <li className="list-disc">
                            <b className="text-gray-900">Provide you with a self-service quality management system option</b>{" "}
                            -  No demos, no quotes, and no set-up fees. Just complete the 5 minute sign up process and
                            your Regulator instance will be ready for you to use.
                        </li>
                        <li className="list-disc">
                            <b className="text-gray-900">A 30 day money-back guarantee</b>{" "}
                            -  Not sure if Regulator is the right fit for you? Sign up and poke around! We know it's hard
                            to tell if a system will work for you until you actually try it out. Reach out within 30 days
                            and get a full refund.
                        </li>
                        <li className="list-disc">
                            <b className="text-gray-900">No user minimums</b>{" "}
                            -  Whether you are lab of 3 or 300, don't pay for more seats than you need. Team is growing?
                            Add more seats at any time in the Settings section of Regulator.
                        </li>
                        <li className="list-disc">
                            <b className="text-gray-900">Access to how-to and troubleshooting guides</b>{" "}
                            -  Decoto Labs is happy to provide getting started guides, how-to articles and troubleshooting
                            tips to make your experience with Regulator easier. You can access these at any time on our
                            site.
                        </li>
                    </p>
                    <h2>How do I sign up?</h2>
                    <p>
                        Go to our{" "}
                        <a href="https://regulator.decotolabs.com/signup/admin" rel="noreferrer" target="_blank">
                            sign up page
                        </a>
                        {" "}and go through the three step sign up process. Provide information about your laboratory
                        and the email of your team's admin user, select the number of seats you require, and enter
                        your payment information.
                    </p>
                    <h2>Still have questions?</h2>
                    <p>
                        Visit our{" "}
                        <Link to="/home">
                            website
                        </Link>
                        {" "}to learn more about our product or contact us at{" "}
                        <a href="mailto:info@decotolabs.com">info@decotolabs.com</a> with any additional
                        questions you may have.
                    </p>
                </div>
            </div>
        </div>
    )
}
