import Header from "./common/Header";
import React from "react";
import Footer from "./common/Footer";
import { Link } from "react-router-dom";

export default function CookiePolicy() {
    return (
        <div className="static">
            <div>
        <span id="top">
        </span>
                <div className="relative py-16 bg-white overflow-hidden rounded-lg">
                    <div className="relative px-4 sm:px-6 lg:px-8">
                        <div className="text-lg max-w-prose mx-auto">
                            <h1>
                <span className="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                  Cookie Policy
                </span>
                            </h1>
                            <div className="mt-6 prose prose-blue prose-lg text-gray-500 mx-auto">
                                <p>
                                    Our <Link to="/privacy-policy">Privacy Policy</Link> explains
                                    our principles when it comes to the collection, processing,
                                    and storage of your information. This policy specifically
                                    explains how we, our partners, and users of our services
                                    deploy cookies, as well as the options you have to control
                                    them.
                                </p>
                                <p>
                                    <strong>What are cookies?</strong>. Cookies are small pieces
                                                                      of data, stored in text files, that are stored on your
                                                                      computer or other device when websites are loaded in a
                                                                      browser. They are widely used to “remember” you and your
                                                                      preferences, either for a single visit (through a “session
                                                                      cookie”) or for multiple repeat visits (using a “persistent
                                                                      cookie”). They ensure a consistent and efficient experience
                                                                      for visitors, and perform essential functions such as allowing
                                                                      users to register and remain logged in. Cookies may be set by
                                                                      the site that you are visiting (known as “first party
                                                                      cookies”), or by third parties, such as those who serve
                                                                      content or provide advertising or analytics services on the
                                                                      website (“third party cookies”). Both websites and HTML emails
                                                                      may also contain other tracking technologies such as “web
                                                                      beacons” or “pixels.” These are typically small transparent
                                                                      images that provide us with statistics, for similar purposes
                                                                      as cookies. They are often used in conjunction with cookies,
                                                                      though they are not stored on your computer in the same way.
                                                                      As a result, if you disable cookies, web beacons may still
                                                                      load, but their functionality will be restricted.
                                </p>
                                <p>
                                    <strong>How we use cookies</strong>. Decoto Labs does not
                                                                       install any third-party cookies on your device. Our
                                                                       first-party cookies are limited to those that are required to
                                                                       make our Services functional, e.g. storing of your session
                                                                       data so you do not need to provide authentication on each
                                                                       page.
                                </p>
                                <p>
                                    <strong>Sources</strong>
                                    This Privacy Policy is adapted from{" "}
                                    <a
                                        href="https://github.com/Automattic/legalmattic"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        Automattic's open source
                                    </a>{" "}
                                    legal documents. We have revised the policy to reflect Decoto
                                    Labs' actual practices.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="absolute bottom-0 w-full">
                <Footer />
            </div>
        </div>
    );
}
