import HomePage from "./HomePage";
import {Navigate, Route, Routes, } from "react-router-dom";
import BlogPage from "./BlogPage";
import BlogPost1 from "./blog/BlogPost1";
import TeamPage from "./TeamPage";
import Header from "./common/Header";
import PricingPage from "./PricingPage";
import PrivacyPolicy from "./PrivacyPolicy";
import CookiePolicy from "./CookiePolicy";
import TermsOfService from "./TermsOfService";
import BlogPost2 from "./blog/BlogPost2";
import BlogPost3 from "./blog/BlogPost3";
import BlogPost4 from "./blog/BlogPost4";
import BlogPost5 from "./blog/BlogPost5";
import BlogPost6 from "./blog/BlogPost6";
import BlogPost7 from "./blog/BlogPost7";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import BlogPost8 from "./blog/BlogPost8";
import BlogPost9 from "./blog/BlogPost9";
import BlogPost10 from "./blog/BlogPost10";
import BlogPost11 from "./blog/BlogPost11";
import BlogPost12 from "./blog/BlogPost12";
import BlogPost13 from "./blog/BlogPost13";
import BlogPost14 from "./blog/BlogPost14";
import BlogPost15 from "./blog/BlogPost15";
import BlogPost16 from "./blog/BlogPost16";
const firebaseConfig = {
    apiKey: "AIzaSyAe4fXZLZRMQKXNgMtZn9Z4UtcmBd1TyQE",
    authDomain: "decoto-labs-marketing-hosting.firebaseapp.com",
    projectId: "decoto-labs-marketing-hosting",
    storageBucket: "decoto-labs-marketing-hosting.appspot.com",
    messagingSenderId: "788039014667",
    appId: "1:788039014667:web:9de2984f32985a44a32a0e",
    measurementId: "G-VWRJRERBMF"
};

export default function App(){
    const app = initializeApp(firebaseConfig);
    const analytics = getAnalytics(app);

    return(
        <Routes>
            <Route path="/" element={
                <div>
                    <Header/>
                    <HomePage/>
                </div>
            }/>
            <Route path="/pricing" element={
                <div>
                    <Header/>
                    <PricingPage/>
                </div>
            }/>
            <Route path="/blog" element={
                <div>
                    <Header/>
                    <BlogPage/>
                </div>
            }/>
            <Route path="/team" element={
                <div>
                    <Header/>
                    <TeamPage/>
                </div>
            }/>
            <Route path="/privacy-policy" element={
                <div>
                    <Header/>
                    <PrivacyPolicy/>
                </div>
            }/>
            <Route path="/cookie-policy" element={
                <div>
                    <Header/>
                    <CookiePolicy/>
                </div>
            }/>
            <Route path="/terms-of-service" element={
                <div>
                    <Header/>
                    <TermsOfService/>
                </div>
            }/>
            <Route path="/blog/top-10-clia-deficiencies-and-how-to-avoid" element={
                <div>
                    <BlogPost1/>
                </div>
            }/>
            <Route path="/blog/new-clia-qms-regulator-early-adoption" element={
                <div>
                    <BlogPost2/>
                </div>
            }/>
            <Route path="/blog/medialab-alternative-regulator" element={
                <div>
                    <BlogPost3/>
                </div>
            }/>
            <Route path="/blog/new-clia-lab-steps" element={
                <div>
                    <BlogPost4/>
                </div>
            }/>
            <Route path="/blog/clia-quality-management-procedures" element={
               <div>
                   <BlogPost5/>
               </div>
            }/>
            <Route path="/blog/cap-clia-cola-inspection-tips" element={
                <div>
                    <BlogPost6/>
                </div>
            }/>
            <Route path="/blog/clia-safety-procedures" element={
                <div>
                    <BlogPost7/>
                </div>
            }/>
            <Route path="/blog/clia-reagent-and-equipment-procedures" element={
                <div>
                    <BlogPost8/>
                </div>
            }/>
            <Route path="/blog/clia-consultant-considerations" element={
                <div>
                    <BlogPost9/>
                </div>
            }/>
            <Route path="/blog/clia-pre-analytical-procedures" element={
                <div>
                    <BlogPost10/>
                </div>
            }/>
            <Route path="/blog/clia-analytical-procedures" element={
                <div>
                    <BlogPost11/>
                </div>
            }/>
            <Route path="/blog/clia-cap-cola-competency-assessment-program" element={
                <div>
                    <BlogPost12/>
                </div>
            }/>
            <Route path="/blog/clia-post-analytical-lab-director-proficiency-testing" element={
                <div>
                    <BlogPost13/>
                </div>
            }/>
            <Route path="/blog/clia-referral-lab-personnel-laboratory-information-systems-procedures" element={
                <div>
                    <BlogPost14/>
                </div>
            }/>
            <Route path="/blog/what-is-an-api-tech-talk" element={
               <div>
                   <BlogPost15/>
               </div>
            }/>
            <Route path="/blog/cap_clia_qms_regulator_launch" element={
                <div>
                    <BlogPost16/>
                </div>
            }/>
            <Route path="*" element={<Navigate to="/" replace={true}/>} />
        </Routes>


    )
}