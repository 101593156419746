import {ArrowLeftIcon} from "@heroicons/react/solid";
import React from "react";
import {Link} from "react-router-dom";

export default function BlogPost5() {

    return (
        <div className="relative py-16 bg-white overflow-hidden">
            <div className="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
                <div className="relative h-full text-lg max-w-prose mx-auto" aria-hidden="true">
                    <svg
                        className="absolute top-12 left-full transform translate-x-32"
                        width={404}
                        height={384}
                        fill="none"
                        viewBox="0 0 404 384"
                    >
                        <defs>
                            <pattern
                                id="74b3fd99-0a6f-4271-bef2-e80eeafdf357"
                                x={0}
                                y={0}
                                width={20}
                                height={20}
                                patternUnits="userSpaceOnUse"
                            >
                                <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor"/>
                            </pattern>
                        </defs>
                        <rect width={404} height={384} fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)"/>
                    </svg>
                    <svg
                        className="absolute top-1/2 right-full transform -translate-y-1/2 -translate-x-32"
                        width={404}
                        height={384}
                        fill="none"
                        viewBox="0 0 404 384"
                    >
                        <defs>
                            <pattern
                                id="f210dbf6-a58d-4871-961e-36d5016a0f49"
                                x={0}
                                y={0}
                                width={20}
                                height={20}
                                patternUnits="userSpaceOnUse"
                            >
                                <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor"/>
                            </pattern>
                        </defs>
                        <rect width={404} height={384} fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)"/>
                    </svg>
                    <svg
                        className="absolute bottom-12 left-full transform translate-x-32"
                        width={404}
                        height={384}
                        fill="none"
                        viewBox="0 0 404 384"
                    >
                        <defs>
                            <pattern
                                id="d3eb07ae-5182-43e6-857d-35c643af9034"
                                x={0}
                                y={0}
                                width={20}
                                height={20}
                                patternUnits="userSpaceOnUse"
                            >
                                <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor"/>
                            </pattern>
                        </defs>
                        <rect width={404} height={384} fill="url(#d3eb07ae-5182-43e6-857d-35c643af9034)"/>
                    </svg>
                </div>
            </div>
            <div className="mt-4 relative px-4 sm:px-6 lg:px-8">
                <div className="text-lg max-w-prose mx-auto">
                    <Link to="/blog"
                          className="inline-flex space-x-4">
                        <span
                            className="inline-flex items-center text-sm font-medium text-blue-500 space-x-1">
                      <ArrowLeftIcon className="h-5 w-5" aria-hidden="true"/>
                            <span>Back to Blog</span>
                    </span>
                    </Link>
                    <span className="block text-base text-center text-blue-600 font-semibold tracking-wide uppercase">
                        Article
                    </span>
                    <h1>
                        <span
                            className="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              Document Roll Call: Quality Management
            </span>
                    </h1>
                    <p className="mt-8 text-xl text-gray-500 leading-8">
                        Decoto Labs is delighted to bring you our Document Roll Call series. Every Tuesday, visit our
                        blog to see which document category we are covering and see if you notice any gaps in your own
                        policies and procedures. Our first Document Roll Call will detail the quality management
                        objectives and aspects you may want to include in your own program.
                    </p>
                </div>
                <figure className="my-2">
                    <img
                        className="mx-auto w-7/12 rounded-lg"
                        src={process.env.PUBLIC_URL+"/assets/blog/blog5_qm_drc.png"}
                        alt=""
                    />
                </figure>
                <div className="mt-6 prose prose-blue prose-lg text-gray-500 mx-auto">
                    <h2>Quality Management Program Breakdown</h2>
                    <p>
                        Unsure where to start with your laboratory’s Quality Management program? Here are some of the
                        documents you may want to implement in your laboratory and the points to make sure your
                        documents touch on. Please note these documents are just our recommendations based on our
                        experience working in clinical laboratory settings. We do not guarantee that this list is
                        exhaustive.
                    </p>
                    <h3>Terms of Accreditation</h3>
                    <ul>
                       <li className="list-disc">
                           Document your laboratory’s agreement and compliance with the terms of
                           accreditation of your laboratory’s accreditation organization, as
                           applicable
                       </li>
                    </ul>
                    <h3>Document Control</h3>
                    <ul>
                        <li className="list-disc">
                            Include the details of the system your laboratory uses to manage policies, procedures and forms
                        </li>
                        <li className="list-disc">
                            Include your laboratory’s policy for management and correction of laboratory records
                        </li>
                        <li className="list-disc">
                            Include your laboratory’s system for ensuring personnel remain knowledgeable about the
                            contents of the policies and procedures that are relevant to their duties
                        </li>
                    </ul>
                    <h3>Specimen and Document Retention</h3>
                    <ul>
                        <li className="list-disc">
                            Include your laboratory’s defined retention periods for records and materials
                        </li>
                        <li className="list-disc">
                            Include your laboratory’s defined retention periods for records, slides, blocks, and tissues
                            in the event your laboratory ceases operations
                        </li>
                        <li className="list-disc">
                            Include your laboratory’s procedure for retaining copies of reports that are legible and
                            retained in a manner that permits prompt retrieval
                        </li>
                    </ul>
                    <h3>Individualized Quality Control Plan</h3>
                    <ul>
                        <li className="list-disc">
                            Include the risk assessment completed by that laboratory to evaluate potential sources of failure
                        </li>
                        <li className="list-disc">
                            Include the quality control plan your laboratory develops to monitor the potential sources of
                            failure revealed in your laboratory’s risk assessment
                        </li>
                        <li className="list-disc">
                            Include the ongoing quality assessment strategy performed by your laboratory to ensure that
                            your quality control plan is effective in mitigating the risks identified in your
                            laboratory’s risk assessment
                        </li>
                    </ul>
                    <h3>Quality Management Program</h3>
                    <ul>
                        <li className="list-disc">
                            Include that the program will be implemented and followed in the laboratory and reviewed
                            annually for effectiveness
                        </li>
                        <li className="list-disc">
                            Include that the program covers all areas of the laboratory and all beneficiaries of service
                        </li>
                        <li className="list-disc">
                            Include your laboratory’s process for identifying and correcting problems that may interfere
                            with patient care
                        </li>
                        <li className="list-disc">
                            Include the monitoring indicators your laboratory uses to track quality in pre-analytic,
                            analytic and post-analytic phases of processing and how often you will review them
                        </li>
                        <li className="list-disc">
                            Include your laboratory’s procedure for measuring the satisfaction of provider
                            and/or patients at least every two years
                        </li>
                        <li className="list-disc">
                            Include your laboratory’s procedure for managing notifications from vendors of defects or
                            issues that may affect patient care
                        </li>
                        <li className="list-disc">
                            Include your laboratory’s procedure for reporting device-related adverse patient events
                            (as required by the FDA)
                        </li>
                        <li className="list-disc">
                            Include a statement that your laboratory will ensure compliance with applicable federal,
                            state and local regulations
                        </li>
                        <li className="list-disc">
                            Include your laboratory’s approach to conducting and documenting interim self-inspections
                        </li>
                        <li className="list-disc">
                            Include your laboratory’s procedure for evaluating laboratory accidents and occupational
                            injury/illness reports to avoid recurrence
                        </li>
                        <li className="list-disc">
                            Include your laboratory’s procedure for the detection and correction of significant clerical
                            and analytical errors and unusual laboratory results in a timely manner
                        </li>
                        <li className="list-disc">
                            Include your laboratory’s procedure for immediate notification of the ordering physician
                            when results exceed established “critical” values including your laboratory’s “read back”
                            procedure for communications over the phone
                        </li>
                        <li className="list-disc">
                            Include your laboratory’s procedure for employees and patients to communicate concerns about
                            laboratory quality and safety
                        </li>
                    </ul>
                    <h2>Still have questions on quality management policies and procedures?</h2>
                    <p>
                        The Decoto Labs team is happy to help however we can. Whether you need document templates,
                        recommendations on how to create procedures that fit your lab workflow or ideas for quality
                        monitoring indicators &#8212; we want to help.
                    </p>
                    <p>
                        Visit our <a href="https://decotolabs.com">homepage</a>{" "} to schedule time with our founders
                        to get your questions answered or reach out via email to <a href="mailto:info@decotolabs.com">info@decotolabs.com</a>.
                        You can also keep up with our blog posts and resources by following our{" "}
                        <a href="https://www.linkedin.com/company/decoto-labs" target="_blank" rel="noreferrer">LinkedIn</a> page.
                    </p>
                    <h2>Interested in trying our QMS, Regulator?</h2>
                    <p>
                        Our Early Adopter program is still open, but spots are filling up quickly. You can reach us via
                        email at{" "}
                        <a href="mailto:info@decotolabs.com">info@decotolabs.com</a>{" "}
                        with “Early Adopter Program” as the subject line. Still not sure? Visit our{" "}
                        <a href="https://decotolabs.com">homepage</a>{" "}
                        where you can directly schedule time to speak with our founders and get your questions answered.
                    </p>
                </div>
            </div>
        </div>
    )
}
