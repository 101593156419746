import {ArrowLeftIcon} from "@heroicons/react/solid";
import React from "react";
import {Link} from "react-router-dom";

export default function BlogPost8() {

    return (
        <div className="relative py-16 bg-white overflow-hidden">
            <div className="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
                <div className="relative h-full text-lg max-w-prose mx-auto" aria-hidden="true">
                    <svg
                        className="absolute top-12 left-full transform translate-x-32"
                        width={404}
                        height={384}
                        fill="none"
                        viewBox="0 0 404 384"
                    >
                        <defs>
                            <pattern
                                id="74b3fd99-0a6f-4271-bef2-e80eeafdf357"
                                x={0}
                                y={0}
                                width={20}
                                height={20}
                                patternUnits="userSpaceOnUse"
                            >
                                <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor"/>
                            </pattern>
                        </defs>
                        <rect width={404} height={384} fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)"/>
                    </svg>
                    <svg
                        className="absolute top-1/2 right-full transform -translate-y-1/2 -translate-x-32"
                        width={404}
                        height={384}
                        fill="none"
                        viewBox="0 0 404 384"
                    >
                        <defs>
                            <pattern
                                id="f210dbf6-a58d-4871-961e-36d5016a0f49"
                                x={0}
                                y={0}
                                width={20}
                                height={20}
                                patternUnits="userSpaceOnUse"
                            >
                                <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor"/>
                            </pattern>
                        </defs>
                        <rect width={404} height={384} fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)"/>
                    </svg>
                    <svg
                        className="absolute bottom-12 left-full transform translate-x-32"
                        width={404}
                        height={384}
                        fill="none"
                        viewBox="0 0 404 384"
                    >
                        <defs>
                            <pattern
                                id="d3eb07ae-5182-43e6-857d-35c643af9034"
                                x={0}
                                y={0}
                                width={20}
                                height={20}
                                patternUnits="userSpaceOnUse"
                            >
                                <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor"/>
                            </pattern>
                        </defs>
                        <rect width={404} height={384} fill="url(#d3eb07ae-5182-43e6-857d-35c643af9034)"/>
                    </svg>
                </div>
            </div>
            <div className="mt-4 relative px-4 sm:px-6 lg:px-8">
                <div className="text-lg max-w-prose mx-auto">
                    <Link to="/blog"
                          className="inline-flex space-x-4">
                        <span
                            className="inline-flex items-center text-sm font-medium text-blue-500 space-x-1">
                      <ArrowLeftIcon className="h-5 w-5" aria-hidden="true"/>
                            <span>Back to Blog</span>
                    </span>
                    </Link>
                    <span className="block text-base text-center text-blue-600 font-semibold tracking-wide uppercase">
                        Article
                    </span>
                    <h1>
                        <span
                            className="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              Document Roll Call: Reagents & Equipment
            </span>
                    </h1>
                    <p className="mt-8 text-xl text-gray-500 leading-8">
                        Congratulations! You’ve made it to the third installment of our Document Roll Call series. This
                        Tuesday, our Document Roll Call will cover the reagent and equipment policies and procedures
                        your laboratory should have. Follow our blog to see which document category we are covering
                        each week and see if you notice in gaps in your own policies and procedures.
                    </p>
                </div>
                <figure className="my-2">
                    <img
                        className="mx-auto w-7/12 rounded-lg"
                        src={process.env.PUBLIC_URL+"/assets/blog/blog8_rande_drc.png"}
                        alt=""
                    />
                </figure>
                <div className="mt-6 prose prose-blue prose-lg text-gray-500 mx-auto">
                    <h2>Reagent and Equipment Program Breakdown</h2>
                    <p>
                        Unsure where to start with your laboratory’s reagent and equipment program? Here are some of
                        the documents you may want to implement in your laboratory and the points to make sure your
                        documents touch on. Please note these documents are just our recommendations based on our
                        experience working in clinical laboratory settings and do not guarantee that this list is
                        exhaustive.
                    </p>
                    <h3>Operation & Maintenance Procedures</h3>
                    <p>
                        Your laboratory should have an operation and maintenance procedure for each of the critical
                        pieces of equipment used in testing. Your laboratory may opt to have an “Auxiliary Equipment”
                        operation and maintenance procedure to encompass simpler pieces of equipment like manual
                        pipettes, mini-centrifuges, vortex mixers, etc.
                        <br/>
                        Each procedure should include:
                    </p>
                    <ul>
                       <li className="list-disc">
                           Include the procedure for startup, operation and shutdown of the instrument and/or equipment
                       </li>
                        <li className="list-disc">
                            Include the manufacturer’s instructions for instrument maintenance and function checks
                        </li>
                        <li className="list-disc">
                            Include the procedure for appropriate maintenance and function checks at least as frequently
                            as specified by manufacturer
                        </li>
                        <li className="list-disc">
                            Include the tolerance limits for acceptable function and action when the limits are exceeded
                        </li>
                        <li className="list-disc">
                            Include instructions for minor troubleshooting and repairs
                        </li>
                        <li className="list-disc">
                            Include the procedure for verifying performance of the instrument and/or equipment upon
                            installation and after major maintenance or service
                        </li>
                        <li className="list-disc">
                            Include your laboratory’s procedure for ensuring instrument and equipment maintenance and
                            function check records are reviewed monthly by the laboratory director
                        </li>
                    </ul>
                    <h3>Temperature Monitoring</h3>
                    <ul>
                        <li className="list-disc">
                            Include your laboratory’s plan for ensuring NIST certified thermometric standard devices
                            are available
                        </li>
                        <li className="list-disc">
                            Include your laboratory’s procedure checking any non-certified thermometers against an
                            appropriate standard
                        </li>
                        <li className="list-disc">
                            Include your laboratory’s procedure for checking and recording temperature daily for all
                            temperature-dependent equipment and environments
                        </li>
                        <li className="list-disc">
                            Include the acceptable temperature ranges for all temperature-dependent equipment and environments
                        </li>
                        <li className="list-disc">
                            Include your laboratory’s corrective action procedure if acceptable temperature ranges are
                            exceeded, including evaluation for adverse effects
                        </li>
                        <li className="list-disc">
                            Include your laboratory’s procedure for checking refrigerator/freezer temperatures daily
                            using a calibrated thermometer
                        </li>
                    </ul>
                    <h3>Equivalency Testing</h3>
                    <ul>
                        <li className="list-disc">
                            Include a list of your laboratory’s non-waived instruments and test methods
                        </li>
                        <li className="list-disc">
                            Include your laboratory’s procedure for performing equivalency testing on all
                            non-waived instruments and test methods
                        </li>
                        <li className="list-disc">
                            Include your laboratory’s acceptability criteria for comparability for all equivalency
                            testing procedures
                        </li>
                        <li className="list-disc">
                            Include your laboratory’s corrective action procedure if acceptability criteria is not met
                        </li>
                    </ul>
                    <h3>Centrifuge Procedures</h3>
                    <ul>
                        <li className="list-disc">
                            Include your laboratory’s procedure for checking operating speeds of centrifuges at least
                            annually and in a safe manner
                        </li>
                    </ul>
                    <h3>Glassware Procedures</h3>
                    <ul>
                        <li className="list-disc">
                            Include your laboratory’s procedures for handling and cleaning glassware, including methods
                            for testing for detergent removal
                        </li>
                    </ul>
                    <h3>Reagent Control</h3>
                    <ul>
                        <li className="list-disc">
                            Include your laboratory’s procedure for storing all reagents, cartridges, test cards in
                            accordance with manufacturer instructions for waived tests
                        </li>
                        <li className="list-disc">
                            Include your laboratory's procedure for ensuring all reagents, calibrators, etc. are labeled
                            with
                            <ul>
                                <li className="list-disc">Content and quantity, concentration, titer</li>
                                <li className="list-disc">Storage requirements</li>
                                <li className="list-disc">Date prepared</li>
                                <li className="list-disc">Expiration date</li>
                            </ul>
                        </li>
                        <li className="list-disc">
                            Include your laboratory’s procedure for handling and storing all reagents and media in
                            accordance with manufacturer’s instructions
                        </li>
                        <li className="list-disc">
                            Include your laboratory’s procedure for ensuring all reagents, chemicals and media are used
                            within their indicated expiration date
                        </li>
                        <li className="list-disc">
                            Include your laboratory’s procedure for how new reagent lots and shipments are checked
                            against old lots and/or suitable reference material before or concurrently with being placed
                            in service
                        </li>
                        <li className="list-disc">
                            Include your laboratory’s procedure for ensuring that components for reagents kits are used
                            only within the kit unless otherwise specified by the manufacturer
                        </li>
                        <li className="list-disc">
                            Include your laboratory’s definition of type of water required for each testing procedure
                            and your laboratory’s procedure for testing water quality annually
                        </li>
                    </ul>
                    <h2>Still have questions on reagent and equipment policies and procedures?</h2>
                    <p>
                        The Decoto Labs team is happy to help however we can. Whether you need document templates,
                        IQ/OQ/PQ guidance or tips on how to implement a live reagent phase-in program &#8212; we want
                        to help.
                    </p>
                    <p>
                        Visit our <a href="https://decotolabs.com">homepage</a>{" "} to schedule time with our founders
                        to get your questions answered or reach out via email to <a href="mailto:info@decotolabs.com">info@decotolabs.com</a>.
                        You can also keep up with our blog posts and resources by following our{" "}
                        <a href="https://www.linkedin.com/company/decoto-labs" target="_blank" rel="noreferrer">LinkedIn</a> page.
                    </p>
                    <h2>Interested in trying our QMS, Regulator?</h2>
                    <p>
                        Our Early Adopter program is still open, but spots are filling up quickly. You can reach us via
                        email at{" "}
                        <a href="mailto:info@decotolabs.com">info@decotolabs.com</a>{" "}
                        with “Early Adopter Program” as the subject line. Still not sure? Visit our{" "}
                        <a href="https://decotolabs.com">homepage</a>{" "}
                        where you can directly schedule time to speak with our founders and get your questions answered.
                    </p>
                </div>
            </div>
        </div>
    )
}
