import {ArrowLeftIcon} from "@heroicons/react/solid";
import React from "react";
import {Link} from "react-router-dom";

export default function BlogPost2() {

    return (
        <div className="relative py-16 bg-white overflow-hidden">
            <div className="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
                <div className="relative h-full text-lg max-w-prose mx-auto" aria-hidden="true">
                    <svg
                        className="absolute top-12 left-full transform translate-x-32"
                        width={404}
                        height={384}
                        fill="none"
                        viewBox="0 0 404 384"
                    >
                        <defs>
                            <pattern
                                id="74b3fd99-0a6f-4271-bef2-e80eeafdf357"
                                x={0}
                                y={0}
                                width={20}
                                height={20}
                                patternUnits="userSpaceOnUse"
                            >
                                <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor"/>
                            </pattern>
                        </defs>
                        <rect width={404} height={384} fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)"/>
                    </svg>
                    <svg
                        className="absolute top-1/2 right-full transform -translate-y-1/2 -translate-x-32"
                        width={404}
                        height={384}
                        fill="none"
                        viewBox="0 0 404 384"
                    >
                        <defs>
                            <pattern
                                id="f210dbf6-a58d-4871-961e-36d5016a0f49"
                                x={0}
                                y={0}
                                width={20}
                                height={20}
                                patternUnits="userSpaceOnUse"
                            >
                                <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor"/>
                            </pattern>
                        </defs>
                        <rect width={404} height={384} fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)"/>
                    </svg>
                    <svg
                        className="absolute bottom-12 left-full transform translate-x-32"
                        width={404}
                        height={384}
                        fill="none"
                        viewBox="0 0 404 384"
                    >
                        <defs>
                            <pattern
                                id="d3eb07ae-5182-43e6-857d-35c643af9034"
                                x={0}
                                y={0}
                                width={20}
                                height={20}
                                patternUnits="userSpaceOnUse"
                            >
                                <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor"/>
                            </pattern>
                        </defs>
                        <rect width={404} height={384} fill="url(#d3eb07ae-5182-43e6-857d-35c643af9034)"/>
                    </svg>
                </div>
            </div>
            <div className="mt-4 relative px-4 sm:px-6 lg:px-8">
                <div className="text-lg max-w-prose mx-auto">
                    <Link to="/blog"
                          className="inline-flex space-x-4">
                        <span
                            className="inline-flex items-center text-sm font-medium text-blue-500 space-x-1">
                      <ArrowLeftIcon className="h-5 w-5" aria-hidden="true"/>
                            <span>Back to Blog</span>
                    </span>
                    </Link>
                    <span className="block text-base text-center text-blue-600 font-semibold tracking-wide uppercase">
                        Press Release
                    </span>
                    <h1>
                        <span
                            className="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              The Regulator Early Adopter Program is now Open!
            </span>
                    </h1>
                    <p className="mt-8 text-xl text-gray-500 leading-8">
                        Decoto Labs is thrilled to announce our quality management system (QMS) offering, Regulator,
                        is now available for early adoption! Find out whether you would be a good fit to be an early
                        adopter and how to get involved.
                    </p>
                </div>
                <figure className="my-2">
                    <img
                        className="mx-auto w-7/12 rounded-lg"
                        src={process.env.PUBLIC_URL+"/assets/blog/blog2_regulator_early_img.png"}
                        alt=""
                    />
                </figure>
                <div className="mt-6 prose prose-blue prose-lg text-gray-500 mx-auto">
                    <h2>What is Regulator?</h2>
                    <p>
                        We know that when it comes to quality management most
                        clinical lab teams are being crushed under the weight of multiple systems that don’t talk to
                        each other and are not audit friendly. We also understand that new laboratories can be
                        overwhelmed by the daunting task of setting up a QMS. Regulator is a new kind of quality
                        management system built for clinical laboratories by clinical quality professionals.
                        Our QMS is an all-in-one solution that seamlessly manages your reagents, equipment, samples,
                        standard operating procedures, personnel, workflows,
                        training and competency. Regulator brings in all of your quality management activities and
                        serves it to you with an intuitive user interface and real-time validation to help you avoid
                        common non-conformances.
                    </p>
                    <h2>What does it mean to be an Early Adopter?</h2>
                    <p>
                        Early Adopters will get the opportunity to be among the first to implement Regulator in their
                        laboratory. The Decoto Labs team will provide you with enterprise level onboarding and customer
                        support at no additional cost. As an Early Adopter, you will get to influence the direction of
                        the Regulator product and have your experience affect the future of clinical laboratory software.
                    </p>
                    <h2>Are you a good fit for our Early Adopter program?</h2>
                    <p>
                        If your team fits any of the following criteria, we want to talk to you!<br/>
                        A Regulator Early Adopter may be:
                        <ul>
                            <li className="list-disc">
                                A newer clinical laboratory that is just starting or in the process of implementing a
                                quality management program in preparation for a COA (Certificate of Accreditation) or
                                COC (Certificate of Compliance) inspection
                            </li>
                            <li className="list-disc">
                                A clinical laboratory that is starting to outgrow their highly manual quality
                                management system and are looking for a software based tool
                            </li>
                            <li className="list-disc">
                                A clinical laboratory enduring a lot of pain with their current quality management
                                tool due to a lack of integration with other tools
                            </li>
                            <li className="list-disc">
                                Any laboratory interested in trying a tool built specifically for clinical laboratories
                                and excited about influencing an QMS product’s development and growth
                            </li>
                        </ul>
                    </p>
                    <h2>How does it work?</h2>
                    <p>
                        From now until June 10th, the Decoto Labs team will be meeting with laboratories interested in
                        becoming an Early Adopter. The team will discuss with you what your current quality management
                        solution is, what are your current pain points are and what you’re hoping to get out of being
                        an Early Adopter. The team will review your information and determine if the Regulator Early
                        Adopter program is a good fit for your laboratory and inform you by June 13th if you have been
                        selected for the Early Adopter cohort.
                    </p>
                    <h2>What if you're not selected?</h2>
                    <p>
                        Never fear! Regulator will be available for all laboratories very soon. Be sure to follow our{" "}
                        <a href="https://decotolabs.com/blog">blog</a>,{" "}
                        <a href="https://www.linkedin.com/company/decoto-labs">LinkedIn</a> and{" "}
                        <a href="https://twitter.com/DecotoLabs">Twitter</a> to stay up to date on our progress and be
                        the first to find out when Regulator is launching for the general public.
                    </p>
                    <h2>How do I get in touch?</h2>
                    <p>
                        Ready to sign up to be considered? You can reach us via email at{" "}
                        <a href="mailto:info@decotolabs.com">info@decotolabs.com</a> with “Early Adopter Program” as the
                        subject line. Still not sure? Visit our <a href="https://decotolabs.com">website</a>{" "}
                        where you can directly schedule time to speak with our founders and get your questions answered.
                    </p>
                </div>
            </div>
        </div>
    )
}
